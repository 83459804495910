import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ChevronDownIcon, ChevronRightIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import {
	Box,
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbLink,
	Button,
	Center,
	Divider,
	Heading,
	HStack,
	Input,
	InputProps,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Select,
	SelectProps,
	Skeleton,
	Switch,
	Text,
	Textarea,
	useDisclosure,
	VStack,
	Wrap,
} from '@chakra-ui/react';

import AlertDialog from 'components/AlertDialog';
import Cardlayout from 'components/CardLayout';
import { CommentsSection, useCpmComments } from 'components/Comment';
import DocumentButton from 'components/documentButton';
import { ChangePropaleOverdueDate } from 'features/ChangeOverdueDate';
import MailFollowUpSection from 'features/mailing-framework/MailFollowUpSection';
import useThemedToast from 'hooks/useThemedToast';
import { ProductPropaleNoBox } from 'pages/cpm/product-propale';
import { statusNamingMap } from 'pages/cpm/super/propale/PropaleList';
import { subjectManual, templateManual, templateManualNoSub } from 'pages/cpm/super/propale/templateMail';
import {
	useDeletePropaleMutation,
	useGetPropaleByIdQuery,
	useLazyGetNotionLinkQuery,
	useSendAutoPropalesMutation,
	useUpdatePropaleLinkedEntitiesMutation,
	useUpdatePropaleMutation,
} from 'services/cpm/propale';
import { CPM, cpmDisplayName, cpmToEmail, emailToCpm } from 'types/cpm-list.type';
import { LeadClosingProbability, PropaleStatus, PropaleType } from 'types/cpm-propale';
import { PortfolioType } from 'types/investmentPreferences.type';
import { MailingFrameworkPostType } from 'types/mailing-framework.type';
import { isNone, isNotNone, toBase64 } from 'utils/functions';
import { PermissionDisplayGuard } from 'utils/guards';
import { BoPermission } from 'utils/permissions';

const InputWithLabel = ({ label, flex, ...props }: { label: string } & InputProps) => (
	<VStack w="100%" spacing="4px" align="start" flex={flex}>
		<Text>{label}</Text>
		<Input {...props} />
	</VStack>
);

const SelectWithLabel = ({ label, flex, children, ...props }: { label: string } & SelectProps) => (
	<VStack w="100%" spacing="4px" align="start" flex={flex}>
		<Text>{label}</Text>
		<Select {...props}>{children}</Select>
	</VStack>
);

export const formatMailData = async (subject: string, body: string, cc: string, attachments: File[]) => ({
	subject,
	body,
	cc: cc.length ? cc.split(',').map((c) => c.trim()) : [],
	attachments: await Promise.all(
		attachments.map(async (a) => ({
			documentName: a.name,
			filename: a.name,
			content: await toBase64(a).then((c) => c.split(',')[1]),
		})),
	),
});

const PropaleDetails = () => {
	const toast = useThemedToast();
	const { id } = useParams<{ id: string }>();
	const navigate = useNavigate();

	const { isOpen: isDeleteDialogOpen, onOpen: openDeleteDialog, onClose: onCloseDeleteDialog } = useDisclosure();

	const { data: propale, isFetching: isPropaleFetching } = useGetPropaleByIdQuery(id ?? '', { skip: !isNotNone(id) });
	const [updatePropale, { isLoading: isUpdateLoading }] = useUpdatePropaleMutation();
	const [deletePropale, { isLoading: isDeleteLoading }] = useDeletePropaleMutation();
	const [sendAutoPropale, { isLoading: isSendingLoading }] = useSendAutoPropalesMutation();
	const [getNotionLink, { isFetching: isNotionLoading }] = useLazyGetNotionLinkQuery();
	const [updateLinkedEntities] = useUpdatePropaleLinkedEntitiesMutation();

	const [status, setStatus] = useState<PropaleStatus>();
	const [type, setType] = useState<PropaleType>();
	const [assignedCpm, setAssignedCpm] = useState('');
	const [notes, setNotes] = useState('');
	const [notionLink, setNotionLink] = useState('');
	const [risk, setRisk] = useState('');
	const [green, setGreen] = useState('');
	const [portfolio, setPortfolio] = useState<PortfolioType>();
	const [leadClosingProbability, setLeadClosingProbability] = useState<LeadClosingProbability>();

	const [mailSubject, setMailSubject] = useState('');
	const [mailBody, setMailBody] = useState('');
	const [mailCC, setMailCC] = useState('conseiller@ramify.fr');
	const [mailAttachment, setMailAttachment] = useState<File[]>([]);
	const inputFile = useRef<HTMLInputElement | null>(null);
	const [sendEmail, setSendEmail] = useState(true);
	const [sendSubscription, setSendSubscription] = useState(true);

	useEffect(() => {
		if (propale?.status) setStatus(propale.status);
		if (propale?.type) setType(propale.type);
		if (propale?.assignedCpmEmail) setAssignedCpm(propale.assignedCpmEmail);
		if (propale?.leadClosingProbability) setLeadClosingProbability(propale.leadClosingProbability);
		if (propale?.notes) setNotes(propale.notes);
		if (propale?.notionLink) setNotionLink(propale.notionLink);
		if (propale?.investRisk) setRisk(propale.investRisk.toString());
		if (propale?.investGreen) setGreen(propale.investGreen.toString());
		if (propale?.investPortfolio) setPortfolio(propale.investPortfolio);
	}, [propale]);

	const comments = useCpmComments();
	const handleSendComment = useCallback(() => {
		comments.onCreateComment(propale!.id);
	}, [comments, propale]);

	// update propale properties

	const dirtyFields = useMemo(
		() => ({
			isStatusDirty: status !== propale?.status,
			isAssignedCpmDirty: propale?.assignedCpmEmail !== assignedCpm,
			isNotesDirty: (propale?.notes ?? '') !== notes,
			isNotionLinkDirty: (propale?.notionLink ?? '') !== notionLink,
			isTypeDirty: propale?.type !== type,
			isLeadClosingProbabilityDirty:
				propale?.leadClosingProbability !== leadClosingProbability && !!leadClosingProbability,
			isPortfolioDirty: propale?.type === PropaleType.AUTO && propale?.investPortfolio !== portfolio,
			isRiskDirty:
				propale?.type === PropaleType.AUTO && propale?.investRisk !== (isNotNone(risk) ? Number(risk) : null),
			isGreenDirty:
				propale?.type === PropaleType.AUTO && propale?.investGreen !== (isNotNone(green) ? Boolean(green) : null),
		}),
		// eslint-disable-next-line prettier/prettier
		[assignedCpm, green, leadClosingProbability, notes, notionLink, portfolio, propale?.assignedCpmEmail, propale?.investGreen, propale?.investPortfolio, propale?.investRisk, propale?.leadClosingProbability, propale?.notes, propale?.notionLink, propale?.status, propale?.type, risk, status, type],
	);

	const handleGetNotionLink = useCallback(
		async () =>
			getNotionLink({
				risk: +risk!,
				portfolio: portfolio!,
				green: green === 'true',
				firstName: propale!.firstName!,
				lastName: propale!.lastName!,
			})
				.unwrap()
				.then(({ link }) => link)
				.catch(() => null),
		[getNotionLink, green, portfolio, propale, risk],
	);

	const handleUpdate = useCallback(
		async () => {
			let newNotionLink: string | null = null;
			if (dirtyFields.isRiskDirty || dirtyFields.isGreenDirty || dirtyFields.isPortfolioDirty) {
				toast({ title: 'Re-génération du lien Notion en cours, veuillez patienter.', status: 'info' });
				newNotionLink = await handleGetNotionLink();
				if (!newNotionLink) {
					toast({ title: 'Erreur lors de la génération du lien Notion', status: 'error' });
					return;
				} else setNotionLink(newNotionLink);
			}

			updatePropale({
				id: propale!.id,
				status: dirtyFields.isStatusDirty ? status : undefined,
				assignedCpmEmail: dirtyFields.isAssignedCpmDirty ? assignedCpm : undefined,
				notes: dirtyFields.isNotesDirty ? notes : undefined,
				leadClosingProbability: dirtyFields.isLeadClosingProbabilityDirty ? leadClosingProbability : undefined,
				notionLink: newNotionLink || (dirtyFields.isNotionLinkDirty ? notionLink : undefined),
				type: dirtyFields.isTypeDirty ? type : undefined,
				investRisk: dirtyFields.isRiskDirty ? +risk! : undefined,
				investGreen: dirtyFields.isGreenDirty ? green === 'true' : undefined,
				investPortfolio: dirtyFields.isPortfolioDirty ? portfolio : undefined,
			})
				.unwrap()
				.then((p) => {
					toast({ title: 'Propale mise à jour', status: 'success' });
					return p;
				})
				.catch(() => {
					toast({ title: 'Erreur lors de la mise à jour de la propale', status: 'error' });
					return null;
				});
		},
		// eslint-disable-next-line prettier/prettier
		[assignedCpm, dirtyFields.isAssignedCpmDirty, dirtyFields.isGreenDirty, dirtyFields.isLeadClosingProbabilityDirty, dirtyFields.isNotesDirty, dirtyFields.isNotionLinkDirty, dirtyFields.isPortfolioDirty, dirtyFields.isRiskDirty, dirtyFields.isStatusDirty, dirtyFields.isTypeDirty, green, handleGetNotionLink, leadClosingProbability, notes, notionLink, portfolio, propale, risk, status, toast, type, updatePropale],
	);

	// sending propales

	const sendManualPropaleWithSub = useCallback(
		async (ids: Record<string, string[]>, amount: number) => {
			await updatePropale({
				id: propale!.id,
				amount,
				notionLink: dirtyFields.isNotionLinkDirty ? notionLink : undefined,
				status: PropaleStatus.SENT,
				mailData: sendEmail ? await formatMailData(mailSubject, mailBody, mailCC, mailAttachment) : undefined,
			});
			updateLinkedEntities({ id: propale!.id, ids });
		},
		// eslint-disable-next-line prettier/prettier
		[dirtyFields.isNotionLinkDirty, mailAttachment, mailBody, mailCC, mailSubject, notionLink, propale, sendEmail, updateLinkedEntities, updatePropale],
	);

	const sendManualPropaleNoSub = useCallback(
		async () =>
			updatePropale({
				id: propale!.id,
				status: PropaleStatus.SENT,
				notionLink: dirtyFields.isNotionLinkDirty ? notionLink : undefined,
				mailData: await formatMailData(mailSubject, mailBody, mailCC, mailAttachment),
			}),
		// eslint-disable-next-line prettier/prettier
		[dirtyFields.isNotionLinkDirty, mailAttachment, mailBody, mailCC, mailSubject, notionLink, propale, updatePropale],
	);

	const sendAutoPropaleNow = useCallback(async () => {
		// if any manual update on the propale, applying them before sending the propale
		if (Object.values(dirtyFields).some((i) => i === true)) await handleUpdate();
		sendAutoPropale(propale!.id)
			.unwrap()
			.then(() => toast({ title: 'Propale envoyée au client', status: 'success' }))
			.catch(() => toast({ title: "Erreur lors de l'envoi de la propale", status: 'error' }));
	}, [dirtyFields, handleUpdate, propale, sendAutoPropale, toast]);

	// helpers

	const handleDelete = useCallback(() => {
		deletePropale(propale!.id)
			.unwrap()
			.then(() => {
				navigate('..');
				onCloseDeleteDialog();
				toast({ title: 'Propale supprimée', status: 'success' });
			})
			.catch(() => toast({ title: 'Erreur lors de la suppression de la propale', status: 'error' }));
	}, [deletePropale, navigate, onCloseDeleteDialog, propale, toast]);

	useEffect(() => {
		if (isNotNone(propale) && propale.type === PropaleType.MANUAL) {
			setMailSubject(subjectManual);
			if (sendSubscription) setMailBody(templateManual(propale.firstName ?? ''));
			else setMailBody(templateManualNoSub(propale.firstName ?? '', notionLink ?? ''));
		}
	}, [notionLink, propale, sendSubscription]);

	if (isPropaleFetching) return <Skeleton h="100%" w="100%" />;
	if (isNone(propale))
		return (
			<Center>
				<Heading size="md">Propale non trouvée</Heading>
			</Center>
		);

	return (
		<VStack w="100%" spacing="12px" align="start">
			<Breadcrumb spacing="8px" separator={<ChevronRightIcon color="gray.500" />}>
				<BreadcrumbItem>
					<BreadcrumbLink onClick={() => navigate('..')}>Propales</BreadcrumbLink>
				</BreadcrumbItem>

				<BreadcrumbItem>
					<BreadcrumbLink>{propale.email}</BreadcrumbLink>
				</BreadcrumbItem>
			</Breadcrumb>

			<HStack w="100%" align="start" justify="space-between">
				<Heading size="lg">
					{propale.firstName} {propale.lastName} - {propale.type}
					{propale.subject ? ` - ${propale.subject}` : ''}
				</Heading>

				<HStack align="start">
					{/* button used to send directly a auto propale instead of watitng for daily cron to di it */}
					{propale.status === PropaleStatus.TODO && propale.type === PropaleType.AUTO && (
						<Button onClick={sendAutoPropaleNow} colorScheme="blue" isLoading={isSendingLoading}>
							Envoyer maintenant
						</Button>
					)}
					<Button
						onClick={handleUpdate}
						colorScheme="blue"
						isLoading={isUpdateLoading || isNotionLoading}
						isDisabled={Object.values(dirtyFields).every((i) => !i)}
					>
						Enregistrer les modifications
					</Button>

					<Menu closeOnSelect={false}>
						<MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
							Actions
						</MenuButton>
						<MenuList>
							<PermissionDisplayGuard permission={BoPermission.PROPALE_DELETE}>
								{propale.userId && (
									<MenuItem onClick={() => navigate(`/cpm/super/client/${propale.userId}`)}>Page client</MenuItem>
								)}
								<MenuItem color="red.600" onClick={openDeleteDialog}>
									Supprimer la propale
								</MenuItem>
							</PermissionDisplayGuard>
						</MenuList>
					</Menu>
				</HStack>
			</HStack>

			{propale.type === PropaleType.AUTO && propale.status === PropaleStatus.TODO && (
				<Text fontWeight="semibold">
					⚠️ Cette propale sera envoyée automatiquement au client le{' '}
					{new Date(
						propale.conversationPropale?.mailingFrameworkPosts.find((p) => p.type === MailingFrameworkPostType.MANUAL)
							?.toSendAt || '',
					).toLocaleString('fr-FR', {
						day: 'numeric',
						month: 'long',
						hour: 'numeric',
						minute: 'numeric',
					})}
				</Text>
			)}

			<HStack w="100%" align="start" spacing="12px" justify="space-between">
				<VStack spacing="12px" align="start" flex={3}>
					{/* Propale data section */}

					{propale.status === PropaleStatus.TODO && propale.type === PropaleType.MANUAL && (
						<Cardlayout title="Envoyer la propale">
							<VStack align="start" w="100%" spacing="24px">
								<VStack align="start" w="100%" spacing="16px">
									<Switch isChecked={sendEmail} onChange={(e) => setSendEmail(e.target.checked)}>
										Envoyer un mail
									</Switch>
									{sendEmail && (
										<VStack w="100%">
											<HStack w="100%" spacing="16px" align="center">
												<Text w="50px">Sujet</Text>
												<Input
													placeholder="Sujet du mail"
													value={mailSubject}
													onChange={(e) => setMailSubject(e.target.value)}
												/>
											</HStack>
											<HStack w="100%" spacing="16px" align="center">
												<Text w="50px">cc</Text>
												<Input placeholder="cc" value={mailCC} onChange={(e) => setMailCC(e.target.value)} />
											</HStack>
											<Textarea h="200px" value={mailBody} onChange={(e) => setMailBody(e.target.value)} />
											<Input
												type="file"
												multiple
												ref={inputFile}
												display="none"
												onChange={(e) => {
													setMailAttachment(Array.from(e.target.files ?? []));
													inputFile.current!.value = '';
												}}
											/>

											<Wrap shouldWrapChildren align="start" p="4px" w="100%">
												<Button onClick={() => inputFile.current?.click()}>Ajouter un document</Button>

												{mailAttachment &&
													Array.from(mailAttachment).map((file) => (
														<DocumentButton
															key={file.name}
															name={file.name}
															onDelete={() => setMailAttachment(mailAttachment.filter((m) => m.name !== file.name))}
														/>
													))}
											</Wrap>
										</VStack>
									)}
								</VStack>

								<Divider />

								<VStack w="100%" align="start" spacing="16px">
									<Switch isChecked={sendSubscription} onChange={(e) => setSendSubscription(e.target.checked)}>
										Créer une souscription
									</Switch>
									{sendSubscription && (
										<Box w="100%" mt="8px">
											<ProductPropaleNoBox
												isInDrawer
												customCpm={assignedCpm ? emailToCpm[assignedCpm] : undefined}
												customEmail={propale.email}
												customNotionLink={notionLink}
												onPostCreate={sendManualPropaleWithSub}
											/>
										</Box>
									)}

									{/* in case you want to send a mail with notion propale link but without creating a subsciption */}
									{sendEmail && !sendSubscription && (
										<Button
											colorScheme="blue"
											alignSelf="end"
											onClick={sendManualPropaleNoSub}
											isLoading={isUpdateLoading}
											isDisabled={!notionLink}
										>
											Envoyer la proposition
										</Button>
									)}
								</VStack>
							</VStack>
						</Cardlayout>
					)}

					<MailFollowUpSection title="Mail propale" conversationId={propale.conversationPropale?.id} />
					<MailFollowUpSection title="Mail post call" conversationId={propale.conversationPostCall?.id} />

					<CommentsSection
						comment={comments.comment}
						commentList={propale?.comments}
						onCreateComment={handleSendComment}
						onChangeComment={comments.onChangeComment}
						onDeleteComment={comments.onDeleteComment}
						isLoading={isUpdateLoading}
					/>
				</VStack>

				<VStack spacing="12px" align="start" flex={2}>
					<Cardlayout title="Mettre à jour">
						<VStack align="start" w="100%" spacing="12px">
							<Text>
								Créé par : <b>{cpmDisplayName[emailToCpm[propale.creatorCpmEmail]]}</b>
							</Text>
							<HStack w="100%" spacing="16px" align="end">
								<SelectWithLabel
									label="Cpm assigné"
									value={assignedCpm}
									onChange={(e) => setAssignedCpm(e.target.value)}
									flex={2}
								>
									{Object.values(CPM).map((cpm) => (
										<option key={cpm} value={cpmToEmail[cpm]}>
											{cpmDisplayName[cpm]}
										</option>
									))}
								</SelectWithLabel>
								<SelectWithLabel
									label="Probabilité de closing"
									value={leadClosingProbability}
									onChange={(e) => setLeadClosingProbability(e.target.value as LeadClosingProbability)}
									placeholder=" "
									flex={1}
								>
									<option value={LeadClosingProbability.HIGH}>Haute 🔥</option>
									<option value={LeadClosingProbability.MEDIUM}>Moyenne 🙂</option>
									<option value={LeadClosingProbability.LOW}>Basse 🥶</option>
								</SelectWithLabel>
							</HStack>

							{propale.type === PropaleType.FOLLOW_UP && (
								<SelectWithLabel
									label="Type de propale"
									value={type}
									onChange={(e) => setType(e.target.value as PropaleType)}
									isDisabled={propale.status !== PropaleStatus.TODO}
								>
									<option value={PropaleType.FOLLOW_UP}>FOLLOW_UP</option>
									<option value={PropaleType.MANUAL}>MANUAL</option>
								</SelectWithLabel>
							)}

							<SelectWithLabel
								label="Status"
								value={status}
								onChange={(e) => setStatus(e.target.value as PropaleStatus)}
								isDisabled={propale.status !== PropaleStatus.TODO && propale.type === PropaleType.AUTO}
							>
								{Object.values(PropaleStatus).map((s) => (
									<option key={s} value={s}>
										{statusNamingMap[s]}
									</option>
								))}
							</SelectWithLabel>

							<HStack w="100%" spacing="16px" align="end">
								<InputWithLabel
									label="Lien notion"
									placeholder="Lien notion"
									value={notionLink}
									isDisabled={propale.status !== PropaleStatus.TODO}
									onChange={(e) => setNotionLink(e.target.value)}
								/>
								<Button
									as="a"
									href={notionLink}
									target="_blank"
									isDisabled={(propale.type === PropaleType.AUTO && !propale.notionLink) || !notionLink}
								>
									<ExternalLinkIcon mx="8px" pb="2px" />
								</Button>
							</HStack>

							{propale.type === PropaleType.AUTO && (
								<HStack w="100%" spacing="16px" align="end">
									<SelectWithLabel
										label="Portfolio"
										value={portfolio}
										onChange={(e) => setPortfolio(e.target.value as PortfolioType)}
										isDisabled={propale.status !== PropaleStatus.TODO}
									>
										<option value="ELITE">Elite</option>
										<option value="FLAGSHIP">Flagship</option>
										<option value="ESSENTIAL">Essential</option>
									</SelectWithLabel>
									<SelectWithLabel
										label="Risque"
										value={risk}
										onChange={(e) => setRisk(e.target.value)}
										isDisabled={propale.status !== PropaleStatus.TODO}
									>
										<option value="3">3</option>
										<option value="6">6</option>
										{portfolio === PortfolioType.ELITE && <option value="8">8</option>}
										<option value="10">10</option>
									</SelectWithLabel>
									<SelectWithLabel
										label="ESG"
										value={green}
										onChange={(e) => setGreen(e.target.value)}
										isDisabled={propale.status !== PropaleStatus.TODO}
									>
										<option value="true">Oui</option>
										<option value="false">Non</option>
									</SelectWithLabel>
								</HStack>
							)}

							<VStack align="start" w="100%">
								<Text>Notes de call</Text>
								<Textarea
									value={notes}
									onChange={(e) => setNotes(e.target.value)}
									h="250px"
									w="100%"
									isDisabled={propale.status !== PropaleStatus.TODO}
								/>
							</VStack>
						</VStack>
					</Cardlayout>

					<Cardlayout title="Deadline">
						<ChangePropaleOverdueDate overdueItem={propale} />
					</Cardlayout>
				</VStack>

				<AlertDialog
					isOpen={isDeleteDialogOpen}
					onClose={onCloseDeleteDialog}
					header="Supprimer la propale"
					body={<Text>Vous êtes sur le point de supprimer cette propale.</Text>}
					footer={
						<>
							<Button onClick={onCloseDeleteDialog}>Annuler</Button>
							<Button colorScheme="red" ml={3} isLoading={isDeleteLoading} onClick={handleDelete}>
								Valider
							</Button>
						</>
					}
				/>
			</HStack>
		</VStack>
	);
};

export default PropaleDetails;
