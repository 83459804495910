import { LegalEntity, SubscriptionStatus } from 'types/global.type';

export enum GirardinProvider {
	INTER_INVEST = 'INTER_INVEST',
	CLUB_FUNDING = 'CLUB_FUNDING',
}

export const girardinProviderLabels: Record<GirardinProvider, string> = {
	[GirardinProvider.INTER_INVEST]: 'Inter Invest',
	[GirardinProvider.CLUB_FUNDING]: 'Club Funding',
};

type GirardinSubscriptionStatus =
	| SubscriptionStatus.PENDING
	| SubscriptionStatus.KYC_VALIDATION
	| SubscriptionStatus.CONTRACT_SENT_TO_CLIENT
	| SubscriptionStatus.SIGNED
	| SubscriptionStatus.PAYMENT
	| SubscriptionStatus.REGISTERED
	| SubscriptionStatus.COMPLETED
	| SubscriptionStatus.TERMINATED;

export type GirardinSubscription = {
	id: string;
	createdAt: string;
	updatedAt: string;

	userId: string;
	legalEntity: LegalEntity;
	moralPersonId?: string;

	initialDepositAmount: string;

	provider: GirardinProvider;

	status: GirardinSubscriptionStatus;
	pendingAt?: string;
	kycValidationAt?: string;
	contractSentToClientAt?: string;
	signedAt?: string;
	paymentAt?: string;
	registeredAt?: string;
	completedAt?: string;
	terminatedAt?: string;

	opsPropertiesId?: string;
	hubspotId?: string;
};
