import { PropsWithChildren } from 'react';
import {
	CloseButtonProps,
	Modal,
	ModalBody,
	ModalBodyProps,
	ModalCloseButton,
	ModalContent,
	ModalContentProps,
	ModalFooter,
	ModalFooterProps,
	ModalHeader,
	ModalHeaderProps,
	ModalOverlay,
	ModalProps,
} from '@chakra-ui/react';

type CustomModalProps = ModalProps &
	PropsWithChildren<{
		contentProps?: ModalContentProps;
		closeButtonProps?: CloseButtonProps;
		bodyProps?: ModalBodyProps;
		headersProps?: ModalHeaderProps;
		footerProps?: ModalFooterProps;
	}>;

const CustomModal = ({
	children,
	bodyProps,
	contentProps,
	closeButtonProps,
	headersProps,
	footerProps,
	...props
}: CustomModalProps): JSX.Element => (
	<Modal size={{ base: 'md', md: '2xl' }} {...props}>
		<ModalOverlay />
		<ModalContent {...contentProps}>
			<ModalHeader {...headersProps} />
			<ModalCloseButton {...closeButtonProps} />
			<ModalBody {...bodyProps}>{children}</ModalBody>
			<ModalFooter {...footerProps} />
		</ModalContent>
	</Modal>
);

export default CustomModal;
