export enum CPM {
	ARNAUD = 'ARNAUD',
	HUGO = 'HUGO',
	HUGO_A = 'HUGO_A',
	MEHDI = 'MEHDI',
	OLIVIER = 'OLIVIER',
}

export const cpmDisplayName: Record<CPM, string> = {
	[CPM.ARNAUD]: 'Arnaud Ton-That',
	[CPM.HUGO]: 'Hugo Toson',
	[CPM.HUGO_A]: 'Hugo Alves Ferreira',
	[CPM.MEHDI]: 'Mehdi Berrada',
	[CPM.OLIVIER]: 'Olivier Herbout',
};

export const cpmToEmail: Record<CPM, string> = {
	[CPM.ARNAUD]: 'arnaud.tonthat@ramify.fr',
	[CPM.HUGO]: 'hugo.toson@ramify.fr',
	[CPM.HUGO_A]: 'hugo.alvesferreira@ramify.fr',
	[CPM.MEHDI]: 'mehdi.berrada@ramify.fr',
	[CPM.OLIVIER]: 'olivier.herbout@ramify.fr',
};

export const cpmToCalendly: Record<Partial<CPM>, string> = {
	[CPM.ARNAUD]: 'https://calendly.com/d/xz6-x24-dmp/echange-avec-un-conseiller-ramify-arnaud',
	[CPM.HUGO]: 'https://calendly.com/d/25y-qq7-xfn/echange-avec-un-conseiller-ramify-hugo',
	[CPM.MEHDI]: 'https://calendly.com/d/zgy-s46-9bf/echange-avec-un-conseiller-ramify-mehdi',
	[CPM.OLIVIER]: 'https://calendly.com/d/yhd-jms-crd/echange-avec-un-conseiller-ramify-olivier',
	[CPM.HUGO_A]: 'https://calendly.com/d/dvk-jm7-7d9/echange-avec-un-conseiller-ramify', // unknown rdv type
};

export const emailToCpm: Record<string, CPM> = Object.entries(cpmToEmail).reduce(
	(acc, [cpm, email]) => ({ ...acc, [email]: cpm as CPM }),
	{} as Record<string, CPM>,
);
