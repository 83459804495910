import { FC, useMemo } from 'react';
import { Text, VStack } from '@chakra-ui/react';

import { useSearchAlertsQuery } from 'services/ops/alerts';
import { useAppSelector } from 'store';

import AlertsFilters from './Elements/AlertsFilters';
import AlertsTable from './Elements/AlertsTable';

const NoAlerts: FC = () => (
	<VStack mt="24px" w="100%" px="32px">
		<Text fontSize="32px" color="gray.600">
			Aucune alerte
		</Text>
		<Text fontSize="64px" color="gray.600">
			🧹
		</Text>
	</VStack>
);

const AllAlertsPage = () => {
	const { filters, pagination } = useAppSelector((state) => state.alertsTool);
	const { data, isFetching } = useSearchAlertsQuery({ filters, pagination });
	const alertsList = useMemo(() => data?.results, [data]);

	const currentSelectionTotalAlerts = useMemo(() => {
		if (filters.fromCron) return data?.totalCron || 0;
		return data?.totalUser || 0;
	}, [filters.fromCron, data?.totalCron, data?.totalUser]);

	if (!alertsList) return <NoAlerts />;

	return (
		<VStack mt="24px" w="100%" px="32px" spacing="40px" marginBottom="60px">
			<AlertsFilters totalAlerts={{ cron: data?.totalCron || 0, user: data?.totalUser || 0 }} />
			<AlertsTable totalAlerts={currentSelectionTotalAlerts} isFetching={isFetching} alerts={alertsList} />
		</VStack>
	);
};

export default AllAlertsPage;
