import { FC, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Button, chakra, Checkbox, HStack, Text, VStack, Wrap } from '@chakra-ui/react';

import DocumentButton from 'components/documentButton';
import YesNoIcon from 'components/icons/YesNo';
import useAssignedOps, { SelectAssignedOps } from 'hooks/useAssignedOps';
import useThemedToast from 'hooks/useThemedToast';
import { AllDeal } from 'services/deal';
import { RamifyDoc } from 'services/document';
import { SendApiSubscription, useSendSubscriptionApiMutation } from 'services/subscription';
import { documentDataMap, DocumentName } from 'utils/documentNamingMap';
import { displayMoney, isNone, isNotNone } from 'utils/functions';

export enum ApiPartner {
	APICIL = 'apicil',
	CFCAL = 'cfcal',
	PEQAN = 'peqan',
	EURAZEO = 'eurazeo',
}

const createAttachment = (doc: RamifyDoc | undefined) =>
	isNotNone(doc) ? { documentName: doc.documentName, filename: doc.filename, url: doc.url } : undefined;

const verifyDocList = (docs: RamifyDoc[], partner: ApiPartner) => {
	const docList = necessaryDocs
		.filter((doc) => (doc.partner ? doc.partner.includes(partner) : true))
		.filter((doc) => doc.required)
		.map((doc) => doc.key);

	docs.forEach((doc) => {
		if (docList.includes(doc.documentName)) docList.splice(docList.indexOf(doc.documentName), 1);
	});

	return docList.length === 0;
};

const necessaryDocs = [
	{
		key: DocumentName.IBAN,
		required: true,
	},
	{
		key: DocumentName.PIECE_IDENTITE,
		required: true,
	},
	{
		key: DocumentName.PIECE_IDENTITE_VERSO,
		required: false,
	},
	{
		key: DocumentName.JUSTIFICATIF_DOMICILE,
		required: true,
	},
	{
		key: DocumentName.JOF,
		required: false,
		partner: [ApiPartner.APICIL, ApiPartner.CFCAL],
	},
	{
		key: DocumentName.JOF,
		required: true,
		partner: [ApiPartner.EURAZEO, ApiPartner.PEQAN],
	},
];

type SubscriptionSendApiProps = {
	subscription: AllDeal;
	partner: ApiPartner;
	selectedDocuments: RamifyDoc[];
	setSelectedDocuments: (docs: RamifyDoc[]) => void;
};

const SubscriptionSendApi: FC<SubscriptionSendApiProps> = ({
	subscription,
	partner,
	selectedDocuments,
	setSelectedDocuments,
}) => {
	const toast = useThemedToast();
	const navigate = useNavigate();

	const [sendSubscription, { isLoading: isLoadingSendSubscription }] = useSendSubscriptionApiMutation();
	const hasAllDocs = useMemo(() => verifyDocList(selectedDocuments, partner), [partner, selectedDocuments]);
	const { assignedOps, onChangeAssignedOps } = useAssignedOps();

	const methods = useForm<SendApiSubscription>({
		defaultValues: { subscriptionId: subscription.id, sendClientConfirmationMail: false },
	});

	useEffect(() => {
		if (subscription.id) methods.setValue('subscriptionId', subscription.id);
		if (subscription.hasBlockingInstance) methods.setValue('sendClientConfirmationMail', true);
	}, [methods, subscription]);

	const handleSend = methods.handleSubmit((data: SendApiSubscription) => {
		sendSubscription({
			...data,
			partner,
			assignedOps,
			rib: createAttachment(selectedDocuments.find((d) => d.documentName === DocumentName.IBAN)),
			identity: createAttachment(selectedDocuments.find((d) => d.documentName === DocumentName.PIECE_IDENTITE)),
			identityVerso: createAttachment(
				selectedDocuments.find((d) => d.documentName === DocumentName.PIECE_IDENTITE_VERSO),
			),
			jod: createAttachment(selectedDocuments.find((d) => d.documentName === DocumentName.JUSTIFICATIF_DOMICILE)),
			jof: createAttachment(selectedDocuments.find((d) => d.documentName === DocumentName.JOF)),
			transferForms: selectedDocuments
				.filter((d) => d.documentName.split('-')[0] === 'form')
				.map((d) => createAttachment(d)!),
		})
			.unwrap()
			.then(() => {
				navigate('..');
				toast({ status: 'success', title: 'Succès', description: 'Souscription envoyée avec succès' });
			})
			.catch((err) => toast({ status: 'error', title: 'Erreur', description: err.data.message }));
	});

	if (isNone(subscription)) return null;

	return (
		<chakra.form w="100%" onSubmit={handleSend}>
			<VStack align="start" w="100%">
				<FormProvider {...methods}>
					{subscription.hasBlockingInstance && (
						<HStack>
							<Checkbox {...methods.register('sendClientConfirmationMail')} />
							<Text>Envoyer un mail de confirmation au client</Text>
						</HStack>
					)}
				</FormProvider>
				<SelectAssignedOps assignedOps={assignedOps} onChangeAssignedOps={onChangeAssignedOps} />

				<VStack w="100%">
					<VStack w="100%" align="start" borderWidth="2px" borderColor="gray.100" p="8px" position="relative">
						<Text>
							Montant de la souscription:{' '}
							<b>{displayMoney('initialDepositAmount' in subscription ? subscription.initialDepositAmount : 0)}</b>
						</Text>
						<HStack spacing="64px">
							<VStack align="start" spacing="0px">
								{necessaryDocs
									.filter((doc) => (doc.partner ? doc.partner.includes(partner) : true))
									.map(({ key, required }) => (
										<HStack key={key} w="300px" justify="space-between">
											<Text fontSize="sm">
												{documentDataMap[key].displayName}
												{!required ? ' (optionnel)' : ''}
											</Text>
											<YesNoIcon isTrue={selectedDocuments.some((d) => d.documentName === key)} />
										</HStack>
									))}
							</VStack>

							<Wrap shouldWrapChildren w="60%" align="start" p="4px">
								{selectedDocuments.map((doc) => (
									<DocumentButton
										key={doc.url}
										name={documentDataMap[doc.documentName]?.displayName ?? doc.documentName}
										onDelete={() => setSelectedDocuments(selectedDocuments.filter((d) => d.url !== doc.url))}
									/>
								))}
							</Wrap>
						</HStack>

						<Button
							position="absolute"
							right="8px"
							bottom="8px"
							colorScheme="blue"
							isLoading={isLoadingSendSubscription}
							isDisabled={!hasAllDocs}
							type="submit"
						>
							Envoyer
						</Button>
					</VStack>
				</VStack>
			</VStack>
		</chakra.form>
	);
};

export default SubscriptionSendApi;
