import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CheckIcon, DeleteIcon, QuestionIcon, ViewIcon } from '@chakra-ui/icons';
import {
	Avatar,
	Badge,
	Box,
	Button,
	Checkbox,
	Heading,
	HStack,
	IconButton,
	Skeleton,
	Table,
	TableCellProps,
	TableColumnHeaderProps,
	TableContainer,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tooltip,
	Tr,
	VStack,
} from '@chakra-ui/react';

import useThemedToast from 'hooks/useThemedToast';
import {
	AlertPriority,
	OpsAlert,
	useDeleteManyAlertsMutation,
	useResolveManyAlertsMutation,
} from 'services/ops/alerts';
import { useAppSelector } from 'store';

import AlertsPagination from './AlertsPagination';

const TableValue = ({ width, children, ...props }: TableCellProps) => (
	<Td overflowX="auto" borderColor="gray.300" minW={width} maxW={width} {...props}>
		{children}
	</Td>
);

const TableHeader = ({ children, ...props }: TableColumnHeaderProps) => (
	<Th borderColor="gray.300" {...props}>
		{children}
	</Th>
);

const AlertsTable = ({
	isFetching,
	alerts,
	totalAlerts,
}: {
	isFetching: boolean;
	alerts: OpsAlert[];
	totalAlerts: number;
}) => {
	const toast = useThemedToast();
	const { pagination } = useAppSelector((state) => state.alertsTool);
	const pageSize = useMemo(() => pagination?.limit, [pagination]);

	const [resolveManyAlerts] = useResolveManyAlertsMutation();
	const [deleteManyAlerts] = useDeleteManyAlertsMutation();

	const navigate = useNavigate();

	const [selectedAlerts, setSelectedAlerts] = useState<Map<string, boolean>>(new Map());

	const onSelect = (id: string) => {
		const newSelectedAlerts = new Map(selectedAlerts);
		if (newSelectedAlerts.has(id)) newSelectedAlerts.delete(id);
		else newSelectedAlerts.set(id, true);
		setSelectedAlerts(newSelectedAlerts);
	};

	const onSelectGlobal = () => {
		if (selectedAlerts.size > 0) {
			setSelectedAlerts(new Map());
		} else {
			const newSelectedAlerts = new Map<string, boolean>();
			alerts.forEach((alert) => newSelectedAlerts.set(alert.id, true));
			setSelectedAlerts(newSelectedAlerts);
		}
	};

	const onDeleteMany = () => {
		deleteManyAlerts([...selectedAlerts.keys()])
			.unwrap()
			.then(() => toast({ status: 'success', title: 'Alertes supprimées avec succès' }))
			.catch(() => toast({ status: 'error', title: "Les alertes n'ont pas pu être supprimées" }));
		setSelectedAlerts(new Map());
	};
	const onResolveMany = () => {
		resolveManyAlerts([...selectedAlerts.keys()])
			.unwrap()
			.then(() => toast({ status: 'success', title: 'Alertes résolues avec succès' }))
			.catch(() => toast({ status: 'error', title: "Les alertes n'ont pas pu être résolues" }));
		setSelectedAlerts(new Map());
	};

	const isAlertSelected = (id: string) => !!selectedAlerts.get(id);

	const getColorFromPriority = (priority: AlertPriority) => {
		switch (priority) {
			case AlertPriority.LOW:
				return 'gray';
			case AlertPriority.MEDIUM:
				return 'yellow';
			case AlertPriority.HIGH:
				return 'orange';
			case AlertPriority.URGENT:
				return 'red';
			case AlertPriority.INFORMATIVE:
				return 'blue';
			default:
				return 'gray';
		}
	};

	return (
		<VStack align="start" w="100%" spacing="24px">
			<HStack w="100%" justify="space-between">
				<HStack spacing="12px">
					<Heading
						size="md"
						color={selectedAlerts.size > 0 ? 'blue.500' : 'gray.700'}
						fontWeight="700"
						whiteSpace="nowrap"
					>
						{selectedAlerts.size > 0 ? `${selectedAlerts.size} Alertes sélectionnées` : 'Alertes en cours'}
					</Heading>

					{selectedAlerts.size > 0 && (
						<HStack spacing="8px">
							<Tooltip label="Marquer comme résolues">
								<IconButton
									size="sm"
									aria-label="Résoudre les alertes sélectionnées"
									icon={<CheckIcon />}
									colorScheme="green"
									onClick={onResolveMany}
								/>
							</Tooltip>
							<Tooltip label="Supprimer les alertes sélectionnées">
								<IconButton
									size="sm"
									aria-label="Supprimer les alertes sélectionnées"
									icon={<DeleteIcon />}
									colorScheme="red"
									isDisabled={selectedAlerts.size === 0}
									onClick={onDeleteMany}
								/>
							</Tooltip>
						</HStack>
					)}
				</HStack>

				<AlertsPagination totalAlerts={totalAlerts} />
			</HStack>
			<TableContainer w="100%" bg="white" paddingTop="8px" borderRadius="8px" border="1px solid" borderColor="gray.300">
				<Table w="100%" variant="simple" size="md" __css={{ tableLayout: 'fixed' }}>
					<Thead w="100%">
						<Tr w="100%">
							<TableHeader w="60px">
								<Checkbox
									isIndeterminate={
										selectedAlerts.size > 0 &&
										(selectedAlerts.size !== alerts.length || !selectedAlerts.get(alerts?.[0]?.id))
									}
									isChecked={selectedAlerts.size === alerts.length && !!selectedAlerts.get(alerts?.[0]?.id)}
									onChange={() => onSelectGlobal()}
								/>
							</TableHeader>
							<TableHeader w="100px">
								<Heading size="xs">Status</Heading>
							</TableHeader>
							<TableHeader w="120px">
								<Heading size="xs">Priorité </Heading>
							</TableHeader>
							<TableHeader w="100%">
								<Heading size="xs">Alerte</Heading>
							</TableHeader>
							<TableHeader w="250px">Produit</TableHeader>
							<TableHeader w="350px">Service - Groupe</TableHeader>
							<TableHeader w="220px">
								<Heading size="xs">Date / Heure</Heading>
							</TableHeader>
							<TableHeader w="140px">
								<Heading size="xs">Actions</Heading>
							</TableHeader>
						</Tr>
					</Thead>
					{!isFetching && (
						<Tbody w="100%">
							{alerts.map((alert) => (
								<Tr w="100%" key={alert.id} bg={isAlertSelected(alert.id) ? 'blue.50 !important' : 'white'}>
									<TableValue
										cursor="pointer"
										onClick={(e) => {
											e.stopPropagation();
											onSelect(alert.id);
										}}
										_hover={{ bg: 'gray.50' }}
									>
										{/* https://github.com/chakra-ui/chakra-ui/issues/2854 */}
										<Box pointerEvents="none">
											<Checkbox isChecked={isAlertSelected(alert.id)} isReadOnly />
										</Box>
									</TableValue>

									<TableValue>
										{!!alert.resolvedAt ? (
											<Badge colorScheme="green">
												<CheckIcon boxSize="12px" /> Résolu
											</Badge>
										) : (
											<Badge>Ouvert</Badge>
										)}
									</TableValue>

									<TableValue>
										{!!alert.priority && (
											<Badge colorScheme={getColorFromPriority(alert.priority)}>{alert.priority}</Badge>
										)}
									</TableValue>

									<TableValue>
										<VStack align="start">
											<HStack>
												<Text overflowX="clip" textOverflow="ellipsis" color="gray.900" fontWeight="600">
													{alert.title}
												</Text>
												<Tooltip label={alert.description}>
													<QuestionIcon color="gray.700" />
												</Tooltip>
											</HStack>

											{alert.impactedUser && (
												<HStack spacing="8px">
													<Tooltip
														label={`${alert.impactedUser?.kyc?.firstName} ${alert.impactedUser?.kyc?.lastName}`}
														placement="top"
													>
														<Avatar
															bg="gray.600"
															color="white"
															size="xs"
															name={`${alert.impactedUser?.kyc?.firstName} ${alert.impactedUser?.kyc?.lastName}`}
														/>
													</Tooltip>
													<Text>{alert.impactedUser?.email}</Text>
												</HStack>
											)}
										</VStack>
									</TableValue>

									<TableValue>
										<Text overflowX="clip" textOverflow="ellipsis">
											{alert.product}
										</Text>
									</TableValue>
									<TableValue>
										<VStack align="start" w="100%">
											<Text overflowX="clip" textOverflow="ellipsis">
												{alert.service}
											</Text>
											<Badge colorScheme="gray">{alert.groupId}</Badge>
										</VStack>
									</TableValue>

									<TableValue>
										{new Date(alert.createdAt).toLocaleDateString('fr-FR')}
										{' - '}
										{new Date(alert.createdAt).toLocaleTimeString('fr-FR')}
									</TableValue>
									<TableValue>
										<Button onClick={() => navigate(`./${alert.id}`)} px="20px">
											<HStack spacing="8px">
												<ViewIcon />
												<Text>Voir</Text>
											</HStack>
										</Button>
									</TableValue>
								</Tr>
							))}
						</Tbody>
					)}
				</Table>
				{isFetching && (
					<VStack w="100%" align="start" spacing="8px">
						{[...Array(pageSize > 0 ? pageSize : 5)].map((_, index) => (
							<Skeleton w="100%" h="60px" key={index.toString()} />
						))}
					</VStack>
				)}
			</TableContainer>
		</VStack>
	);
};

export default AlertsTable;
