export enum MailingFrameworkPostType {
	MANUAL = 'MANUAL',
	WORKFLOW = 'WORKFLOW',
}

export type MailingFrameworkPost = {
	id: string;
	messageId?: string;
	createdAt: string;
	updatedAt: string;
	message?: string;
	templateId?: number;
	templateParams?: unknown;
	type: MailingFrameworkPostType;
	toSendAt?: string;
	sentAt?: string;
	receivedAt?: string;
	opened: boolean;
	mailingFrameworkConversation?: MailingFrameworkConversation;
	mailingFrameworkConversationId?: string;
};

export type MailingFrameworkConversation = {
	id: string;
	createdAt: string;
	updatedAt: string;
	sender: string;
	receiver: string[];
	cc?: string[];
	bcc?: string[];
	subject: string;
	mailingFrameworkPosts: MailingFrameworkPost[];
	authorEmail: string;
	hasReplied: boolean;

	opsPropertiesId?: string;
	cpmPropalePostCallId?: string;
	cpmPropalePropaleId?: string;
};
