import React from 'react';
import { InfoIcon } from '@chakra-ui/icons';
import { Box, BoxProps, Popover, PopoverContent, PopoverProps, PopoverTrigger, Text } from '@chakra-ui/react';

const CustomTooltip = ({
	text,
	content,
	color = 'gray.500',
	boxSize = '16px',
	padding = { base: '8px', xl: '4px' },
	placement = 'auto',
	...props
}: {
	text?: React.ReactNode;
	content?: React.ReactNode;
	color?: string;
	boxSize?: string;
	padding?: BoxProps['p'];
	placement?: PopoverProps['placement'];
} & PopoverProps): JSX.Element => (
	<Popover placement={placement} trigger="hover" {...props}>
		<PopoverTrigger>
			<Box p={padding}>
				<InfoIcon boxSize={boxSize} cursor="pointer" color={color} alignSelf="center" />
			</Box>
		</PopoverTrigger>
		<Box zIndex={999} bg="red">
			<PopoverContent p={4} w="auto">
				{text && (
					<Text as="span" fontSize="14px" color="gray.700" whiteSpace="normal">
						{text}
					</Text>
				)}
				{content}
			</PopoverContent>
		</Box>
	</Popover>
);

export default CustomTooltip;
