import React, { FC } from 'react';
import { Divider, HStack, Text, VStack } from '@chakra-ui/react';

import { MailingFrameworkPost, MailingFrameworkPostType } from 'types/mailing-framework.type';

type DisplayPostProps = {
	postData: MailingFrameworkPost;
};

const DisplayPost: FC<DisplayPostProps> = ({ postData }) => {
	// DELETE POST
	// const [deletePost] = useDeletePostMutation();

	// const handleDeletePost = async () => {
	// 	if (!postData) return;
	// 	await deletePost({ postId: postData.id });
	// };

	if (!postData) return null;

	return (
		<VStack
			w="100%"
			p="16px"
			borderRadius="10px"
			borderWidth="1px"
			borderColor={postData.type === MailingFrameworkPostType.WORKFLOW ? 'purple.600' : 'gray.200'}
			bg="white"
			spacing="12px"
			align="left"
		>
			<VStack w="100%" align="start">
				{/*Allow to remove a workflow message - disable for now*/}
				{/*{postData.type === MailingFrameworkPostType.WORKFLOW && postData.toSendAt && (*/}
				{/*	<>*/}
				{/*		<Text>toSendAt: {postData.toSendAt || 'None'}</Text>*/}
				{/*		<Button size="xs" colorScheme="red" onClick={handleDeletePost}>*/}
				{/*			X*/}
				{/*		</Button>*/}
				{/*	</>*/}
				{/*)}*/}

				<VStack w="100%" align="start">
					<HStack>
						<Text minW="50px">
							<b>Envoyé le</b>:
						</Text>
						<Text>{postData.sentAt || 'En attente'}</Text>
					</HStack>
					<HStack>
						<Text minW="50px">
							<b>reçu le</b>:
						</Text>
						<Text>{postData.receivedAt || 'En attente'}</Text>
					</HStack>
					<HStack>
						<Text minW="50px">
							<b>Ouvert</b>:
						</Text>
						<Text>{postData.opened ? 'oui' : 'non'}</Text>
					</HStack>
					{postData.toSendAt && (
						<HStack>
							<Text minW="50px">
								<b>Envoie programmé le</b>:
							</Text>
							<Text>{postData.toSendAt}</Text>
						</HStack>
					)}
				</VStack>
			</VStack>

			<Divider borderWidth="1px" borderColor="dark" />

			<VStack w="100%" align="start" display="inline-block">
				{postData?.message?.split('\n').map((line) => (
					<Text key={line} overflowWrap="break-word" mb="8px">
						{line}
					</Text>
				))}
			</VStack>
		</VStack>
	);
};

export default DisplayPost;
