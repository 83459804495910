import { dashboardApi } from 'store/api';
import { BankInformation } from 'types/bank-information.type';

export const endpoints = dashboardApi.injectEndpoints({
	endpoints: (builder) => ({
		getUserBankAccounts: builder.query<BankInformation[], { email: string }>({
			query: ({ email }) => ({
				url: `/bo/ops/bank-informations/${email}`,
				method: 'GET',
				responseHandler: 'content-type',
				cache: 'no-cache',
			}),
			providesTags: ['OPS_DOCUMENTS'],
		}),
		updateBankAccountJustificatif: builder.mutation<void, { body: FormData; bankId: string }>({
			query: (content) => ({
				url: `/bo/ops/bank-informations/${content.bankId}/justificatif`,
				method: 'PUT',
				body: content.body,
			}),
			invalidatesTags: ['OPS_DOCUMENTS'],
		}),

		createBankAccountForUser: builder.mutation<void, { body: FormData; userEmail: string }>({
			query: (content) => ({
				url: `/bo/ops/bank-informations/${content.userEmail}`,
				method: 'POST',
				body: content.body,
			}),
			invalidatesTags: ['OPS_DOCUMENTS'],
		}),
	}),
});

export const {
	useGetUserBankAccountsQuery,
	useLazyGetUserBankAccountsQuery,
	useUpdateBankAccountJustificatifMutation,
	useCreateBankAccountForUserMutation,
} = endpoints;
