export enum ProfessionalCategory {
	FARMER_OPERATOR = 'FARMER_OPERATOR',
	CRAFTSMAN = 'CRAFTSMAN',
	ADMINISTRATIVE_AND_COMMERCIAL_COMPANY_EXECUTIVE = 'ADMINISTRATIVE_AND_COMMERCIAL_COMPANY_EXECUTIVE',
	PUBLIC_SERVICE_EXECUTIVE = 'PUBLIC_SERVICE_EXECUTIVE',
	DRIVER = 'DRIVER',
	COMPANY_MANAGER = 'COMPANY_MANAGER',
	SHOPKEEPER_AND_SIMILAR = 'SHOPKEEPER_AND_SIMILAR',
	FOREMAN_SUPERVISOR = 'FOREMAN_SUPERVISOR',
	ADMINISTRATIVE_EMPLOYEE_OF_A_COMPANY = 'ADMINISTRATIVE_EMPLOYEE_OF_A_COMPANY',
	CIVILIAN_EMPLOYEE_IN_THE_PUBLIC_SERVICE = 'CIVILIAN_EMPLOYEE_IN_THE_PUBLIC_SERVICE',
	COMMERCIAL_EMPLOYEE = 'COMMERCIAL_EMPLOYEE',
	ENGINEER_AND_TECHNICAL_EXECUTIVE = 'ENGINEER_AND_TECHNICAL_EXECUTIVE',
	MEMBER_OF_THE_COULTRY_RELIGIOUS = 'MEMBER_OF_THE_COULTRY_RELIGIOUS',
	AGRICULTURAL_WORKER = 'AGRICULTURAL_WORKER',
	UNQUALIFIED_WORKER = 'UNQUALIFIED_WORKER',
	QUALIFIED_WORKER = 'QUALIFIED_WORKER',
	DIRECT_SERVICES_TO_INDIVIDUALS = 'DIRECT_SERVICES_TO_INDIVIDUALS',
	SCHOOL_TEACHER_AND_SIMILAR = 'SCHOOL_TEACHER_AND_SIMILAR',
	PROFESSOR_SCIENTIFIC_PROFESSION = 'PROFESSOR_SCIENTIFIC_PROFESSION',
	MEDIA_PROFESSION = 'MEDIA_PROFESSION',
	INTERMEDIATE_ADMINISTRATIVE_PROFESSION = 'INTERMEDIATE_ADMINISTRATIVE_PROFESSION',
	INTERMEDIATE_HEALTH_PROFESSIONALS = 'INTERMEDIATE_HEALTH_PROFESSIONALS',
	LIBERAL_PROFESSION_AND_SIMILAR = 'LIBERAL_PROFESSION_AND_SIMILAR',
	PROFESSIONAL_SPORTSMAN = 'PROFESSIONAL_SPORTSMAN',
	TECHNICIAN = 'TECHNICIAN',
}

const ProfessionalCategoryReferentiel: Record<ProfessionalCategory, string> = {
	FARMER_OPERATOR: 'Agriculteur exploitant',
	CRAFTSMAN: 'Artisan',
	ADMINISTRATIVE_AND_COMMERCIAL_COMPANY_EXECUTIVE: "Cadre admin. et commercial d'entreprise",
	PUBLIC_SERVICE_EXECUTIVE: 'Cadre de la fonction publique',
	DRIVER: 'Chauffeur',
	COMPANY_MANAGER: "Chef(fe) d'entreprise",
	SHOPKEEPER_AND_SIMILAR: 'Commerçant et assimilé',
	FOREMAN_SUPERVISOR: 'Contremaître, agent de maîtrise',
	ADMINISTRATIVE_EMPLOYEE_OF_A_COMPANY: "Employé(e) administratif d'entreprise",
	CIVILIAN_EMPLOYEE_IN_THE_PUBLIC_SERVICE: 'Employé(e) civil de la fonction publique',
	COMMERCIAL_EMPLOYEE: 'Employé(e) de commerce',
	ENGINEER_AND_TECHNICAL_EXECUTIVE: "Ingénieur(e) et cadre technique d'entreprise",
	MEMBER_OF_THE_COULTRY_RELIGIOUS: 'Membre du clergé, religieux',
	AGRICULTURAL_WORKER: 'Ouvrier(e) agricole',
	UNQUALIFIED_WORKER: 'Ouvrier(e) non qualifié(e)',
	QUALIFIED_WORKER: 'Ouvrier(e) qualifié(e)',
	DIRECT_SERVICES_TO_INDIVIDUALS: 'Personnel des services directs aux particuliers',
	SCHOOL_TEACHER_AND_SIMILAR: 'Professeur des écoles et assimilé',
	PROFESSOR_SCIENTIFIC_PROFESSION: 'Professeur, profession scientifique',
	MEDIA_PROFESSION: 'Profession des médias',
	INTERMEDIATE_ADMINISTRATIVE_PROFESSION: 'Profession intermédiaire administrative',
	INTERMEDIATE_HEALTH_PROFESSIONALS: 'Profession intermédiaire de la santé',
	LIBERAL_PROFESSION_AND_SIMILAR: 'Profession libérale et assimilé',
	PROFESSIONAL_SPORTSMAN: 'Sportif(ve) professionnel(le)',
	TECHNICIAN: 'Technicien',
};

export default ProfessionalCategoryReferentiel;
