import { OpsBiKpi, OpsDealKpi } from 'services/ops/kpi';
import { isNotNone } from 'utils/functions';

export enum OpsKpiType {
	PRODUCT = 'product',
	BLOCKING_INSTANCE = 'blocking_instance',
	PROCESS = 'process',
}

export interface OpsKpiDealDisplay extends OpsDealKpi {
	treatedTimeDisplay: string | false;
	submittedTimeDisplay: string | false;
	completedTimeDisplay: string | false;

	treatmentDurationDisplay: string;
	treatmentDurationDisplayHours: string;
	completionDurationDisplay: string;
	completionDurationDisplayHours: string;

	incoherentTreatmentData: boolean;
	incoherentCompletionData: boolean;

	unpreciseTreatmentData: boolean;
	unpreciseCompletionData: boolean;
}

export interface OpsKpiBlockingInstanceDisplay extends OpsBiKpi {
	durationADisplay: string;
	durationADisplayHours: string;
	durationBDisplay: string;
	durationBDisplayHours: string;
	durationCDisplay: string;
	durationCDisplayHours: string;
	durationDDisplay: string;
	durationDDisplayHours: string;

	incoherentData: boolean;

	openAtDisplay: string | false;
	sentToClientAtDisplay: string | false;
	documentsSubmittedAtDisplay: string | false;
	sentToPartnerAtDisplay: string | false;
	closedAtDisplay: string | false;
}

const dateToDisplayString = (date: string) =>
	isNotNone(date) &&
	new Date(date).toLocaleDateString('FR-fr', {
		day: '2-digit',
		month: '2-digit',
		year: 'numeric',
		hour: '2-digit',
		minute: '2-digit',
	});

export const formatDealKpiData = (dataToFormat: OpsDealKpi[]): OpsKpiDealDisplay[] =>
	dataToFormat.map((d) => ({
		...d,
		treatedTimeDisplay: dateToDisplayString(d.treatedTime),
		submittedTimeDisplay: dateToDisplayString(d.submittedTime),
		completedTimeDisplay: dateToDisplayString(d.completedTime),
		treatmentDurationDisplay: (d.treatmentDuration / 1000 / 60 / 60 / 24).toFixed(2),
		treatmentDurationDisplayHours: (d.treatmentDuration / 1000 / 60 / 60).toFixed(2),
		completionDurationDisplay: (d.completionDuration / 1000 / 60 / 60 / 24).toFixed(2),
		completionDurationDisplayHours: (d.completionDuration / 1000 / 60 / 60).toFixed(2),
		incoherentTreatmentData: d.treatmentDuration < 0,
		incoherentCompletionData: d.completionDuration < 0,
		unpreciseTreatmentData:
			(new Date(d.submittedTime).getHours() === 0 &&
				new Date(d.submittedTime).getMinutes() === 0 &&
				new Date(d.submittedTime).getSeconds() === 0) ||
			(new Date(d.treatedTime).getHours() === 0 &&
				new Date(d.treatedTime).getMinutes() === 0 &&
				new Date(d.treatedTime).getSeconds() === 0),
		unpreciseCompletionData:
			(new Date(d.treatedTime).getHours() === 0 &&
				new Date(d.treatedTime).getMinutes() === 0 &&
				new Date(d.treatedTime).getSeconds() === 0) ||
			(new Date(d.completedTime).getHours() === 0 &&
				new Date(d.completedTime).getMinutes() === 0 &&
				new Date(d.completedTime).getSeconds() === 0),
	}));

export const formatBlockingInstanceKpiData = (dataToFormat: OpsBiKpi[]): OpsKpiBlockingInstanceDisplay[] =>
	dataToFormat.map((d) => ({
		...d,
		durationADisplay: (d.durationA / 1000 / 60 / 60 / 24).toFixed(2),
		durationADisplayHours: (d.durationA / 1000 / 60 / 60).toFixed(2),
		durationBDisplay: (d.durationB / 1000 / 60 / 60 / 24).toFixed(2),
		durationBDisplayHours: (d.durationB / 1000 / 60 / 60).toFixed(2),
		durationCDisplay: (d.durationC / 1000 / 60 / 60 / 24).toFixed(2),
		durationCDisplayHours: (d.durationC / 1000 / 60 / 60).toFixed(2),
		durationDDisplay: (d.durationD / 1000 / 60 / 60 / 24).toFixed(2),
		durationDDisplayHours: (d.durationD / 1000 / 60 / 60).toFixed(2),
		incoherentData: d.durationA < 0 || d.durationB < 0 || d.durationC < 0 || d.durationD < 0,
		openAtDisplay: dateToDisplayString(d.openAt),
		sentToClientAtDisplay: dateToDisplayString(d.sentToClientAt),
		documentsSubmittedAtDisplay: dateToDisplayString(d.documentsSubmittedAt),
		sentToPartnerAtDisplay: dateToDisplayString(d.sentToPartnerAt),
		closedAtDisplay: dateToDisplayString(d.closedAt),
	}));
