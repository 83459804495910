import { dashboardApi } from 'store/api';
import { User } from 'types/user.type';

export type OpsAlert = {
	id: string;
	title: string;
	description: string;
	createdAt: string;
	impactedUser?: User & {
		kyc: {
			firstName: string;
			lastName: string;
		};
	};
	priority: AlertPriority;
	resolvedAt?: string;
	product: string;
	groupId: string;
	service: string;
	metadataTech: string;
	metadataOps: string;
	fromCron: boolean;
};

export enum AlertPriority {
	URGENT = 'URGENT',
	HIGH = 'HIGH',
	MEDIUM = 'MEDIUM',
	LOW = 'LOW',
	INFORMATIVE = 'INFORMATIVE',
}

export type AlertsSystemFiltersDto = {
	priority?: AlertPriority[];
	groupId?: string[];
	impactedUserEmail?: string;
	product?: string[];
	service?: string[];
	fromCron?: boolean;
	showResolved?: boolean;
};

export type AlertsSystemPaginationDto = {
	page: number;
	limit: number;
};

type GetAlertsResponse = {
	totalCron: number;
	totalUser: number;
	results: OpsAlert[];
};

type AlertsFiltersResponse = {
	priorities: AlertPriority[];
	groups: string[];
	products: string[];
	services: string[];
};

interface GetAlertDto {
	filters?: AlertsSystemFiltersDto;
	pagination?: AlertsSystemPaginationDto;
}

const endpoints = dashboardApi.injectEndpoints({
	endpoints: (builder) => ({
		searchAlerts: builder.query<GetAlertsResponse, GetAlertDto>({
			query: (body) => ({
				url: '/bo/ops/alerts-system/search',
				method: 'POST',
				body,
			}),
			providesTags: ['OPS_ALERTS_SYSTEM'],
		}),
		getFilterOptions: builder.query<AlertsFiltersResponse, void>({
			query: () => ({
				url: '/bo/ops/alerts-system/filters',
				method: 'GET',
			}),
			providesTags: ['OPS_ALERTS_SYSTEM'],
		}),
		getAlert: builder.query<OpsAlert, string>({
			query: (id: string) => ({
				url: `/bo/ops/alerts-system/${id}`,
				method: 'GET',
			}),
			providesTags: ['OPS_ALERTS_SYSTEM'],
		}),
		resolveAlert: builder.mutation<void, string>({
			query: (id) => ({
				url: `/bo/ops/alerts-system/resolve/${id}`,
				method: 'POST',
			}),
			invalidatesTags: ['OPS_ALERTS_SYSTEM'],
		}),
		unresolveAlert: builder.mutation<void, string>({
			query: (id) => ({
				url: `/bo/ops/alerts-system/unresolve/${id}`,
				method: 'POST',
			}),
			invalidatesTags: ['OPS_ALERTS_SYSTEM'],
		}),
		resolveManyAlerts: builder.mutation<void, string[]>({
			query: (ids) => ({
				url: '/bo/ops/alerts-system/resolve/many',
				method: 'PATCH',
				body: { ids },
			}),
			invalidatesTags: ['OPS_ALERTS_SYSTEM'],
		}),
		deleteManyAlerts: builder.mutation<void, string[]>({
			query: (ids) => ({
				url: '/bo/ops/alerts-system/many',
				method: 'DELETE',
				body: { ids },
			}),
			invalidatesTags: ['OPS_ALERTS_SYSTEM'],
		}),
	}),
});

export const {
	useSearchAlertsQuery,
	useGetFilterOptionsQuery,
	useGetAlertQuery,
	useResolveAlertMutation,
	useUnresolveAlertMutation,
	useResolveManyAlertsMutation,
	useDeleteManyAlertsMutation,
} = endpoints;
