import { FC } from 'react';
import { Checkbox, CheckboxGroup, SimpleGrid, VStack } from '@chakra-ui/react';

import { FilterPopoverProps } from '.';

const PartnerFilter: FC<FilterPopoverProps> = ({ value, onChange, options }) => (
	<CheckboxGroup value={value} onChange={(v) => onChange(v as string[])}>
		<VStack align="start" p="16px">
			<SimpleGrid columns={4} w="100%">
				{Object.values(options).map((partner) => (
					<Checkbox key={partner} value={partner}>
						{partner}
					</Checkbox>
				))}
			</SimpleGrid>
		</VStack>
	</CheckboxGroup>
);
export default PartnerFilter;
