export enum RegionType {
	EUROPE = 'EUROPE',
	USA = 'USA',
	EMERGING = 'EMERGING',
	DEVELOPED = 'DEVELOPED',
	FRANCE = 'FRANCE',
	JAPAN = 'JAPAN',
	CHINA = 'CHINA',
}

export enum AssetType {
	EQUITY = 'EQUITY',
	BOND = 'BOND',
}

export enum PortfolioType {
	FLAGSHIP = 'FLAGSHIP',
	ESSENTIAL = 'ESSENTIAL',
	ELITE = 'ELITE',
	ELITE_BIS = 'ELITE_BIS',
	CUSTOM = 'CUSTOM',
}

export enum FinancialLostType {
	NO = 'NO',
	MAX_10 = 'MAX_10',
	MAX_20 = 'MAX_20',
	MORE_20 = 'MORE_20',
}

export enum LostReactionType {
	WAIT = 'WAIT',
	REINVEST = 'REINVEST',
	SELL = 'SELL',
	UNKNOWN = 'UNKNOWN',
}

export type InvestmentPreferences = {
	id: string;
	contractId: string | null;
	subscriptionId: string | null;
	simulationId: string | null;
	regions: RegionType[];
	types: AssetType[];
	esg: boolean | null;
	temperature: number | null;
	portfolioType: PortfolioType | null;
	risk: number | null;
	recommandedRisk: number | null;
	hasEverUsedSavingPlan: boolean;
	financialLost: FinancialLostType;
	lostReaction: LostReactionType;
};
