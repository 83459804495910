import { ReactNode } from 'react';
import { DeleteIcon } from '@chakra-ui/icons';
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Heading } from '@chakra-ui/react';

const ProductAccordion = ({ header, body, onDelete }: { header: ReactNode; body: ReactNode; onDelete: () => void }) => (
	<Accordion borderRadius="10px" borderWidth="1px" borderColor="1" w="100%" defaultIndex={[0]} allowMultiple>
		<AccordionItem border="none">
			<AccordionButton>
				<Heading my="8px" size="md" flex="1" textAlign="left">
					{header}
				</Heading>
				<DeleteIcon mr="32px" onClick={onDelete} />
				<AccordionIcon />
			</AccordionButton>
			<AccordionPanel>{body}</AccordionPanel>
		</AccordionItem>
	</Accordion>
);

export default ProductAccordion;
