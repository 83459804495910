import React, { FC } from 'react';
import { Wrap } from '@chakra-ui/react';

import CustomModal from 'components/Modal';
import DisplayPost from 'features/mailing-framework/posts/DisplayPost';
import { MailingFrameworkPost } from 'types/mailing-framework.type';

type DisplayPostsModalProps = {
	isOpen: boolean;
	onClose: () => void;
	mailingFrameworkPosts: MailingFrameworkPost[];
};

const DisplayPostsModal: FC<DisplayPostsModalProps> = ({ isOpen, onClose, mailingFrameworkPosts }) => (
	<CustomModal
		isOpen={isOpen}
		onClose={onClose}
		scrollBehavior="inside"
		headersProps={{ children: 'Contenu de la conversation' }}
	>
		<Wrap w="100%" gap="24px">
			{mailingFrameworkPosts.map((post) => (
				<DisplayPost key={post.id} postData={post} />
			))}
		</Wrap>
	</CustomModal>
);

export default DisplayPostsModal;
