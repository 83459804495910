import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Table, TableContainer, Tbody, Text, Thead, Tr } from '@chakra-ui/react';

import { ConditionalLink } from 'components/tables/columns/ConditionalLink';
import { BoolTd, DateTd, DefaultTd } from 'components/tables/columns/Td';
import { DefaultTh, SortTh } from 'components/tables/columns/Th';
import { isDealOverdue } from 'features/ChangeOverdueDate';
import useSessionStorageState from 'hooks/useSessionStorate';
import { AllDeal, OpsTransferDeal } from 'services/deal';
import { StatusLabel } from 'types/airtable/status-label.airtable.type';
import { BOContext, SubscriptionStatus } from 'types/global.type';
import { displayMoney, isNone } from 'utils/functions';

const statusOrder = {
	[SubscriptionStatus.REQUESTED]: 0,
	[SubscriptionStatus.SIGNED]: 1,
	[SubscriptionStatus.PARTNER_VALIDATION]: 2,
	[SubscriptionStatus.PARTNER_TREATMENT]: 3,
	[SubscriptionStatus.PAYMENT]: 4,
	[SubscriptionStatus.COMPLETED]: 5,
};

const sortFields = (a: OpsTransferDeal, b: OpsTransferDeal, orderBy: string, order: 'asc' | 'desc') => {
	if (orderBy === 'createdAt') {
		if (order === 'asc') return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
		else return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
	}
	if (orderBy === 'updatedAt') {
		if (order === 'asc') return new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime();
		else return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
	}
	if (orderBy === 'amount') {
		if (order === 'asc') return (a.transferAmount ?? 0) - (b.transferAmount ?? 0);
		else return (b.transferAmount ?? 0) - (a.transferAmount ?? 0);
	}
	if (orderBy === 'status') {
		if (order === 'asc') return statusOrder[a.status] - statusOrder[b.status];
		else return statusOrder[b.status] - statusOrder[a.status];
	}
	return 0;
};

const rowColor = (
	transfer: OpsTransferDeal,
	selectedTransfer?: OpsTransferDeal,
	statuses?: Record<string, StatusLabel>,
): string => {
	if (selectedTransfer?.id === transfer.id) return 'blue.50';
	if (isNone(statuses)) return 'white';
	if (isDealOverdue(transfer as AllDeal, statuses)) return 'red.50';
	return 'white';
};

type TransfersTableProps = {
	context: Extract<BOContext, 'client' | 'transfer'>;
	transfers: OpsTransferDeal[];
	selectedTransfer?: OpsTransferDeal;
	onClick?: (c: OpsTransferDeal) => void;
	pageSize: number;
	productTypeStatuses?: Record<string, StatusLabel>;
};

const initialState = {
	pageIndex: 0,
	sortBy: 'updatedAt',
	sortDirection: 'desc' as const,
};

const TransfersTable = ({
	context,
	selectedTransfer,
	productTypeStatuses,
	transfers,
	onClick,
	pageSize,
}: TransfersTableProps) => {
	const [pageIndex, setPageIndex] = useSessionStorageState<number>('TRANSFERS_PAGE_INDEX', initialState.pageIndex);
	const [sortBy, setSortBy] = useSessionStorageState('TRANSFERS_SORT_BY', initialState.sortBy);
	const [sortDirection, setSortDirection] = useSessionStorageState<'asc' | 'desc'>(
		'TRANSFERS_SORT_DIRECTION',
		initialState.sortDirection,
	);

	const handleOrderBy = (orderByParam: string) => {
		if (orderByParam === sortBy) {
			setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		} else {
			setSortBy(orderByParam);
			setSortDirection('asc');
		}
	};

	return (
		<Box>
			<TableContainer bg="white">
				<Table variant="simple" size="sm">
					<Thead>
						<Tr>
							<DefaultTh>Email</DefaultTh>
							<DefaultTh>Nom</DefaultTh>
							<DefaultTh>Prénom</DefaultTh>
							<DefaultTh>Black</DefaultTh>
							<SortTh onClick={handleOrderBy} value="amount" selectedSortBy={sortBy} sortDirection={sortDirection}>
								Montant
							</SortTh>
							<DefaultTh>Provider</DefaultTh>
							<DefaultTh>Nature</DefaultTh>
							<SortTh onClick={handleOrderBy} value="status" selectedSortBy={sortBy} sortDirection={sortDirection}>
								Statut
							</SortTh>
							<SortTh onClick={handleOrderBy} value="createdAt" selectedSortBy={sortBy} sortDirection={sortDirection}>
								Créé le
							</SortTh>
							<SortTh onClick={handleOrderBy} value="updatedAt" selectedSortBy={sortBy} sortDirection={sortDirection}>
								MAJ le
							</SortTh>
						</Tr>
					</Thead>

					<Tbody>
						{transfers
							.sort((a, b) => sortFields(a, b, sortBy, sortDirection))
							.slice(pageIndex, pageIndex + pageSize)
							.map((transfer) => (
								<ConditionalLink
									key={transfer.id}
									style={{ display: 'contents' }}
									to={`/ops/super/transfer/${transfer.id}`}
									shouldrenderLink={!onClick}
								>
									<Tr
										cursor="pointer"
										bg={rowColor(transfer, selectedTransfer, productTypeStatuses)}
										onClick={() => {
											if (onClick) onClick(transfer);
										}}
									>
										<DefaultTd>{transfer.user?.email}</DefaultTd>
										<DefaultTd>{transfer.user?.kyc?.lastName}</DefaultTd>
										<DefaultTd>{transfer.user?.kyc?.firstName}</DefaultTd>
										<BoolTd value={transfer.user?.isBlack} />
										<DefaultTd>{displayMoney(transfer.transferAmount)}</DefaultTd>
										<DefaultTd>{transfer.providerName}</DefaultTd>
										<DefaultTd>{transfer.fiscalNature}</DefaultTd>
										<DefaultTd>{transfer.status}</DefaultTd>
										<DateTd value={new Date(transfer.createdAt)} />
										<DateTd value={new Date(transfer.updatedAt)} />
									</Tr>
								</ConditionalLink>
							))}
					</Tbody>
				</Table>
			</TableContainer>

			{context !== 'client' && (
				<Box w="100%" textAlign="right" my="16px" fontWeight="semibold" userSelect="none">
					<Text as="span" color="gray.600">
						Show {pageIndex + 1} to {pageIndex + pageSize} of {transfers.length} entries{' '}
					</Text>
					<ChevronLeftIcon
						mx="16px"
						boxSize="24px"
						bg="gray.100"
						cursor="pointer"
						onClick={() =>
							setPageIndex(pageIndex === 0 ? Math.floor(transfers.length / pageSize) * 10 : pageIndex - pageSize)
						}
					/>
					<ChevronRightIcon
						boxSize="24px"
						bg="gray.100"
						cursor="pointer"
						onClick={() => setPageIndex(pageIndex + 1 * pageSize >= transfers.length ? 0 : pageIndex + pageSize)}
					/>
				</Box>
			)}
		</Box>
	);
};

export default TransfersTable;
