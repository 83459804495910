type Asset = {
	ISIN: string;
	repartition: number;
};

export const transformPortfolio = {
	lean: (assets: Asset[]): Asset[] => {
		const roundedPortfolio = assets.map<Asset>((p) => ({
			ISIN: p.ISIN,
			repartition: parseFloat(p.repartition.toFixed(4)),
		}));

		const total = roundedPortfolio.reduce((acc, value) => acc + value.repartition, 0);
		const diff = 1 - total;
		roundedPortfolio[0].repartition = parseFloat((roundedPortfolio[0].repartition + diff).toFixed(4));
		return roundedPortfolio;
	},
	toArray: (weights: Record<string, number>): Asset[] =>
		Object.entries(weights).map<Asset>(([ISIN, repartition]) => ({
			ISIN,
			repartition,
		})),
};
