import { dashboardApi } from 'store/api';
import { ProductType } from 'types/global.type';
import { DocumentName } from 'utils/documentNamingMap';

export type RamifyDoc = {
	key: string;
	documentName: DocumentName;
	metadata: Record<string, string>;
	folder: string;
	url: string;
	filename: string;
	lastUpdated?: string;
	contentType?: string;
};

const endpoints = dashboardApi.injectEndpoints({
	endpoints: (builder) => ({
		getDocuments: builder.query<
			RamifyDoc[],
			{ productType: ProductType | undefined; email: string; subscriptionId: string | undefined }
		>({
			query: (queryParams) => ({
				url: '/bo/documents',
				params: queryParams,
				method: 'GET',
			}),
			providesTags: ['OPS_DOCUMENTS'],
		}),
		uploadDocument: builder.mutation<void, FormData>({
			query: (formData) => ({
				url: '/bo/documents',
				method: 'POST',
				body: formData,
			}),
			invalidatesTags: ['OPS_DOCUMENTS'],
		}),
	}),
});

export const { useGetDocumentsQuery, useLazyGetDocumentsQuery, useUploadDocumentMutation } = endpoints;
