import { Box } from '@chakra-ui/react';

import Autologin from 'features/client-tools/Autologin';

const AutologinQIS = () => (
	<Box p="32px" mt="32px" bg="white" borderRadius="10px" borderWidth="1px" borderColor="gray.200">
		<Autologin />
	</Box>
);

export default AutologinQIS;
