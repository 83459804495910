import CheckList from './CheckList';
import DropDown from './DropDown';

const DropDownOptionPicker = ({
	label,
	values,
	options,
	onChange,
}: {
	label: string;
	values: string[];
	options: string[];
	onChange: (values: string[]) => void;
}) => (
	<DropDown
		borderRadius="4px"
		label={`${label} ${values.length > 0 ? ` (${values.length})` : ''}`}
		isOn={values.length > 0}
	>
		<CheckList
			options={options}
			values={values}
			onChange={(newValue, isChecked) => {
				const newValues = isChecked ? [...values, newValue] : values.filter((value) => value !== newValue);
				onChange(newValues);
			}}
		/>
	</DropDown>
);

export default DropDownOptionPicker;
