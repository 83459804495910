import { dashboardApi } from 'store/api';
import { Adequation } from 'types/adequation.type';
import { KYC } from 'types/kyc.type';
import { MoralPerson, MoralPersonBeneficiary } from 'types/moralPerson.type';
import { InvestorCategory, User } from 'types/user.type';

export type UserWithKycAndMoral = User & { kyc: KYC; moralPersons: MoralPerson[] };

export type MoralPersonWithBeneficiaries = MoralPerson & { moralPersonBeneficiaries: MoralPersonBeneficiary[] };
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type KycAndAdequation = { kyc: KYC; adequation: Adequation; historiqueFiscale: any[] };

const endpoints = dashboardApi.injectEndpoints({
	endpoints: (builder) => ({
		getClients: builder.query<UserWithKycAndMoral[], { searchBy: string; input: string | undefined }>({
			query: (queryParams) => ({
				url: '/bo/clients',
				params: queryParams,
				method: 'GET',
			}),
			providesTags: ['OPS_CLIENTS'],
		}),
		getClientById: builder.query<UserWithKycAndMoral, string>({
			query: (id) => ({
				url: `/bo/clients/id/${id}`,
				method: 'GET',
			}),
			providesTags: ['OPS_CLIENTS'],
		}),
		getClientByEmail: builder.query<UserWithKycAndMoral, string>({
			query: (id) => ({
				url: `/bo/clients/email/${id}`,
				method: 'GET',
			}),
			providesTags: ['OPS_CLIENTS'],
		}),
		getClientBySponsorshipCode: builder.query<UserWithKycAndMoral, string>({
			query: (code) => ({
				url: `/bo/clients/sponsorship-code/${code}`,
				method: 'GET',
			}),
			providesTags: ['OPS_CLIENTS'],
		}),
		getClientKYC: builder.query<KycAndAdequation, { email: string }>({
			query: ({ email }) => ({
				url: '/bo/clients/kyc',
				params: { email },
				method: 'GET',
			}),
		}),
		getClientMoralPersons: builder.query<MoralPersonWithBeneficiaries[], { email: string }>({
			query: ({ email }) => ({
				url: '/bo/clients/moral-persons',
				params: { email },
				method: 'GET',
			}),
		}),
		getAutologinToken: builder.query<{ token: string }, { email: string }>({
			query: ({ email }) => ({
				url: `/bo/clients/autologin-token`,
				params: { email },
			}),
		}),
		deleteClient: builder.mutation<void, { email: string }>({
			query: ({ email }) => ({
				url: '/bo/clients',
				params: { email },
				method: 'DELETE',
			}),
			invalidatesTags: ['OPS_CLIENTS', 'OPS_SUBSCRIPTIONS', 'OPS_DEALS'],
		}),
		updateEmail: builder.mutation<void, { oldEmail: string; newEmail: string }>({
			query: (body) => ({
				url: `/bo/clients/update-email`,
				method: 'POST',
				body: body,
			}),
			invalidatesTags: ['OPS_CLIENTS', 'OPS_SUBSCRIPTIONS'],
		}),
		getSponsor: builder.query<User, string>({
			query: (emailFilleul) => ({
				url: `/bo/clients/sponsor`,
				params: { emailFilleul },
				method: 'GET',
			}),
			providesTags: ['OPS_CLIENTS'],
		}),
		updateAffiliation: builder.mutation<void, { emailFilleul: string; emailParrain: string }>({
			query: (body) => ({
				url: `/bo/clients/update-affiliation`,
				body,
				method: 'PUT',
			}),
			invalidatesTags: ['OPS_CLIENTS'],
		}),
		updateBlackStatus: builder.mutation<void, { email: string; isBlack: boolean }>({
			query: (body) => ({
				url: `/bo/clients/update-black-status`,
				body,
				method: 'PUT',
			}),
			invalidatesTags: ['OPS_CLIENTS'],
		}),
		updateInvestorCategory: builder.mutation<void, { email: string; investorCategory: InvestorCategory }>({
			query: (body) => ({
				url: `/bo/clients/update-investor-category`,
				body,
				method: 'PUT',
			}),
			invalidatesTags: ['OPS_CLIENTS'],
		}),
	}),
});

export const {
	useGetClientsQuery,
	useGetClientByIdQuery,
	useGetClientByEmailQuery,
	useLazyGetClientBySponsorshipCodeQuery,
	useLazyGetClientsQuery,
	useLazyGetClientByIdQuery,
	useLazyGetClientByEmailQuery,
	useGetClientKYCQuery,
	useGetClientMoralPersonsQuery,
	useLazyGetAutologinTokenQuery,
	useDeleteClientMutation,
	useUpdateEmailMutation,
	useGetSponsorQuery,
	useUpdateAffiliationMutation,
	useUpdateBlackStatusMutation,
	useUpdateInvestorCategoryMutation,
} = endpoints;
