import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { Box, Heading, HStack, Text, VStack } from '@chakra-ui/react';
import { ResponsiveLine } from '@nivo/line';

import CustomTooltip from 'components/CustomTooltip';

type LineGraphProps = {
	data: Record<string, number[]>;
	isOptimal: boolean;
	temperature: number | null;
	reachableTemperature: number | null;
	isEsg: boolean | null;
};

const InfoTooltip = ({ data }: { data: LineGraphProps['data'] }) => (
	<CustomTooltip
		placement="left-end"
		text={
			<Box>
				<HStack w="100%" justify="space-around">
					<Text>
						Actual Return: <b>{data.actual[0].toPrecision(4)}</b>
					</Text>
					<Text>
						Ideal Return: <b>{data.ideal[0].toPrecision(4)}</b>
					</Text>
					<Text>
						New Return: <b>{data.new[0].toPrecision(4)}</b>
					</Text>
				</HStack>
				<HStack w="100%" justify="space-around">
					<Text>
						Actual Volatility: <b>{data.actual[1].toPrecision(4)}</b>
					</Text>
					<Text>
						Ideal Volatility: <b>{data.ideal[1].toPrecision(4)}</b>
					</Text>
					<Text>
						New Volatility: <b>{data.new[1].toPrecision(4)}</b>
					</Text>
				</HStack>
			</Box>
		}
	/>
);

const LineGraph = ({ data, isOptimal, isEsg, temperature, reachableTemperature }: LineGraphProps) => (
	<Box borderWidth="2px" borderRadius="4px" p="16px">
		<VStack w="100%" align="start" justify="space-around" ml="24px" mt="8px">
			<HStack w="100%">
				<Heading flex={0.95} as="h4" size="md">
					Distance View
				</Heading>
				<InfoTooltip data={data} />
			</HStack>

			<Text>
				Optimized Distance :
				{isOptimal ? <CheckIcon ml="8px" color="green.500" /> : <CloseIcon ml="8px" color="red.500" />}
			</Text>
			<HStack>
				<Text>
					Reachable Temperature: <b>{reachableTemperature?.toPrecision(3)} °C</b>
				</Text>
				{isEsg && <Text>(vs {temperature ?? '2.5'}°C cible)</Text>} // TODO only if esg
			</HStack>
		</VStack>
		<Box h="300px">
			<ResponsiveLine
				data={Object.keys(data).map((key) => ({
					id: key,
					data: [{ x: data[key][0].toFixed(5), y: data[key][1].toFixed(5) }],
				}))}
				margin={{ top: 20, right: 100, bottom: 50, left: 90 }}
				xScale={{ type: 'linear', min: 0.025, max: 0.17 }}
				yScale={{ type: 'linear', min: 0.01, max: 0.13 }}
				yFormat=" >-.2f"
				axisTop={null}
				axisRight={null}
				axisBottom={{
					tickSize: 5,
					tickPadding: 5,
					tickRotation: 0,
					legend: 'Expected Volatility',
					legendOffset: 36,
					legendPosition: 'middle',
				}}
				axisLeft={{
					tickSize: 5,
					tickPadding: 5,
					tickRotation: 0,
					legend: 'Expected Return',
					legendOffset: -60,
					legendPosition: 'middle',
				}}
				colors={{ scheme: 'category10' }}
				lineWidth={0}
				pointSize={10}
				pointBorderWidth={2}
				pointBorderColor={{ from: 'serieColor' }}
				pointLabelYOffset={-12}
				useMesh
				legends={[
					{
						anchor: 'bottom-right',
						direction: 'column',
						justify: false,
						translateX: 100,
						translateY: 0,
						itemsSpacing: 0,
						itemDirection: 'left-to-right',
						itemWidth: 80,
						itemHeight: 20,
						itemOpacity: 0.75,
						symbolSize: 12,
						symbolShape: 'circle',
						symbolBorderColor: 'rgba(0, 0, 0, .5)',
						effects: [
							{
								on: 'hover',
								style: {
									itemBackground: 'rgba(0, 0, 0, .03)',
									itemOpacity: 1,
								},
							},
						],
					},
				]}
			/>
		</Box>
	</Box>
);

export default LineGraph;
