import { useCallback } from 'react';
import { ArrowLeftIcon, ArrowRightIcon, ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Box, FormLabel, HStack, Text, VStack } from '@chakra-ui/react';

import NumberInputWithStepper from 'components/NumberInput';
import { useAppDispatch, useAppSelector } from 'store';

import { setAlertsPagination } from '../alerts.slice';

const AlertsPagination = ({ totalAlerts }: { totalAlerts: number }) => {
	const pagination = useAppSelector((state) => state.alertsTool.pagination);
	const { page, limit } = pagination;
	const dispatch = useAppDispatch();

	const setPageSize = useCallback(
		(value: number) => {
			dispatch(setAlertsPagination({ ...pagination, limit: value }));
		},
		[dispatch, pagination],
	);

	const setPageIndex = useCallback(
		(value: number) => {
			dispatch(setAlertsPagination({ ...pagination, page: value }));
		},
		[dispatch, pagination],
	);

	return (
		<VStack w="100%">
			<Box w="100%" textAlign="right" fontWeight="semibold" userSelect="none">
				<HStack justify="end" w="100%">
					<Text as="span" color="gray.600">
						Alertes {(page - 1) * limit + 1} à {Math.min(page * limit, totalAlerts)} (sur {totalAlerts} au total)
					</Text>

					<HStack spacing="8px">
						<ArrowLeftIcon
							padding="6px"
							boxSize="24px"
							bg="gray.100"
							cursor="pointer"
							onClick={() => setPageIndex(1)}
						/>
						<ChevronLeftIcon
							boxSize="24px"
							bg="gray.100"
							cursor="pointer"
							onClick={() => setPageIndex(page === 1 ? page : page - 1)}
						/>
						<ChevronRightIcon
							boxSize="24px"
							bg="gray.100"
							cursor="pointer"
							onClick={() => setPageIndex(page * limit >= totalAlerts ? page : page + 1)}
						/>
						<ArrowRightIcon
							padding="6px"
							boxSize="24px"
							bg="gray.100"
							cursor="pointer"
							onClick={() => setPageIndex(+(totalAlerts / limit).toFixed(0))}
						/>
					</HStack>

					<HStack>
						<FormLabel m="auto">Page size</FormLabel>
						<NumberInputWithStepper
							bg="white"
							step={5}
							max={1000}
							min={5}
							maxW="100px"
							value={limit}
							onChange={(s, v) => {
								if (v < 1) return;
								setPageSize(v);
							}}
						/>
					</HStack>
				</HStack>
			</Box>
		</VStack>
	);
};

export default AlertsPagination;
