import { MailingFrameworkPostCreation } from 'services/mailing-framework/mailing-framework.post';
import { dashboardApi } from 'store/api';
import { MailingFrameworkConversation } from 'types/mailing-framework.type';

export type MailingFrameworkConversationCreation = {
	sender: string;
	receiver: string[];
	cc?: string[];
	bcc?: string[];
	subject: string;
	firstPost?: MailingFrameworkPostCreation;
	workflowParams?: WorkflowParams[];
	opsPropertiesId?: string;
	cpmPropaleId?: string;
};

// Common params for each workflow
export type WorkflowParams = {
	message: string;
	dayDelayFromFirstMail: number;
	templateId?: number;
	templateParams?: unknown;
};

export type ApplyWorkflow = {
	conversationId: string;
	workflowParams: WorkflowParams[];
};

export const endpoints = dashboardApi.injectEndpoints({
	endpoints: (builder) => ({
		//TESTING FUNCTION
		getConversations: builder.query<MailingFrameworkConversation[], void>({
			query: () => ({
				url: `bo/mailing-framework/conversation`,
				method: 'GET',
			}),
			providesTags: ['MAILING_FRAMEWORK'],
		}),
		getConversationById: builder.query<MailingFrameworkConversation, { conversationId: string }>({
			query: (content) => ({
				url: `bo/mailing-framework/conversation/${content.conversationId}`,
				method: 'GET',
			}),
			providesTags: ['MAILING_FRAMEWORK'],
		}),

		createConversation: builder.mutation<MailingFrameworkConversation, MailingFrameworkConversationCreation>({
			query: (content) => ({
				url: `bo/mailing-framework/conversation`,
				method: 'POST',
				body: content,
			}),
			invalidatesTags: ['MAILING_FRAMEWORK'],
		}),
		applyConversationWorkflow: builder.mutation<MailingFrameworkConversation, ApplyWorkflow>({
			query: ({ conversationId, ...body }) => ({
				url: `bo/mailing-framework/conversation/${conversationId}/workflow`,
				method: 'POST',
				body: body,
			}),
			invalidatesTags: ['MAILING_FRAMEWORK'],
		}),
	}),
});

export const {
	useCreateConversationMutation,
	useGetConversationByIdQuery,
	useGetConversationsQuery,
	useApplyConversationWorkflowMutation,
} = endpoints;
