export enum ProfessionalSituation {
	EMPLOYEE = 'EMPLOYEE',
	INDEPENDANT = 'INDEPENDANT',
	RETIRED = 'RETIRED',
	STUDENT = 'STUDENT',
	CUMUL_EMPLOYEE_RETIRED = 'CUMUL_EMPLOYEE_RETIRED',
	SEARCHING_EMPLOYMENT = 'SEARCHING_EMPLOYMENT',
	NO_ACTIVITY = 'NO_ACTIVITY',
}

const ProfessionalSituationReferentiel: Record<ProfessionalSituation, string> = {
	EMPLOYEE: 'Salarié',
	INDEPENDANT: 'Travailleur non salarié',
	RETIRED: 'Retraité(e)',
	STUDENT: 'Etudiant(e)',
	CUMUL_EMPLOYEE_RETIRED: 'Cumul emploi / retraite',
	SEARCHING_EMPLOYMENT: "Recherche d'emploi",
	NO_ACTIVITY: 'Sans activité',
};

export default ProfessionalSituationReferentiel;
