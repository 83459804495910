import { Box, FormLabel, VStack } from '@chakra-ui/react';

import NumberInputWithStepper from 'components/NumberInput';
import { ArtData } from 'pages/cpm/product-propale/propaleData.type';

type ArtCardProps = {
	data: ArtData;
	editData: React.Dispatch<React.SetStateAction<ArtData[]>>;
};

export const ArtCard = ({ data, editData }: ArtCardProps) => (
	<VStack align="start" spacing="16px" w="100%">
		<Box w="100%">
			<FormLabel fontSize="sm">Montant</FormLabel>
			<NumberInputWithStepper
				step={1000}
				min={20000}
				value={data.initialDepositAmount + '€'}
				onChange={(amount) => {
					editData((old) => {
						const newFunds = [...old];
						newFunds.splice(newFunds.indexOf(data), 1);
						return [...newFunds, { ...data, initialDepositAmount: +amount }].sort();
					});
				}}
			/>
		</Box>
	</VStack>
);
