import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Event } from 'react-big-calendar';
import { useNavigate } from 'react-router-dom';
import {
	Button,
	Checkbox,
	HStack,
	Input,
	InputProps,
	Select,
	SelectProps,
	Skeleton,
	Text,
	Textarea,
	VStack,
	Wrap,
} from '@chakra-ui/react';
import { useAuth } from '@frontegg/react';

import DocumentButton from 'components/documentButton';
import NumberInputWithStepper from 'components/NumberInput';
import useThemedToast from 'hooks/useThemedToast';
import { formatMailData } from 'pages/cpm/super/propale/PropaleDetails';
import {
	subjectAuto,
	subjectFollowUpPostCall,
	subjectManualPostCall,
	subjectNoShowPostCall,
	templateAuto,
	templateFollowUpPostCall,
	templateManualPostCall,
	templateNoShowPostCall,
} from 'pages/cpm/super/propale/templateMail';
import { useLazyGetClientByEmailQuery } from 'services/client';
import {
	GoogleCalendarEvent,
	ScheduledEvent,
	ScheduledEventInvitiee,
	useLazyGetCalendlyEventQuery,
} from 'services/cpm/calendar';
import { useCreatePropaleMutation, useLazyGetNotionLinkQuery } from 'services/cpm/propale';
import { CPM, cpmDisplayName, cpmToCalendly, cpmToEmail, emailToCpm } from 'types/cpm-list.type';
import { LeadClosingProbability, PropaleType } from 'types/cpm-propale';
import { BOContext } from 'types/global.type';
import { PortfolioType } from 'types/investmentPreferences.type';
import { isNotNone } from 'utils/functions';

const InputWithLabel = ({ label, flex, ...props }: { label: string } & InputProps) => (
	<VStack w="100%" spacing="4px" align="start" flex={flex}>
		<Text fontWeight="semibold">{label}</Text>
		<Input {...props} />
	</VStack>
);

const SelectWithLabel = ({ label, flex, children, ...props }: { label: string } & SelectProps) => (
	<VStack w="100%" spacing="4px" align="start" flex={flex}>
		<Text fontWeight="semibold">{label}</Text>
		<Select {...props}>{children}</Select>
	</VStack>
);

const getDateInFiveWorkingDays = () => {
	const date = new Date();
	const daysToAdd = 5;
	let workingDaysAdded = 0;

	while (workingDaysAdded < daysToAdd) {
		date.setDate(date.getDate() + 1);
		if (date.getDay() !== 0 && date.getDay() !== 6) workingDaysAdded++;
	}

	return date.toLocaleDateString('en-CA', {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
	});
};

const getEventId = (description: string) => {
	const regex = /https:\/\/calendly\.com\/events\/([0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12})\/google_meet/;
	const match = description.match(regex);
	return match ? match[1] : undefined;
};

type CalendarActionsProps = {
	event?: Omit<Event, 'resource'> & {
		resource?: Pick<GoogleCalendarEvent, 'id' | 'description' | 'attendees' | 'hangoutLink'>;
	};
	context: BOContext;
	onClose: () => void;
};

const CreatePropale = ({ event, context, onClose }: CalendarActionsProps) => {
	const toast = useThemedToast();
	const navigate = useNavigate();

	const { user } = useAuth();
	const [createPropale, { isLoading: isCreatePropaleLoading }] = useCreatePropaleMutation();
	const [getNotionLink, { isLoading: isNotionLoading }] = useLazyGetNotionLinkQuery();
	const [getCalendlyEvent, { isLoading: isCalendlyLoading }] = useLazyGetCalendlyEventQuery();
	const [getClient] = useLazyGetClientByEmailQuery();

	const [eventData, setEventData] = useState<ScheduledEvent & { invitees: ScheduledEventInvitiee }>();

	const [notes, setNotes] = useState('');
	const [assignedCpm, setAssignedCpm] = useState<string>();
	const [propaleType, setPropaleType] = useState<PropaleType>();
	const [leadClosingProbability, setLeadClosingProbability] = useState<LeadClosingProbability>();
	const [notionLink, setNotionLink] = useState('');
	const [deadline, setDeadline] = useState(getDateInFiveWorkingDays());
	const [amount, setAmount] = useState('10000');

	const [investRisk, setInvestRisk] = useState('3');
	const [investGreen, setInvestGreen] = useState('true');
	const [investTimeHorizon, setInvestTimeHorizon] = useState('5');
	const [investPortfolio, setInvestPortfolio] = useState(PortfolioType.ELITE);

	const [subject, setSubject] = useState('');
	const [email, setEmail] = useState('');
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [sendEmail, setSendEmail] = useState(true);
	const [mailSubject, setMailSubject] = useState('');
	const [mailBody, setMailBody] = useState('');
	const [mailCC, setMailCC] = useState('conseiller@ramify.fr');
	const [mailAttachment, setMailAttachment] = useState<File[]>([]);
	const inputMailFile = useRef<HTMLInputElement | null>(null);

	const isGoogleMeet = useMemo(
		() => eventData?.invitees.collection?.[0].questions_and_answers.some((qa) => qa.answer.includes('Google Meet')),
		[eventData?.invitees.collection],
	);

	//
	// saving notes into session storage
	//

	const updateNotes = useCallback(
		(value: string) => {
			setNotes(value);
			sessionStorage.setItem(event ? event.start!.toISOString() : email, value);
		},
		[email, event],
	);

	useEffect(() => {
		if (event) {
			const e = sessionStorage.getItem(event.start!.toISOString());
			if (e) setNotes(e);
		} else if (email) {
			const e = sessionStorage.getItem(email);
			if (e) setNotes(e);
		}
	}, [email, event]);

	//
	// loading client data from email
	//

	useEffect(() => {
		if (email?.includes('@') && (!firstName || !lastName))
			getClient(email)
				.unwrap()
				.then((client) => {
					if (client?.kyc?.firstName) setFirstName(client.kyc.firstName);
					if (client?.kyc?.lastName) setLastName(client.kyc.lastName);
				});
	}, [email, firstName, getClient, lastName]);

	//
	// loading event data from calendly
	//

	useEffect(() => {
		if (event) {
			const eventId = getEventId(event.resource?.description ?? '');
			if (eventId) {
				getCalendlyEvent(eventId)
					.unwrap()
					.then((data) => {
						setEventData(data);
						setEmail(data.invitees.collection?.[0].email);
						setFirstName(data.invitees.collection?.[0].first_name);
						setLastName(data.invitees.collection?.[0].last_name);
					})
					.catch((error) => toast({ title: 'Failed to get event data', description: error.message, status: 'error' }));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	//
	// propale logic
	//

	const handleGetNotionLink = useCallback(
		(r: string, p: PortfolioType, g: string, f: string, l: string) => {
			getNotionLink({ risk: +r, portfolio: p, green: g === 'true', firstName: f, lastName: l })
				.unwrap()
				.then(({ link }) => setNotionLink(link))
				.catch((error) => console.error('Failed to get notion link', error));
		},
		[getNotionLink],
	);

	const handleCreatePropale = useCallback(async () => {
		createPropale({
			assignedCpmEmail: assignedCpm!,
			email,
			firstName,
			lastName,
			notes,
			type: propaleType!,
			leadClosingProbability:
				leadClosingProbability && leadClosingProbability.length > 1 ? leadClosingProbability : undefined,
			autoPropale:
				propaleType === PropaleType.AUTO
					? {
							investRisk: +investRisk,
							investTimeHorizon: +investTimeHorizon,
							investPortfolio: investPortfolio,
							investGreen: investGreen === 'true',
							notionLink,
							amount: 10_000,
					  }
					: undefined,
			manualPropale: propaleType === PropaleType.MANUAL ? { amount: +amount, deadline: deadline } : undefined,
			followUpPropale: propaleType === PropaleType.FOLLOW_UP ? { subject, deadline: deadline } : undefined,
			mailData: sendEmail ? await formatMailData(mailSubject, mailBody, mailCC, mailAttachment) : undefined,
			googleCalendarId: event?.resource?.id,
		})
			.unwrap()
			.then((p) => {
				toast({ title: 'Propale créée', description: 'La propale a bien été créée', status: 'success' });
				onClose();
				navigate(`/cpm/super/propale/${p.id}`);
			})
			.catch((error) => {
				toast({ title: 'Erreur', description: 'Erreur lors de la création de la propale', status: 'error' });
				console.error('Failed to create propale', error);
			});
		// eslint-disable-next-line prettier/prettier
	}, [createPropale, assignedCpm, email, firstName, lastName, notes, propaleType, leadClosingProbability, investRisk, investTimeHorizon, investPortfolio, investGreen, notionLink, amount, deadline, subject, sendEmail, mailSubject, mailBody, mailCC, mailAttachment, event?.resource?.id, toast, onClose, navigate]);

	//
	// fill templates
	//

	useEffect(() => {
		if (propaleType === PropaleType.AUTO) {
			setMailSubject(subjectAuto);
			setMailBody(templateAuto(firstName));
		}
		if (propaleType === PropaleType.MANUAL) {
			setMailSubject(subjectManualPostCall);
			setMailBody(templateManualPostCall(firstName));
		}
		if (propaleType === PropaleType.FOLLOW_UP) {
			setMailSubject(subjectFollowUpPostCall);
			setMailBody(templateFollowUpPostCall(firstName));
		}
		if (propaleType === PropaleType.NO_SHOW) {
			setMailSubject(subjectNoShowPostCall);
			if (assignedCpm) setMailBody(templateNoShowPostCall(firstName, cpmToCalendly[emailToCpm[assignedCpm]]));
		}
	}, [propaleType, firstName, assignedCpm]);

	useEffect(() => {
		if (user) setAssignedCpm(user.email);
	}, [propaleType, user]);

	if (isCalendlyLoading) return <Skeleton w="100%" h="300px" />;

	return (
		<VStack w="100%" spacing="20px" align="start">
			{eventData && (
				<VStack w="100%" spacing="0px" align="start">
					{eventData.invitees.collection?.[0].questions_and_answers.map((qna) => (
						<Text key={qna.question}>
							{qna.question}: <b>{qna.answer}</b>
						</Text>
					))}
				</VStack>
			)}

			<HStack w="100%" spacing="16px" align="end">
				<InputWithLabel
					label="Email"
					placeholder="Email"
					value={email}
					onChange={(e) => setEmail(e.target.value.trim().toLowerCase())}
				/>
				<SelectWithLabel label="Cpm" value={assignedCpm} onChange={(e) => setAssignedCpm(e.target.value)}>
					{Object.values(CPM).map((cpm) => (
						<option key={cpm} value={cpmToEmail[cpm]}>
							{cpmDisplayName[cpm]}
						</option>
					))}
				</SelectWithLabel>
				{context === 'meeting' && (
					<Button as="a" href={event?.resource?.hangoutLink} minW="200px" target="_blank" isDisabled={!isGoogleMeet}>
						Rejoindre le meeting
					</Button>
				)}
			</HStack>

			<HStack w="100%" spacing="16px">
				<InputWithLabel
					label="Prénom"
					flex={3}
					placeholder="Prénom"
					value={firstName}
					onChange={(e) => setFirstName(e.target.value)}
				/>
				<InputWithLabel
					label="Nom"
					flex={3}
					placeholder="Nom"
					value={lastName}
					onChange={(e) => setLastName(e.target.value)}
				/>
				{propaleType !== PropaleType.NO_SHOW && (
					<SelectWithLabel
						label="Probabilité de closing"
						value={leadClosingProbability}
						onChange={(e) => setLeadClosingProbability(e.target.value as LeadClosingProbability)}
						flex={2}
						placeholder=" "
					>
						<option value={LeadClosingProbability.HIGH}>Haute 🔥</option>
						<option value={LeadClosingProbability.MEDIUM}>Moyenne 🙂</option>
						<option value={LeadClosingProbability.LOW}>Basse 🥶</option>
					</SelectWithLabel>
				)}
			</HStack>

			<VStack w="100%" align="start">
				<Text fontWeight="semibold">Notes du call</Text>
				<Textarea value={notes} onChange={(e) => updateNotes(e.target.value)} h="200px" w="100%" />
			</VStack>

			<SelectWithLabel
				label="Type de propale"
				value={propaleType}
				onChange={(e) => setPropaleType(e.target.value as PropaleType)}
				w="100%"
				placeholder="choisissez une action"
			>
				<option value={PropaleType.AUTO}>Propale automatique</option>
				<option value={PropaleType.MANUAL}>Propale manuelle</option>
				<option value={PropaleType.FOLLOW_UP}>Propale follow up</option>
				<option value={PropaleType.NO_SHOW}>No Show</option>
			</SelectWithLabel>

			{propaleType === PropaleType.AUTO && (
				<VStack w="100%" spacing="16px" align="start">
					<HStack w="100%" spacing="16px">
						<SelectWithLabel
							label="Portfolio"
							w="100%"
							value={investPortfolio}
							onChange={(e) => setInvestPortfolio(e.target.value as PortfolioType)}
						>
							<option value="ELITE">Elite</option>
							<option value="FLAGSHIP">Flagship</option>
							<option value="ESSENTIAL">Essential</option>
						</SelectWithLabel>
						<SelectWithLabel label="Risque" w="100%" value={investRisk} onChange={(e) => setInvestRisk(e.target.value)}>
							<option value="3">3</option>
							<option value="6">6</option>
							{investPortfolio === PortfolioType.ELITE && <option value="8">8</option>}
							<option value="10">10</option>
						</SelectWithLabel>
						<SelectWithLabel
							label="Horizon de temps"
							w="100%"
							value={investTimeHorizon}
							onChange={(e) => setInvestTimeHorizon(e.target.value)}
						>
							<option value={5}>0 à 5 ans</option>
							<option value={10}>5 à 10 ans</option>
							<option value={15}>10 à 15 ans</option>
							<option value={20}>15 à 20 ans</option>
							<option value={30}>+ de 20 ans</option>
						</SelectWithLabel>
						<SelectWithLabel label="ESG" w="100%" value={investGreen} onChange={(e) => setInvestGreen(e.target.value)}>
							<option value="true">Oui</option>
							<option value="false">Non</option>
						</SelectWithLabel>
					</HStack>
					<HStack w="100%" spacing="16px">
						<Button
							colorScheme="blue"
							onClick={() => handleGetNotionLink(investRisk, investPortfolio, investGreen, firstName, lastName)}
							isLoading={isNotionLoading}
							isDisabled={!!notionLink}
						>
							Générer le notion
						</Button>
						{notionLink && (
							<Button as="a" href={notionLink} target="_blank">
								voir
							</Button>
						)}
					</HStack>
				</VStack>
			)}

			{propaleType === PropaleType.MANUAL && (
				<VStack w="100%" spacing="16px" align="start">
					<HStack w="100%" spacing="16px">
						<VStack w="100%" spacing="4px" align="start">
							<Text fontWeight="semibold">Montant</Text>
							<NumberInputWithStepper
								step={1000}
								min={1000}
								w="100%"
								onChange={(e) => setAmount(e)}
								value={amount + '€'}
							/>
						</VStack>
						<InputWithLabel
							label="Deadline (mm/dd/yyyy)"
							type="date"
							w="100%"
							value={deadline}
							onChange={(e) => setDeadline(e.target.value)}
						/>
					</HStack>
				</VStack>
			)}

			{propaleType === PropaleType.FOLLOW_UP && (
				<VStack w="100%" spacing="16px" align="start">
					<HStack w="100%" spacing="16px">
						<InputWithLabel
							label="Sujet"
							w="100%"
							placeholder="Sujet"
							value={subject}
							onChange={(e) => setSubject(e.target.value)}
						/>
						<InputWithLabel
							label="Deadline (mm/dd/yyyy)"
							type="date"
							w="100%"
							value={deadline}
							onChange={(e) => setDeadline(e.target.value)}
						/>
					</HStack>
				</VStack>
			)}

			{isNotNone(propaleType) && propaleType.length > 0 && (
				<VStack align="start" w="100%">
					<HStack w="100%" justify="space-between">
						<Text fontWeight="semibold">Message pour le client</Text>
						{propaleType !== PropaleType.AUTO && (
							<Checkbox isChecked={sendEmail} onChange={(e) => setSendEmail(e.target.checked)}>
								Envoyer un mail
							</Checkbox>
						)}
					</HStack>
					{sendEmail && (
						<VStack w="100%">
							<HStack w="100%" spacing="16px" align="center">
								<Text w="50px">Sujet</Text>
								<Input
									placeholder="Sujet du mail"
									value={mailSubject}
									onChange={(e) => setMailSubject(e.target.value)}
								/>
							</HStack>
							<HStack w="100%" spacing="16px" align="center">
								<Text w="50px">cc</Text>
								<Input placeholder="cc" value={mailCC} onChange={(e) => setMailCC(e.target.value)} />
							</HStack>
							<Textarea h="200px" value={mailBody} onChange={(e) => setMailBody(e.target.value)} />
							<Input
								type="file"
								multiple
								ref={inputMailFile}
								display="none"
								onChange={(e) => {
									setMailAttachment(Array.from(e.target.files ?? []));
									inputMailFile.current!.value = '';
								}}
							/>

							<Wrap shouldWrapChildren align="start" p="4px" w="100%">
								<Button onClick={() => inputMailFile.current?.click()}>Ajouter un document</Button>

								{mailAttachment &&
									Array.from(mailAttachment).map((file) => (
										<DocumentButton
											key={file.name}
											name={file.name}
											onDelete={() => setMailAttachment(mailAttachment.filter((m) => m.name !== file.name))}
										/>
									))}
							</Wrap>
						</VStack>
					)}
				</VStack>
			)}

			<Button
				colorScheme="blue"
				alignSelf="end"
				onClick={handleCreatePropale}
				isDisabled={
					!propaleType ||
					!email ||
					!assignedCpm ||
					(propaleType === PropaleType.AUTO && (!notionLink || !firstName || !lastName)) ||
					(propaleType === PropaleType.MANUAL && (!amount || !firstName || !lastName)) ||
					(propaleType === PropaleType.FOLLOW_UP && (!subject || !firstName || !lastName))
				}
				isLoading={isCreatePropaleLoading}
			>
				Valider
			</Button>
		</VStack>
	);
};

export default CreatePropale;
