import { useMemo } from 'react';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useProfilePicture = (email?: string) => {
	// todo 19/06/24 find a way to get all pictures, not important for now
	const authorPicture = useMemo(() => undefined, []);
	return authorPicture;
};

export default useProfilePicture;
