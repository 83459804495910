import { Box, FormLabel, VStack } from '@chakra-ui/react';

import NumberInputWithStepper from 'components/NumberInput';
import { CrowdfundingFundData } from 'pages/cpm/product-propale/propaleData.type';

type CrowdfundingFundCardProps = {
	data: CrowdfundingFundData;
	editData: React.Dispatch<React.SetStateAction<CrowdfundingFundData[]>>;
};

export const CrowdfundingFundCard = ({ data, editData }: CrowdfundingFundCardProps) => (
	<VStack align="start" spacing="16px" w="100%">
		<Box w="100%">
			<FormLabel fontSize="sm">Montant</FormLabel>
			<NumberInputWithStepper
				step={1000}
				min={data.fund.ticket}
				value={data.initialDepositAmount + '€'}
				onChange={(amount) => {
					editData((old) => {
						const newFunds = [...old];
						newFunds.splice(newFunds.indexOf(data), 1);
						return [...newFunds, { ...data, initialDepositAmount: +amount }].sort((a, b) => a.id - b.id);
					});
				}}
			/>
		</Box>
	</VStack>
);
