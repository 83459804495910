import { useEffect, useRef } from 'react';

export default function useDelay(func: () => void, delay = 500): void {
	const afterLoad = useRef(0);

	useEffect(() => {
		if (afterLoad.current > 0) {
			afterLoad.current -= 1;
			return;
		}

		const timer1 = setTimeout(() => {
			func();
		}, delay);

		// eslint-disable-next-line consistent-return
		return () => {
			clearTimeout(timer1);
		};
	}, [delay, func]);
}
