import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Heading, VStack } from '@chakra-ui/react';

import ClientList from 'pages/ops/super/client/ClientList';

import { ClientDetails } from './ClientDetails';

export type ClientSectionPossiblePath = 'ops' | 'cpm';

type ClientHomeProps = {
	basePath: ClientSectionPossiblePath;
};

const ClientHome: FC<ClientHomeProps> = ({ basePath }) => (
	<VStack w="100%" spacing="24px" align="start">
		<Heading size="lg">Client</Heading>
		<ClientList basePath={basePath} />
	</VStack>
);

type ClientRouterProps = {
	basePath: ClientSectionPossiblePath;
};

const ClientRouter: FC<ClientRouterProps> = ({ basePath }) => (
	<Routes>
		<Route index element={<ClientHome basePath={basePath} />} />
		<Route path=":id" element={<ClientDetails />} />
		<Route path="*" element={<Navigate to={`/${basePath}/super/client`} replace />} />
	</Routes>
);

export default ClientRouter;
