import { FC } from 'react';
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import { TableCellProps, Th } from '@chakra-ui/react';

const TriangleOrder = ({ sortDirection }: { sortDirection: 'asc' | 'desc' }) => {
	if (sortDirection === 'asc') return <TriangleUpIcon ml="4px" />;
	if (sortDirection === 'desc') return <TriangleDownIcon ml="4px" />;
};

// default

type DefaultThProps = TableCellProps & {
	hide?: boolean;
};

export const DefaultTh: FC<DefaultThProps> = ({ children, hide, ...props }) =>
	!hide && (
		<Th borderWidth="2px" {...props}>
			{children}
		</Th>
	);

// sort

type SortThProps = Omit<DefaultThProps, 'onClick'> & {
	value: string;
	selectedSortBy: string;
	sortDirection: 'asc' | 'desc';
	onClick: (orderBy: string) => void;
};

export const SortTh: FC<SortThProps> = ({ value, selectedSortBy, sortDirection, onClick, children, ...props }) => (
	<DefaultTh {...props} textDecor="underline" cursor="pointer" onClick={() => onClick(value)}>
		{children}
		{value === selectedSortBy && <TriangleOrder sortDirection={sortDirection} />}
	</DefaultTh>
);
