import {
	Button,
	Divider,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	VStack,
} from '@chakra-ui/react';

export type ConfirmationModalRattachementProps = {
	show: boolean;
	nbSponsorships: number;
	onConfirm: () => void;
	onClose: () => void;
};

const ConfirmationModalReimbursement = ({
	show,
	nbSponsorships,
	onConfirm,
	onClose,
}: ConfirmationModalRattachementProps) => (
	<Modal isOpen={show} onClose={onClose}>
		<ModalOverlay />
		<ModalContent>
			<ModalHeader>Confirmation</ModalHeader>
			<ModalCloseButton />
			<ModalBody>
				<VStack align="start" w="100%" spacing="16px">
					<VStack align="start" w="100%" spacing="16px">
						<Text>
							Attention, vous êtes sur le point de passer en statut rembourser <b>{nbSponsorships} parrainages</b> !
						</Text>
						<Text>Veuillez vous assurer d'avoir sorti un extract de ces clients auparavant</Text>
					</VStack>
					<Divider />
				</VStack>
			</ModalBody>
			<ModalFooter>
				<Button colorScheme="blue" mr={3} onClick={onConfirm}>
					Confirmer
				</Button>
				<Button variant="ghost" onClick={onClose}>
					Annuler
				</Button>
			</ModalFooter>
		</ModalContent>
	</Modal>
);

export default ConfirmationModalReimbursement;
