import { FC, useState } from 'react';
import { Button, Heading, Input, Text, useDisclosure, VStack } from '@chakra-ui/react';

import AlertDialog from 'components/AlertDialog';
import useThemedToast from 'hooks/useThemedToast';
import { useUpdateEmailMutation } from 'services/client';

type UpdateEmailProps = {
	email: string;
};

const UpdateEmail: FC<UpdateEmailProps> = ({ email }) => {
	const toast = useThemedToast();
	const { isOpen: isValidationOpen, onOpen: openValidation, onClose: closeValidation } = useDisclosure();

	const [newEmail, setNewEmail] = useState<string>('');
	const [updateEmail, { isLoading }] = useUpdateEmailMutation();

	const handleEmailUpdate = async () => {
		updateEmail({ oldEmail: email, newEmail })
			.unwrap()
			.then(() => {
				toast({ status: 'success', title: 'Email mis à jour avec succès' });
				closeValidation();
				setNewEmail('');
			})
			.catch((err) => toast({ status: 'error', title: 'Erreur', description: err.data.message }));
	};

	return (
		<VStack align="start" spacing="32px" w="100%">
			<VStack w="100%" align="start">
				<Heading size="sm">Ancien email</Heading>
				<Input isDisabled value={email} />
			</VStack>
			<VStack w="100%" align="start">
				<Heading size="sm">Nouvel email</Heading>
				<Input value={newEmail} onChange={(e) => setNewEmail(e.target.value.trim())} placeholder="Nouvel email" />
			</VStack>
			<Button
				colorScheme="blue"
				alignSelf="end"
				onClick={() => openValidation()}
				isDisabled={!newEmail || newEmail === email}
			>
				Valider
			</Button>

			<AlertDialog
				isOpen={isValidationOpen}
				onClose={closeValidation}
				header="Modifier l'email du client"
				body={
					<Text>
						Vous êtes sur le point de mettre à jour <b>{email}</b> à <b>{newEmail}</b>
						<br />
						<br />
						Êtes-vous sûr de vouloir continuer ?
					</Text>
				}
				footer={
					<>
						<Button onClick={() => closeValidation()}>Annuler</Button>
						<Button colorScheme="red" onClick={() => handleEmailUpdate()} ml={3} isLoading={isLoading}>
							Valider
						</Button>
					</>
				}
			/>
		</VStack>
	);
};

export default UpdateEmail;
