import { AllDeal } from 'services/deal';
import { useGetCrowdfundingFundByIdQuery, useGetPeFundByIdQuery, useGetScpiFundByIdQuery } from 'services/fund-data';
import { ProductType } from 'types/global.type';

const useFund = (deal: AllDeal | undefined, skip?: boolean) => {
	const { data: peFund } = useGetPeFundByIdQuery(deal?.productType === ProductType.PE ? deal.fund! : '', {
		skip: skip || deal?.productType !== ProductType.PE || !deal?.fund,
	});

	const { data: scpiFund } = useGetScpiFundByIdQuery(deal?.productType === ProductType.SCPI ? deal.fund! : '', {
		skip: skip || deal?.productType !== ProductType.SCPI || !deal?.fund,
	});

	const { data: crowdfundingFund } = useGetCrowdfundingFundByIdQuery(
		deal?.productType === ProductType.CROWDFUNDING ? deal.airtableFundId! : '',
		{ skip: skip || deal?.productType !== ProductType.CROWDFUNDING || !deal?.airtableFundId },
	);

	if (!deal) return null;
	if (deal.productType === ProductType.PE) return peFund ?? null;
	if (deal.productType === ProductType.SCPI) return scpiFund ?? null;
	if (deal.productType === ProductType.CROWDFUNDING) return crowdfundingFund ?? null;
	return null;
};

export default useFund;
