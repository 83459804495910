import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
	Button,
	Card,
	chakra,
	Checkbox,
	Divider,
	FormControl,
	FormLabel,
	Heading,
	HStack,
	Input,
	InputGroup,
	InputRightElement,
	Select,
	Skeleton,
	Spinner,
	Text,
	VStack,
} from '@chakra-ui/react';

import {
	DefiscalisationPropalBody,
	useCreateDefiscalisationPropalMutation,
	useGetResultFiscaliteForClientQuery,
} from 'services/cpm/fiscality-propale';
import { User } from 'types/user.type';

import { InvestFormContext } from './investContent';

const inputWidth = { base: '100%', md: '456px' };

type DefiscalisationPropalBodyForm = Omit<DefiscalisationPropalBody, 'esg'> & {
	esg: 'no' | '2.0' | '2.5';
};

export type PropalDefiscalisationProps = {
	user: User;
	onPrev: () => void;
};

const PropalDefiscalisation = ({ user, onPrev }: PropalDefiscalisationProps) => {
	const [templatePropal, setTemplatePropal] = useState<DefiscalisationPropalBody['typePropal']>();
	const [precisionAum, setPrecisionAum] = useState(false);
	const methods = useForm<DefiscalisationPropalBodyForm>({
		shouldUnregister: true,
	});
	const {
		handleSubmit,
		register,
		formState: { isValid },
	} = methods;
	const [mutation, { isLoading: isGenerating }] = useCreateDefiscalisationPropalMutation();
	const { data: resultOptim, isFetching: isOptimFetching } = useGetResultFiscaliteForClientQuery({ id: user.id });

	const onFormSubmit = handleSubmit((data: DefiscalisationPropalBodyForm) => {
		mutation({
			...data,
			email: user.email,
			portfolioType: data.portfolioType || 'ESSENTIAL',
			riskScore: data.riskScore || 5,
			esg: data.esg === 'no' ? false : true,
			temperature: data.esg === 'no' ? 2.5 : Number(data.esg) || 2.5,
			managementFees: 0.01,
			typePropal: templatePropal!,
		});
		// .unwrap()
		// .then((res) => console.log('succes, got response : ', res))
		// .catch((err) => console.log('error : ', err));
	});

	return (
		<chakra.form onSubmit={onFormSubmit} w="100%">
			<VStack w="100%" align="start" spacing="24px">
				<Divider />
				<HStack w="100%" spacing="12px">
					<Text>Vous êtes sur le compte de :</Text>
					<Heading size="sm">{user.email}</Heading>
				</HStack>
				<Divider />
				<FormControl>
					<FormLabel>Sélectionnez un template :</FormLabel>
					<Select
						placeholder="Sélectionner .."
						value={templatePropal}
						onChange={(e) => setTemplatePropal(e.target.value as DefiscalisationPropalBody['typePropal'])}
					>
						<option value="per_and_pe">Défiscalisation (PER + PE)</option>
						<option value="per">Défiscalisation (PER uniquement)</option>
						<option value="pe">Défiscalisation (PE uniquement)</option>
					</Select>
				</FormControl>
				{templatePropal && (
					<VStack align="start" w="100%" spacing="24px">
						<VStack w="100%" align="start">
							<HStack w="100%" spacing="24px">
								<FormControl maxW={inputWidth}>
									<FormLabel>Montant global à investir :</FormLabel>
									<InputGroup>
										<Input type="number" {...register('amountToInvest', { valueAsNumber: true, required: true })} />
										<InputRightElement children="€" />
									</InputGroup>
								</FormControl>
								<Card bg="gray.50" p="16px 24px">
									<Skeleton w="100%" minH="40px" isLoaded={!isOptimFetching}>
										{resultOptim && (
											<VStack w="100%" align="start">
												<Heading size="sm">
													Montant recommandé par QIS: {resultOptim.optimal_amount_to_invest} €
												</Heading>
												<Text>PER : {resultOptim.per_amount} €</Text>
												<Text>Private Equity : {resultOptim.pe_amount} €</Text>
											</VStack>
										)}
									</Skeleton>
								</Card>
							</HStack>
							{templatePropal === 'per_and_pe' && (
								<Checkbox onChange={(e) => setPrecisionAum(e.target.checked)}>Fournir plus de précision</Checkbox>
							)}
						</VStack>
						{precisionAum === true && templatePropal === 'per_and_pe' && (
							<HStack w="100%" justify="space-between">
								<FormControl maxW={inputWidth}>
									<FormLabel>Montant à investir dans le PER</FormLabel>
									<InputGroup>
										<Input type="number" {...register('amountToInvestPER', { valueAsNumber: true, required: true })} />
										<InputRightElement children="€" />
									</InputGroup>
								</FormControl>
								<FormControl maxW={inputWidth}>
									<FormLabel>Montant à investir dans le Private Equity</FormLabel>
									<InputGroup>
										<Input type="number" {...register('amountToInvestPE', { valueAsNumber: true, required: true })} />
										<InputRightElement children="€" />
									</InputGroup>
								</FormControl>
							</HStack>
						)}

						{templatePropal !== 'pe' && (
							<>
								<Divider />
								<Heading size="md">PER</Heading>
								<FormProvider {...methods}>
									<InvestFormContext />
								</FormProvider>
							</>
						)}

						{templatePropal !== 'per' && (
							<>
								<Divider />
								<Heading size="md">Private Equity</Heading>
								<Text>Pas de customisation pour l'instant ...</Text>
							</>
						)}
					</VStack>
				)}
				<Divider />
				<HStack w="100%" justify="space-between">
					<Button onClick={onPrev}>Précédent</Button>
					{isValid && (
						<Button colorScheme="blue" type="submit" isLoading={isGenerating}>
							{isGenerating ? (
								<HStack>
									Génération en cours ... <Spinner />
								</HStack>
							) : (
								'Générer la propal'
							)}
						</Button>
					)}
				</HStack>
			</VStack>
		</chakra.form>
	);
};

export default PropalDefiscalisation;
