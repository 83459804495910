export const subjectManualPostCall = 'Ramify - Suite à notre échange';
export const templateManualPostCall = (firstname: string) => `Bonjour ${firstname},

Tout d'abord, je souhaiterais vous remercier pour notre échange d'aujourd'hui.

J'espère avoir pu répondre à vos questions concernant nos produits chez <a href="https://www.ramify.fr">Ramify</a>.

Comme convenu pendant notre appel, je m’occupe de revenir vers vous sous une semaine ouvrée avec une proposition d'investissement adaptée à vos objectifs.

Si ce n’est pas déjà fait, je vous invite en parallèle à vous <a href="https://app.ramify.fr/creer-un-compte?redirect=/">créer un espace</a> sur notre plateforme afin que vous puissiez parcourir l’ensemble de nos produits. C’est également depuis votre espace investisseur que vous pourrez visualiser votre proposition d’investissement personnalisée.

Dans cette attente, n’hésitez pas à me solliciter si vous avez des questions complémentaires, je reste bien entendu disponible.

Je vous souhaite une agréable journée,

Cordialement.`;

export const subjectFollowUpPostCall = 'Ramify - Suite à notre échange';
export const templateFollowUpPostCall = (firstname: string) => `Bonjour ${firstname},

Tout d'abord, je souhaiterais vous remercier pour notre échange d'aujourd'hui.

J'espère avoir pu répondre à vos questions concernant nos produits chez <a href="https://www.ramify.fr">Ramify</a>.

Comme convenu pendant notre appel, je m’occupe de revenir vers vous sous une semaine ouvrée avec les informations demandées.

Si ce n’est pas déjà fait, je vous invite en parallèle à vous <a href="https://app.ramify.fr/creer-un-compte?redirect=/">créer un espace</a> sur notre plateforme afin que vous puissiez parcourir l’ensemble de nos produits.

Dans cette attente, n’hésitez pas à me solliciter si vous avez des questions complémentaires, je reste bien entendu disponible.

Je vous souhaite une agréable journée,

Cordialement.`;

export const subjectNoShowPostCall = "Ramify | Vous avez manqué notre rendez-vous d'aujourd'hui";
export const templateNoShowPostCall = (firstname: string, calendlyLink: string) => `Bonjour ${firstname},

J'espère que vous allez bien.

Nous avions un échange prévu aujourd'hui, mais je n'ai malheureusement pas pu vous joindre.

Je sais que cette période de l'année est chargée, donc ne vous inquiétez pas.

Je vous propose de décaler notre échange.

Vous pouvez reprendre un rendez-vous sur <a href="${calendlyLink}">Calendly</a> pour choisir le créneau qui vous convient.

Dans l'attente de votre retour, je vous souhaite une excellente journée.

Cordialement.`;

export const subjectManual = 'Ramify - Votre proposition d’investissement personnalisée est disponible';
export const templateManual = (firstname: string) => `Bonjour ${firstname},

Tout d'abord, j’espère que vous allez bien depuis notre échange.

Comme convenu, je reviens vers vous avec votre proposition d’investissement personnalisée.

Vous pouvez la visualiser directement sur votre <a href="[[AUTOLOGIN_LINK]]">espace client</a>.

Cette proposition est selon nos modèles la plus adaptée à vos objectifs et horizons d'investissement. Qu'en pensez-vous ?

Si la proposition vous parait pertinente, vous avez la possibilité d'y souscrire directement depuis votre espace "souscription".

De mon côté, je reste à disposition pour toute question complémentaire.

Bien à vous.`;

export const templateManualNoSub = (firstname: string, notionLink: string) => `Bonjour ${firstname},

Tout d'abord, j’espère que vous allez bien depuis notre échange.

Comme convenu, je reviens vers vous avec votre proposition d’investissement personnalisée.

Vous pouvez la visualiser en cliquant sur <a href="${notionLink}">ce lien</a>.

Cette proposition est selon nos modèles la plus adaptée à vos objectifs et horizons d'investissement. Qu'en pensez-vous ?

De mon côté, je reste à disposition pour toute question complémentaire.

Bien à vous.`;

export const subjectAuto = 'Ramify - Votre proposition d’investissement personnalisée est disponible';
export const templateAuto = (firstname: string) => `Bonjour ${firstname},

Tout d'abord, je souhaitais vous remercier pour notre échange.

Comme convenu, je reviens vers vous avec votre proposition d’investissement personnalisée.

Vous pouvez la visualiser directement sur votre <a href="[[AUTOLOGIN_LINK]]">espace client</a>.

Qu'en pensez-vous ?

Si la proposition vous parait pertinente, vous avez la possibilité d'y souscrire directement depuis votre espace "souscription".

De mon côté, je reste à disposition pour toute question complémentaire.

Bien à vous.`;
