export enum PatrimonySource {
	PRO_ASSETS = 'PRO_ASSETS',
	REAL_ESTATE_ASSETS = 'REAL_ESTATE_ASSETS',
	ASSETS_SELLOUT = 'ASSETS_SELLOUT',
	GAMBLE_GAINS = 'GAMBLE_GAINS',
	INHERITANCE = 'INHERITANCE',
	SAVINGS = 'SAVINGS',
	OTHER = 'OTHER',
}

const patrimonySourceReferentiel: Record<PatrimonySource, string> = {
	PRO_ASSETS: 'Cession d’actif(s) professionnel(s)',
	REAL_ESTATE_ASSETS: 'Cession d’actif(s) immobilier(s)',
	ASSETS_SELLOUT: 'Cession d’actif(s) mobilier(s)',
	GAMBLE_GAINS: 'Gain aux jeux',
	INHERITANCE: 'Succession / Donation',
	SAVINGS: 'Epargne, revenus',
	OTHER: 'Autre',
};

export default patrimonySourceReferentiel;
