import { SearchBy } from 'components/filters/SearchBy';
import useSessionStorageState from 'hooks/useSessionStorate';
import { SubscriptionStatus } from 'types/global.type';
import { TransferStatus } from 'types/perTransfer.type';

export const statusOptions = [
	SubscriptionStatus.REQUESTED,
	SubscriptionStatus.SIGNED,
	SubscriptionStatus.PARTNER_VALIDATION,
	SubscriptionStatus.PARTNER_TREATMENT,
	SubscriptionStatus.PAYMENT,
	SubscriptionStatus.COMPLETED,
];

const initialState = {
	statusFilter: statusOptions,
	onlyOverdue: false,
	input: '',
	searchBy: 'email' as const,
};

const useTransferFilters = (customSearchBy?: SearchBy, customInput?: string) => {
	const [input, setInput] = useSessionStorageState('SEARCH_INPUT_TRANSFER', customInput ?? initialState.input);
	const [searchBy, setSearchBy] = useSessionStorageState<SearchBy>(
		'SEARCH_BY_TRANSFER',
		customSearchBy ?? initialState.searchBy,
	);
	const [statusFilter, setStatusFilter] = useSessionStorageState<TransferStatus[]>('STATUS_FILTER_TRANSFER', [
		SubscriptionStatus.REQUESTED,
		SubscriptionStatus.SIGNED,
		SubscriptionStatus.PARTNER_VALIDATION,
		SubscriptionStatus.PARTNER_TREATMENT,
		SubscriptionStatus.PAYMENT,
	]);
	const [onlyOverdue, setOnlyOverdue] = useSessionStorageState<boolean>(
		'ONLY_OVERDUE_TRANSFER',
		initialState.onlyOverdue,
	);

	const resetFilters = () => {
		setInput(initialState.input);
		setSearchBy(initialState.searchBy);
		setStatusFilter([
			SubscriptionStatus.REQUESTED,
			SubscriptionStatus.SIGNED,
			SubscriptionStatus.PARTNER_VALIDATION,
			SubscriptionStatus.PARTNER_TREATMENT,
			SubscriptionStatus.PAYMENT,
		]);
		setOnlyOverdue(initialState.onlyOverdue);
	};

	return {
		input,
		setInput,
		searchBy,
		setSearchBy,
		statusFilter,
		setStatusFilter,
		onlyOverdue,
		setOnlyOverdue,
		resetFilters,
	};
};

export default useTransferFilters;
