import { SearchBy } from 'components/filters/SearchBy';
import useSessionStorageState from 'hooks/useSessionStorate';
import { Partner } from 'pages/ops/super/blocking-instance/BlockingInstanceCreate';
import { BlockingInstanceLifecycle, BlockingInstanceStatus } from 'types/blocking-instance.type';

const initialState = {
	partnerFilter: Object.values(Partner),
	statusFilter: Object.values(BlockingInstanceStatus),
	lifecycleFilter: Object.values(BlockingInstanceLifecycle),
	onlyOverdue: false,
	input: '',
	searchBy: 'email' as const,
};

const useBlockingInstanceFilters = (customSearchBy?: SearchBy, customInput?: string) => {
	const [partnerFilter, setPartnerFilter] = useSessionStorageState<string[]>(
		'PARTNER_FILTER',
		initialState.partnerFilter,
	);
	const [statusFilter, setStatusFilter] = useSessionStorageState<BlockingInstanceStatus[]>(
		'STATUS_FILTER_DEALS',
		initialState.statusFilter,
	);
	const [lifecycleFilter, setLifecycleFilter] = useSessionStorageState<BlockingInstanceLifecycle[]>(
		'LIFECYCLE_FILTER',
		initialState.lifecycleFilter,
	);
	const [onlyOverdue, setOnlyOverdue] = useSessionStorageState<boolean>('ONLY_OVERDUE', initialState.onlyOverdue);
	const [input, setInput] = useSessionStorageState('SEARCH_INPUT', customInput ?? initialState.input);
	const [searchBy, setSearchBy] = useSessionStorageState<SearchBy>(
		'SEARCH_BY',
		customSearchBy ?? initialState.searchBy,
	);

	const resetFilters = () => {
		setStatusFilter(initialState.statusFilter);
		setLifecycleFilter(initialState.lifecycleFilter);
		setPartnerFilter(initialState.partnerFilter);
		setOnlyOverdue(initialState.onlyOverdue);
		setInput(initialState.input);
		setSearchBy(initialState.searchBy);
	};

	return {
		partnerFilter,
		setPartnerFilter,
		statusFilter,
		setStatusFilter,
		lifecycleFilter,
		setLifecycleFilter,
		onlyOverdue,
		setOnlyOverdue,
		searchBy,
		setSearchBy,
		input,
		setInput,
		resetFilters,
	};
};

export default useBlockingInstanceFilters;
