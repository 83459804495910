export enum CivilStatus {
	MONSIEUR = 'MONSIEUR',
	MADAME = 'MADAME',
}

const CivilStatusReferentiel: Record<CivilStatus, string> = {
	MONSIEUR: 'Monsieur',
	MADAME: 'Madame',
};

export default CivilStatusReferentiel;
