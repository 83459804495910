import { Controller, useFormContext } from 'react-hook-form';
import { FormControl, FormLabel, HStack, Select, VStack } from '@chakra-ui/react';

import NumberInputWithStepper from 'components/NumberInput';
import { DefiscalisationPropalBody } from 'services/cpm/fiscality-propale';

const inputWidth = { base: '100%', md: '456px' };

export const InvestFormContext = () => {
	const { register, control } = useFormContext<DefiscalisationPropalBody>();

	return (
		<VStack align="start" spacing="32px" w="100%">
			<HStack w="100%" justify="space-between">
				<FormControl maxW={inputWidth}>
					<FormLabel fontSize="sm">Choisir le type de portefeuille</FormLabel>
					<Select placeholder="Sélectionner un portefeuille" {...register('portfolioType', { required: true })}>
						<option value="ELITE">Elite</option>
						<option value="FLAGSHIP">Flagship</option>
						<option value="ESSENTIAL">Essential</option>
					</Select>
				</FormControl>

				<FormControl maxW={inputWidth}>
					<FormLabel fontSize="sm">Risque du portefeuille</FormLabel>
					<Controller
						control={control}
						name="riskScore"
						rules={{ required: true, min: 1, max: 10 }}
						render={({ field: { onChange, value } }) => (
							<NumberInputWithStepper step={0.1} min={1} max={10} value={value} onChange={(s, v) => onChange(v)} />
						)}
					/>
				</FormControl>
			</HStack>

			<HStack w="100%" justify="space-between">
				<FormControl maxW={inputWidth}>
					<FormLabel fontSize="sm">Souhaitez-vous un portefeuille ESG ?</FormLabel>
					<Select placeholder="Choisissez votre option" {...register('esg', { required: true })}>
						<option value="no">Non</option>
						<option value="2.0">Oui, avec température à 2.0 °C</option>
						<option value="2.5">Oui, avec température à 2.5 °C</option>
					</Select>
				</FormControl>
			</HStack>
		</VStack>
	);
};
