import { Navigate, Route, Routes } from 'react-router-dom';
import { Heading, VStack } from '@chakra-ui/react';

import DealList from 'pages/ops/super/deal/DealList';

import { DealDetails } from './DealDetails';

const DealHome = () => (
	<VStack w="100%" spacing="24px" align="start">
		<Heading size="lg">Deal</Heading>
		<DealList context="deal" />
	</VStack>
);

const DealRouter = (): JSX.Element => (
	<Routes>
		<Route index element={<DealHome />} />
		<Route path=":id" element={<DealDetails />} />
		<Route path="*" element={<Navigate to="/ops/super/deal" replace />} />
	</Routes>
);

export default DealRouter;
