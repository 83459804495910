import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ChevronRightIcon } from '@chakra-ui/icons';
import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Alert,
	AlertIcon,
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbLink,
	Button,
	Checkbox,
	Code,
	HStack,
	Skeleton,
	Text,
	VStack,
} from '@chakra-ui/react';

import useThemedToast from 'hooks/useThemedToast';
import { useGetAlertQuery, useResolveAlertMutation, useUnresolveAlertMutation } from 'services/ops/alerts';
import { isNotNone } from 'utils/functions';

export const MetaDataDisplay = ({ metadata }: { metadata: string }) => (
	<Code whiteSpace="break-spaces">{JSON.stringify(metadata, undefined, 6)}</Code>
);

export const AlertProperty = ({ label, value }: { label: string; value: string }) => (
	<VStack align="start">
		<Text color="gray.500" fontSize="0.75rem" fontWeight="semibold">
			{label}
		</Text>
		<Text fontSize="0.9rem">{value}</Text>
	</VStack>
);

const AlertDetails = () => {
	const { id } = useParams();
	const { data: alert, isLoading } = useGetAlertQuery(id!);

	const date = useMemo(() => (!alert ? undefined : new Date(alert?.createdAt)), [alert]);

	const toast = useThemedToast();
	const [triggerResolve] = useResolveAlertMutation();
	const [triggerUnresolve] = useUnresolveAlertMutation();
	const handleResolve = useCallback(
		(value: boolean) => {
			(value ? triggerUnresolve(id!) : triggerResolve(id!))
				.unwrap()
				.then(() => toast({ title: 'Alerte modifiée avec succès', status: 'success' }))
				.catch(() => toast({ title: "Erreur lors de la résolution de l'alerte", status: 'error' }));
		},
		[id, toast, triggerResolve, triggerUnresolve],
	);
	const navigate = useNavigate();

	if (isLoading) return <Skeleton isLoaded={!isLoading} w="100%" h="100%" />;

	if (!alert)
		return (
			<VStack mt="24px" spacing="32px">
				<Alert status="error">
					<AlertIcon />
					L'alerte n'a pas été trouvée
				</Alert>
				<Button onClick={() => navigate('..')}>Retourner à la liste d'alertes</Button>
			</VStack>
		);

	return (
		<VStack mt="24px" spacing="12px" px="32px" w="100%" align="start">
			<HStack w="100%" justify="space-between">
				<Breadcrumb spacing="8px" separator={<ChevronRightIcon color="gray.500" />}>
					<BreadcrumbItem>
						<BreadcrumbLink onClick={() => navigate('..')}>Alertes</BreadcrumbLink>
					</BreadcrumbItem>

					<BreadcrumbItem>
						<BreadcrumbLink>{alert.id}</BreadcrumbLink>
					</BreadcrumbItem>
				</Breadcrumb>

				<HStack align="start">
					<Button colorScheme="blue" onClick={() => navigate(`/ops/super/client/${alert.impactedUser?.id}`)}>
						Voir l'utilisateur impacté
					</Button>
					<Button
						onClick={() => {
							navigator.clipboard.writeText(location.href);
						}}
					>
						Copier le lien de l'alerte
					</Button>
				</HStack>
			</HStack>
			<HStack align="start" justify="space-between" w="100%" maxW="1500px">
				<AlertProperty label="ID" value={alert.id} />
				<AlertProperty label="TITRE" value={alert.title} />
				<AlertProperty
					label="DATE"
					value={`Le ${date?.toLocaleDateString('fr-FR')} à ${date?.toLocaleTimeString('fr-FR')}`}
				/>
			</HStack>
			<VStack align="start">
				<Text color="gray.500" fontSize="0.75rem" fontWeight="semibold">
					RESOLUE
				</Text>
				<Checkbox defaultChecked={!!alert.resolvedAt} onChange={(event) => handleResolve(!event.target.checked)} />
			</VStack>
			<HStack align="start" justify="space-between" w="100%" maxW="400px">
				<AlertProperty label="GROUPE" value={alert.groupId} />
				<AlertProperty label="SERVICE" value={alert.service} />
				<AlertProperty label="PRODUIT" value={alert.product} />
			</HStack>

			<AlertProperty label="DESCRIPTION" value={alert.description} />

			{isNotNone(alert.impactedUser) && (
				<HStack align="start" justify="space-between" w="100%" maxW="400px">
					<AlertProperty label="EMAIL" value={alert.impactedUser?.email} />
				</HStack>
			)}

			{isNotNone(alert.impactedUser?.kyc) && (
				<HStack align="start" justify="space-between" w="100%" maxW="400px">
					<AlertProperty label="PRENOM" value={alert.impactedUser.kyc.firstName} />
					<AlertProperty label="NOM" value={alert.impactedUser.kyc.lastName} />
					<AlertProperty label="BLACK" value={alert.impactedUser?.isBlack ? 'Oui' : 'Non'} />
				</HStack>
			)}

			<Accordion allowToggle w="100%">
				<AccordionItem background="gray.100">
					<AccordionButton>
						<Text color="gray.500" fontSize="0.75rem" fontWeight="semibold">
							METADATA OPS
						</Text>
						<AccordionIcon />
					</AccordionButton>
					<AccordionPanel pb={4}>
						<MetaDataDisplay metadata={alert.metadataOps} />
					</AccordionPanel>
				</AccordionItem>

				<AccordionItem background="gray.100">
					<AccordionButton>
						<Text color="gray.500" fontSize="0.75rem" fontWeight="semibold">
							METADATA TECH
						</Text>
						<AccordionIcon />
					</AccordionButton>
					<AccordionPanel pb={4}>
						<MetaDataDisplay metadata={alert.metadataTech} />
					</AccordionPanel>
				</AccordionItem>
			</Accordion>
		</VStack>
	);
};

export default AlertDetails;
