import { FC, useCallback } from 'react';
import {
	Button,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	ModalProps,
	Text,
} from '@chakra-ui/react';

import { RebalancingType } from 'services/qis';

export enum BatchBehaviorType {
	SKIP = 'SKIP',
	ASK_SIGN = 'ASK_SIGN',
}

type BatchModalProps = Pick<ModalProps, 'isOpen' | 'onClose'> & {
	behavior: BatchBehaviorType;
	rebalanceType: RebalancingType;
	contractsNb: number;
	onTrigger: () => void;
};

export const BatchModal: FC<BatchModalProps> = ({
	isOpen,
	onClose,
	behavior,
	contractsNb,
	onTrigger,
	rebalanceType,
}) => {
	const handleRebalance = useCallback(() => {
		onTrigger();
		onClose();
	}, [onClose, onTrigger]);

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Arbitrage en batch</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					{behavior === BatchBehaviorType.SKIP ? (
						<Text>
							Les contrats qui nécessitent une signature <strong>ne seront pas</strong> arbitrés.
						</Text>
					) : (
						<Text>
							<strong>Un email sera envoyé</strong> aux clients dont les contrats nécessitent une signature.
						</Text>
					)}
					<Text>Le mode d'arbitrage est {rebalanceType}.</Text>
				</ModalBody>

				<ModalFooter justifyContent="space-between">
					<Button onClick={onClose}>Annuler</Button>
					<Button colorScheme="red" onClick={handleRebalance}>
						Arbitrer {contractsNb} contrats
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
