import { FC, ReactNode, useMemo } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
	Box,
	Tab,
	Table,
	TableContainer,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Tbody,
	Text,
	Thead,
	Tr,
} from '@chakra-ui/react';

import { ConditionalLink } from 'components/tables/columns/ConditionalLink';
import { BoolTd, ButtonTd, DateTd, DefaultTd } from 'components/tables/columns/Td';
import { DefaultTh, SortTh } from 'components/tables/columns/Th';
import { isDealOverdue } from 'features/ChangeOverdueDate';
import { useNextStatusModal } from 'hooks/useNextStatusModal';
import useSessionStorageState from 'hooks/useSessionStorate';
import { UserWithKycAndMoral } from 'services/client';
import { AllDeal } from 'services/deal';
import { StatusLabel } from 'types/airtable/status-label.airtable.type';
import { BOContext, LegalEntity, ProductType, SubscriptionStatus } from 'types/global.type';
import { emailToOps, opsDisplayName } from 'types/ops-list.type';
import { displayMoney, getUpdatedStatusTime, isNone, isNotNone } from 'utils/functions';

//
// DISPLAY
//

const getVehiculeType = (productCodePartner: string) => {
	if (productCodePartner === 'RAMV') return 'AV RAMIFY';
	if (productCodePartner === 'RAMP') return 'PER RAMIFY';
	if (productCodePartner === 'P2') return 'AV INTENCIAL';
	if (productCodePartner === 'SP08') return 'AV CAPI';
	if (productCodePartner === 'IL') return 'PER INTENCIAL';
	if (productCodePartner === 'SV09') return 'AV HYBRIDE';
};

const getProductDetails = (deal: AllDeal) => {
	if (
		deal.productType === ProductType.SCPI ||
		deal.productType === ProductType.PE ||
		deal.productType === ProductType.CROWDFUNDING
	)
		return `${deal.productType} - ${deal.fundName} - ${deal.fundName}`;

	if (deal.productType === ProductType.CASH) return `${deal.type} - ${deal.legalEntity}`;
	if (deal.productType === ProductType.GIRARDIN) return `${deal.productType}`;
	if (deal.productType === ProductType.AV_LUX) return `${deal.productType}`;
	if (deal.productType === ProductType.ART) return `${deal.productType} - ${deal.fundName}`;

	if (deal.productType === ProductType.INVEST) {
		if (deal.dealType === 'TRANSFER') return 'INVEST - PER - TRANSFER';
		if (deal.dealType === 'CONTRACT' && deal.structuredProduct.length > 0) return 'PRODUIT STRUCTURE - AV';
		const productType = deal.productType;
		const vehicule = getVehiculeType(deal.envelope?.partnerCode);
		const dealType = deal.dealType;
		const risk = deal.investmentPreferences?.risk ?? '?';
		const projectType = 'subscription' in deal ? deal.subscription?.projectType : deal.projectType;
		const timeHorizon = 'subscription' in deal ? deal.subscription?.timeHorizon : deal.timeHorizon;
		const finalStr = `${productType} - ${vehicule} - ${dealType} - ${projectType} - ${risk}R`;
		return timeHorizon
			? `${finalStr} - ${new Date(timeHorizon).getFullYear() - new Date().getFullYear()} ANS`
			: finalStr;
	}
};

const getProductSupport = (deal: AllDeal) => {
	if (
		deal.productType === ProductType.SCPI ||
		deal.productType === ProductType.PE ||
		deal.productType === ProductType.CROWDFUNDING
	)
		return deal.process;
	if (deal.productType === ProductType.INVEST) return 'api';
	if (deal.productType === ProductType.CASH) return 'api';
	if (deal.productType === ProductType.ART) return 'mail';
	if (deal.productType === ProductType.GIRARDIN) return 'extranet';
	if (deal.productType === ProductType.AV_LUX) return 'extranet';
};

const getProductAmount = (deal: AllDeal) => {
	if (
		deal.productType === ProductType.CASH ||
		deal.productType === ProductType.SCPI ||
		deal.productType === ProductType.PE ||
		deal.productType === ProductType.CROWDFUNDING ||
		deal.productType === ProductType.ART ||
		deal.productType === ProductType.GIRARDIN ||
		deal.productType === ProductType.AV_LUX ||
		(deal.productType === ProductType.INVEST && 'initialDepositAmount' in deal)
	)
		return deal.initialDepositAmount;
	if (deal.productType === ProductType.INVEST && 'transferAmount' in deal) return deal.transferAmount; // transfer
	if (deal.productType === ProductType.INVEST && 'amount' in deal) return deal.amount; // opened contract
};

const getParcoursCompletionDate = (deal: AllDeal): Date | undefined => {
	let completionDate;

	if ('process' in deal && deal.process !== 'mail') completionDate = deal.pendingAt;
	else if (deal.productType === ProductType.INVEST && deal.dealType === 'TRANSFER') return undefined;
	else if (deal.productType === ProductType.INVEST && deal.dealType === 'CONTRACT') return undefined;
	else if (deal.productType === ProductType.GIRARDIN) return undefined;
	else if (deal.productType === ProductType.AV_LUX) completionDate = deal.pendingAt;
	else completionDate = deal.signedAt;

	if (!completionDate) return undefined;
	return new Date(completionDate);
};

const getMoralPersonOnUserFromId = (user: UserWithKycAndMoral, moralPersonId: string): string => {
	const moralPerson = user.moralPersons.find((mp) => mp.id === moralPersonId);
	if (isNotNone(moralPerson)) return moralPerson.denomination || 'Sans denomination'; // no name
	return '?'; // not found
};

const getMoralPersonFromDeal = (deal: AllDeal): string => {
	if (!deal.user) return ''; // won't be able to find the moral person
	if (getLegalEntity(deal) === LegalEntity.MORAL) {
		if ('moralPersonId' in deal && deal.moralPersonId) return getMoralPersonOnUserFromId(deal.user, deal.moralPersonId);
		else return '?'; // no MP to search for
	}
	return ''; // deal is not MORAL
};

const getLegalEntity = (deal: AllDeal): string =>
	'legalEntity' in deal && isNotNone(deal.legalEntity) ? deal.legalEntity : LegalEntity.PHYSICAL;

const rowColor = (deal: AllDeal, selectedDeal?: AllDeal, statuses?: Record<string, StatusLabel>): string => {
	if (selectedDeal?.id === deal.id) return 'blue.50';
	if (isNone(statuses)) return 'white';
	if (isDealOverdue(deal, statuses)) return 'red.50';
	return 'white';
};

//
// SORTING
//

const sortFields = (a: AllDeal, b: AllDeal, orderBy: string, order: 'asc' | 'desc') => {
	if (orderBy === 'amount') {
		const aAmount = +(getProductAmount(a) ?? 0);
		const bAmount = +(getProductAmount(b) ?? 0);
		if (order === 'asc') return aAmount - bAmount;
		else return bAmount - aAmount;
	}
	if (orderBy === 'status') {
		const aStatus = a.status;
		const bStatus = b.status;
		const statuses = Object.keys(SubscriptionStatus);
		if (order === 'asc') return statuses.indexOf(aStatus) - statuses.indexOf(bStatus);
		else return statuses.indexOf(bStatus) - statuses.indexOf(aStatus);
	}
	return 0;
};

//
// UTILS
//

// dirty trick to just put link over regular td, with no button inside. https://stackoverflow.com/a/6393863/10889054
const TdLink = ({
	deal,
	shouldrenderLink,
	children,
	context,
}: {
	deal: AllDeal;
	shouldrenderLink: boolean;
	children: ReactNode;
	context: DealsTableProps['context'];
}) => (
	<ConditionalLink
		style={{ display: 'contents' }}
		to={`/ops/super/${context === 'subscription' ? 'subscription' : 'deal'}/${deal.id}?productType=${
			deal.productType === ProductType.INVEST && deal.dealType === 'TRANSFER' ? 'TRANSFER' : deal.productType
		}`}
		shouldrenderLink={shouldrenderLink}
		children={children}
	/>
);

//
// COMPONENT
//

type DealsTableProps = {
	context: Extract<BOContext, 'client' | 'subscription' | 'deal' | 'blocking-instance'>;
	deals: AllDeal[];
	selectedDeal?: AllDeal;
	onClick?: (c: AllDeal) => void;
	pageSize: number;
	productTypeStatuses?: Record<string, StatusLabel>;
};

const initialState = {
	pageIndex: 0,
	selectedStatus: 'ALL' as const,
	sortBy: 'updatedAt',
	sortDirection: 'desc' as const,
};

const DealsTable: FC<DealsTableProps> = ({ deals, selectedDeal, onClick, pageSize, context, productTypeStatuses }) => {
	const [pageIndex, setPageIndex] = useSessionStorageState<number>('DEALS_PAGE_INDEX', initialState.pageIndex);
	const [selectedStatus, setSelectedStatus] = useSessionStorageState<SubscriptionStatus | 'ALL'>(
		'DEALS_SELECTED_STATUS',
		initialState.selectedStatus,
	);
	const [sortBy, setSortBy] = useSessionStorageState('DEALS_SORT_BY', initialState.sortBy);
	const [sortDirection, setSortDirection] = useSessionStorageState<'asc' | 'desc'>(
		'DEALS_SORT_DIRECTION',
		initialState.sortDirection,
	);

	// todo move out of the component
	const { NextStatusModal, handleOpenNextStatusModal } = useNextStatusModal();

	const nbDeals = useMemo(
		() => deals.filter((s) => (selectedStatus === 'ALL' ? true : s.status === selectedStatus)).length,
		[deals, selectedStatus],
	);

	const handleOrderBy = (orderByParam: string) => {
		if (orderByParam === sortBy) {
			setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		} else {
			setSortBy(orderByParam);
			setSortDirection('asc');
		}
	};

	const statuses = ['ALL', ...Object.values(SubscriptionStatus)];
	const initialIndex = statuses.findIndex((status) => status === selectedStatus);

	return (
		<Box>
			<NextStatusModal />

			<Tabs
				variant="enclosed"
				isFitted
				isLazy
				bg="white"
				index={initialIndex}
				onChange={(index) => {
					setSelectedStatus(statuses[index] as SubscriptionStatus);
					setPageIndex(0);
				}}
			>
				{context === 'deal' && (
					<TabList
						h="60px"
						overflowY="hidden"
						borderWidth="2px"
						borderColor="gray.100"
						sx={{ scrollbarWidth: 'none', '::-webkit-scrollbar': { display: 'none' } }}
					>
						{statuses.map((status) => (
							<Tab
								key={status}
								_selected={{
									borderRadius: '0px',
									borderWidth: '2px',
									borderColor: 'gray.400',
								}}
							>
								{status}
							</Tab>
						))}
					</TabList>
				)}

				<TabPanels>
					{['ALL', ...Object.values(SubscriptionStatus).filter((s) => s !== SubscriptionStatus.PARTNER_TREATMENT)].map(
						(status) => (
							<TabPanel key={status} px="0px" py="0px">
								<TableContainer bg="white">
									<Table variant="simple" size="sm">
										<Thead>
											<Tr>
												<DefaultTh>Email</DefaultTh>
												<DefaultTh>Nom</DefaultTh>
												<DefaultTh>Prénom</DefaultTh>
												<DefaultTh>Black</DefaultTh>
												<DefaultTh>PM</DefaultTh>
												<DefaultTh>Société</DefaultTh>
												<DefaultTh>Produit</DefaultTh>
												<DefaultTh>Partenaire</DefaultTh>
												<DefaultTh hide={context === 'subscription'}>OPS</DefaultTh>
												<SortTh
													onClick={handleOrderBy}
													value="amount"
													selectedSortBy={sortBy}
													sortDirection={sortDirection}
												>
													Montant
												</SortTh>
												<SortTh
													onClick={handleOrderBy}
													value="status"
													selectedSortBy={sortBy}
													sortDirection={sortDirection}
												>
													Statut
												</SortTh>
												<DefaultTh>Changer statut</DefaultTh>
												<DefaultTh>MAJ Statut le</DefaultTh>
												<DefaultTh>Envoi</DefaultTh>
												<DefaultTh hide={context === 'subscription'}>CFCAL 1er deposit</DefaultTh>
												<DefaultTh>Complété le</DefaultTh>
												<DefaultTh>Instance</DefaultTh>
											</Tr>
										</Thead>

										<Tbody>
											{deals
												.filter((s) => (status === 'ALL' ? true : s.status === status))
												.sort((a, b) => sortFields(a, b, sortBy, sortDirection))
												.slice(pageIndex, pageIndex + pageSize)
												.map((deal) => {
													const opsProcess = getProductSupport(deal);
													return (
														<Tr
															key={deal.id}
															cursor="pointer"
															bg={rowColor(deal, selectedDeal, productTypeStatuses)}
															onClick={() => {
																if (onClick) onClick(deal);
															}}
														>
															<TdLink deal={deal} shouldrenderLink={!onClick} context={context}>
																<DefaultTd>{deal.user?.email}</DefaultTd>
																<DefaultTd>{deal.user?.kyc?.lastName}</DefaultTd>
																<DefaultTd>{deal.user?.kyc?.firstName}</DefaultTd>
																<BoolTd value={deal.user?.isBlack} />
																<BoolTd value={getLegalEntity(deal) === LegalEntity.MORAL} />
																<DefaultTd>{getMoralPersonFromDeal(deal)}</DefaultTd>
																<DefaultTd>{getProductDetails(deal)}</DefaultTd>
																<DefaultTd>{deal.partner}</DefaultTd>
																<DefaultTd hideCell={context === 'subscription'}>
																	{opsDisplayName[emailToOps[deal.opsProperties?.assignedOpsEmail ?? '']]}
																</DefaultTd>
																<DefaultTd>{displayMoney(getProductAmount(deal))}</DefaultTd>
																<DefaultTd>{deal.status}</DefaultTd>
															</TdLink>
															<ButtonTd
																onClick={(event) => {
																	event.stopPropagation();
																	handleOpenNextStatusModal(deal);
																}}
																buttonStyleProps={{
																	isDisabled:
																		(context === 'subscription' && (opsProcess === 'mail' || opsProcess === 'api')) ||
																		[SubscriptionStatus.COMPLETED, SubscriptionStatus.TERMINATED].includes(deal.status),
																	w: '100%',
																	fontSize: '14px',
																	h: '20px',
																}}
															>
																Suivant
															</ButtonTd>
															<TdLink deal={deal} shouldrenderLink={!onClick} context={context}>
																<DateTd value={getUpdatedStatusTime(deal)} />
																<DefaultTd>{opsProcess}</DefaultTd>
																<BoolTd
																	hideCell={context === 'subscription'}
																	value={'didInitialDeposit' in deal ? deal.didInitialDeposit : undefined}
																/>
																<DateTd value={getParcoursCompletionDate(deal)} />
																<BoolTd value={deal.hasBlockingInstance} />
															</TdLink>
														</Tr>
													);
												})}
										</Tbody>
									</Table>
								</TableContainer>
							</TabPanel>
						),
					)}
				</TabPanels>
			</Tabs>

			{context !== 'client' && (
				<Box w="100%" textAlign="right" my="16px" fontWeight="semibold" userSelect="none">
					<Text as="span" color="gray.600">
						Show {pageIndex + 1} to {pageIndex + pageSize} of {nbDeals} entries
					</Text>
					<ChevronLeftIcon
						mx="16px"
						boxSize="24px"
						bg="gray.100"
						cursor="pointer"
						onClick={() => setPageIndex(pageIndex === 0 ? Math.floor(nbDeals / pageSize) * 10 : pageIndex - pageSize)}
					/>
					<ChevronRightIcon
						boxSize="24px"
						bg="gray.100"
						cursor="pointer"
						onClick={() => setPageIndex(pageIndex + 1 * pageSize >= nbDeals ? 0 : pageIndex + pageSize)}
					/>
				</Box>
			)}
		</Box>
	);
};

export default DealsTable;
