import { ReactNode, useRef } from 'react';
import {
	AlertDialog as AlertDialogChakra,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
} from '@chakra-ui/react';

type AlertDialogProps = {
	header: ReactNode;
	body: ReactNode;
	footer: ReactNode;
	isOpen: boolean;
	onClose: () => void;
	cancelRef?: React.RefObject<HTMLElement>;
};

const AlertDialog = ({ isOpen, onClose, cancelRef, header, body, footer }: AlertDialogProps) => {
	const ref = useRef<HTMLElement>(null);

	return (
		<AlertDialogChakra isOpen={isOpen} leastDestructiveRef={cancelRef ?? ref} onClose={onClose}>
			<AlertDialogOverlay>
				<AlertDialogContent>
					<AlertDialogHeader fontSize="lg" fontWeight="bold">
						{header}
					</AlertDialogHeader>
					<AlertDialogBody>{body}</AlertDialogBody>
					<AlertDialogFooter>{footer}</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialogChakra>
	);
};

export default AlertDialog;
