import { FC } from 'react';
import { Divider, Heading, Skeleton, VStack } from '@chakra-ui/react';

import { useGetSponsorshipExtractQuery } from 'services/ops/sponsorship';

import SponsorshipTable from './SponsorshipTable';

const SponsorshipTool: FC = () => {
	const { data: affiliateDataList, isLoading } = useGetSponsorshipExtractQuery();

	return (
		<VStack align="start" w="100%" px="24px" spacing="24px" pb="40px">
			<Heading>Parrainages</Heading>
			<Divider />
			{isLoading ? (
				<Skeleton height="400px" width="100%" />
			) : (
				affiliateDataList && <SponsorshipTable affiliateDataList={affiliateDataList} />
			)}
		</VStack>
	);
};

export default SponsorshipTool;
