export enum IncomePeriod {
	MONTHLY = 'MONTHLY',
	ANNUALLY = 'ANNUALLY',
}

const IncomePeriodReferentiel: Record<IncomePeriod, string> = {
	MONTHLY: 'Mensuel',
	ANNUALLY: 'Annuel',
};

export default IncomePeriodReferentiel;
