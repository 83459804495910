import { useMemo, useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
	Box,
	Button,
	HStack,
	Input,
	Menu,
	MenuButton,
	MenuItemOption,
	MenuList,
	MenuOptionGroup,
	Table,
	TableContainer,
	Tbody,
	Text,
	Thead,
	Tr,
} from '@chakra-ui/react';

import { BoolTd, DateTd, DefaultTd } from 'components/tables/columns/Td';
import { DefaultTh } from 'components/tables/columns/Th';
import { PhoenixUser } from 'services/phoenix';

type PhoenixTableProps = {
	users: PhoenixUser[];
	pageSize: number;
	selectedUser?: PhoenixUser;
	onClick: (c: PhoenixUser) => void;
	onCreate: () => void;
};

const PhoenixTable = ({ users, pageSize, selectedUser, onClick, onCreate }: PhoenixTableProps) => {
	const [pageIndex, setPageIndex] = useState<number>(0);
	const [searchBy, setSearchBy] = useState<string>('email');
	const [input, setInput] = useState<string>('');

	const filteredUsers = useMemo(
		() =>
			users.filter((u) =>
				(searchBy === 'email' ? u?.email ?? '' : u?.kyc?.lastName ?? '').toLowerCase().includes(input.toLowerCase()),
			),
		[input, searchBy, users],
	);

	return (
		<Box w="100%">
			<HStack w="100%">
				<Input
					w="500px"
					placeholder={searchBy === 'name' ? 'Nom' : 'Email'}
					value={input}
					onChange={(v) => setInput(v.target.value)}
				/>
				<Menu closeOnSelect={false}>
					<MenuButton as={Button}>Chercher par</MenuButton>
					<MenuList minWidth="240px">
						<MenuOptionGroup value={searchBy} onChange={(v) => setSearchBy(v as string)} type="radio">
							<MenuItemOption value="name">Nom</MenuItemOption>
							<MenuItemOption value="email">Email</MenuItemOption>
						</MenuOptionGroup>
					</MenuList>
				</Menu>
				<Button colorScheme="blue" onClick={onCreate}>
					Créer un utilisateur
				</Button>
			</HStack>

			<Box w="100%" textAlign="right" mb="16px" mt="-32px" fontWeight="semibold" userSelect="none">
				<Text as="span" color="gray.600">
					Show {pageIndex + 1} to {pageIndex + pageSize} of {users.length} entries{' '}
				</Text>
				<ChevronLeftIcon
					mx="16px"
					boxSize="24px"
					bg="gray.100"
					cursor="pointer"
					onClick={() =>
						setPageIndex(pageIndex === 0 ? Math.floor(users.length / pageSize) * 10 : pageIndex - pageSize)
					}
				/>
				<ChevronRightIcon
					boxSize="24px"
					bg="gray.100"
					cursor="pointer"
					onClick={() => setPageIndex(pageIndex + 1 * pageSize >= users.length ? 0 : pageIndex + pageSize)}
				/>
			</Box>

			<TableContainer>
				<Table variant="simple" size="sm">
					<Thead>
						<Tr>
							<DefaultTh>Email</DefaultTh>
							<DefaultTh>Nom</DefaultTh>
							<DefaultTh>Prénom</DefaultTh>
							<DefaultTh>Onboarding terminé</DefaultTh>
							<DefaultTh>KYC rempli</DefaultTh>
							<DefaultTh>KYB rempli</DefaultTh>
							<DefaultTh>Compte bancaire rempli</DefaultTh>
							<DefaultTh>Date de création</DefaultTh>
						</Tr>
					</Thead>

					<Tbody>
						{filteredUsers.slice(pageIndex, pageIndex + pageSize).map((user) => {
							const hasCompletedKyc = !!user.kyc?.patrimonySources.length;
							const hasCompletedKyb = !!user.moralPersons?.at(0)?.moralPersonBeneficiaries?.length;
							const hasCompleteBankInformation = !!user?.bankInformations?.length;

							return (
								<Tr
									key={user.id}
									onClick={() => onClick(user)}
									cursor="pointer"
									bg={selectedUser?.id === user.id ? 'blue.50' : 'white'}
								>
									<DefaultTd>{user?.email}</DefaultTd>
									<DefaultTd>{user?.kyc?.lastName}</DefaultTd>
									<DefaultTd>{user?.kyc?.firstName}</DefaultTd>
									<BoolTd value={hasCompletedKyc && hasCompletedKyb && hasCompleteBankInformation} />
									<BoolTd value={hasCompletedKyc} />
									<BoolTd value={hasCompletedKyb} />
									<BoolTd value={hasCompleteBankInformation} />
									<DateTd value={new Date(user.createdAt)} />
								</Tr>
							);
						})}
					</Tbody>
				</Table>
			</TableContainer>
		</Box>
	);
};

export default PhoenixTable;
