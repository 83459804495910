import { PropsWithChildren, ReactNode } from 'react';
import {
	CloseButtonProps,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerContentProps,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	DrawerProps,
} from '@chakra-ui/react';

type CustomDrawerProps = DrawerProps &
	PropsWithChildren<{
		header: ReactNode;
		footer?: ReactNode;
		isOpen: boolean;
		onClose: () => void;
		contentProps?: DrawerContentProps;
		closeButtonProps?: CloseButtonProps;
	}>;

const CustomDrawer = ({
	isOpen,
	onClose,
	children,
	header,
	footer,
	contentProps,
	closeButtonProps,
	...props
}: CustomDrawerProps): JSX.Element => (
	<Drawer isOpen={isOpen} onClose={onClose} size={{ base: 'md', md: 'xl' }} {...props}>
		<DrawerOverlay />
		<DrawerContent {...contentProps}>
			<DrawerHeader fontSize="lg" fontWeight="bold">
				{header}
			</DrawerHeader>
			<DrawerCloseButton {...closeButtonProps} />
			<DrawerBody {...contentProps}>{children}</DrawerBody>
			<DrawerFooter>{footer}</DrawerFooter>
		</DrawerContent>
	</Drawer>
);

export default CustomDrawer;
