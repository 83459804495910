export enum FamilySituation {
	SINGLE = 'SINGLE',
	DIVORCED = 'DIVORCED',
	MARRIED = 'MARRIED',
	CIVIL_UNION = 'CIVIL_UNION',
	COHABITING = 'COHABITING',
	SEPARATED = 'SEPARATED',
	WIDOWED = 'WIDOWED',
}

const FamilySituationReferentiel: Record<FamilySituation, string> = {
	SINGLE: 'Célibataire',
	DIVORCED: 'Divorcé(e)',
	MARRIED: 'Marié(e)',
	CIVIL_UNION: 'Pacsé(e)',
	COHABITING: 'Concubin(e)',
	SEPARATED: 'Séparé(e)',
	WIDOWED: 'Veuf(ve)',
};

export default FamilySituationReferentiel;
