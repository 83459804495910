import { Navigate, Route, Routes } from 'react-router-dom';
import { Heading, VStack } from '@chakra-ui/react';

import { SubscriptionDetails } from './SubscriptionDetails';
import SubscriptionList from './SubscriptionList';

const SubscriptionHome = () => (
	<VStack w="100%" spacing="24px" align="start">
		<Heading size="lg">Souscriptions</Heading>
		<SubscriptionList context="subscription" />
	</VStack>
);

const SouscriptionRouter = (): JSX.Element => (
	<Routes>
		<Route index element={<SubscriptionHome />} />
		<Route path=":id" element={<SubscriptionDetails />} />
		<Route path="*" element={<Navigate to="/ops/super/souscription" replace />} />
	</Routes>
);

export default SouscriptionRouter;
