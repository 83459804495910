import { useCallback, useMemo, useState } from 'react';
import { DeleteIcon } from '@chakra-ui/icons';
import { Avatar, Button, HStack, Tag, TagLabel, Text, Textarea, Tooltip, VStack } from '@chakra-ui/react';
import { useAuth } from '@frontegg/react';

import Cardlayout from 'components/CardLayout';
import useProfilePicture from 'hooks/useProfilePicture';
import useThemedToast from 'hooks/useThemedToast';
import {
	useCreatePropaleCommentMutation as useCreateCpmCommentMutation,
	useDeletePropaleCommentMutation as useDeleteCpmCommentMutation,
} from 'services/cpm/propale';
import {
	useCreateCommentMutation as useCreateOpsCommentMutation,
	useDeleteCommentMutation as useDeleteOpsCommentMutation,
} from 'services/ops/properties';
import { CpmComment } from 'types/cpm-propale';
import { OpsComment } from 'types/ops-properties.type';
import { isNotNone } from 'utils/functions';
import { displayDateInLetters } from 'utils/rendering';

const useOpsComments = () => {
	const toast = useThemedToast();
	const [comment, setComment] = useState<string>('');

	const [triggerCreateComment] = useCreateOpsCommentMutation();
	const [triggerDelete] = useDeleteOpsCommentMutation();

	const handleCreateComment = useCallback(
		(opsPropertiesId: string) =>
			triggerCreateComment({ id: opsPropertiesId, content: comment })
				.unwrap()
				.then(() => setComment('')),
		[comment, triggerCreateComment],
	);

	const handleDelete = useCallback(
		(opsPropertiesId: string, commentId: string) => {
			triggerDelete({ id: opsPropertiesId, commentId })
				.unwrap()
				.catch(() =>
					toast({
						status: 'error',
						title: 'Erreur',
						description: 'Impossible de supprimer le commentaire',
						duration: 1000,
					}),
				);
		},
		[toast, triggerDelete],
	);

	return {
		comment,
		onCreateComment: handleCreateComment,
		onChangeComment: (value: string) => setComment(value),
		onDeleteComment: handleDelete,
	};
};

const useCpmComments = () => {
	const toast = useThemedToast();
	const [comment, setComment] = useState<string>('');

	const [triggerCreateComment] = useCreateCpmCommentMutation();
	const [triggerDelete] = useDeleteCpmCommentMutation();

	const handleCreateComment = useCallback(
		(propaleId: string) =>
			triggerCreateComment({ id: propaleId, content: comment })
				.unwrap()
				.then(() => setComment('')),
		[comment, triggerCreateComment],
	);

	const handleDelete = useCallback(
		(propaleId: string, commentId: string) => {
			triggerDelete({ id: propaleId, commentId })
				.unwrap()
				.catch(() =>
					toast({
						status: 'error',
						title: 'Erreur',
						description: 'Impossible de supprimer le commentaire',
						duration: 1000,
					}),
				);
		},
		[toast, triggerDelete],
	);

	return {
		comment,
		onCreateComment: handleCreateComment,
		onChangeComment: (value: string) => setComment(value),
		onDeleteComment: handleDelete,
	};
};

const CommentsSection = ({
	comment,
	commentList,
	onCreateComment,
	onChangeComment,
	onDeleteComment,
	isLoading,
}: {
	comment: string;
	commentList?: OpsComment[] | CpmComment[];
	onCreateComment: () => void;
	onChangeComment: (value: string) => void;
	onDeleteComment: (id: string, commentId: string) => void;
	isLoading: boolean;
}): JSX.Element => (
	<Cardlayout title="Commentaires">
		{isNotNone(commentList) && commentList.length > 0 && (
			<VStack align="start">
				{commentList.map((c) => (
					<CommentCard
						key={c.id}
						content={c.content}
						authorEmail={c.authorEmail}
						updatedAt={c.updatedAt}
						onDeleteComment={() => onDeleteComment('opsPropertiesId' in c ? c.opsPropertiesId : c.propaleId, c.id)}
					/>
				))}
			</VStack>
		)}

		<HStack w="100%" position="relative" mt="8px">
			<Button
				zIndex={2}
				position="absolute"
				right={2}
				bottom={2}
				isDisabled={comment.length === 0}
				onClick={onCreateComment}
			>
				Envoyer
			</Button>
			<Textarea h="100px" isDisabled={isLoading} value={comment} onChange={(e) => onChangeComment(e.target.value)} />
		</HStack>
	</Cardlayout>
);

const CommentCard = ({
	authorEmail,
	content,
	updatedAt,
	onDeleteComment,
}: Pick<OpsComment, 'authorEmail' | 'content' | 'updatedAt'> & {
	onDeleteComment: () => void;
}): JSX.Element => {
	const profilePicture = useProfilePicture(authorEmail);
	const dateString = displayDateInLetters(updatedAt);

	const { user } = useAuth();
	const isAuthor = useMemo(() => authorEmail === user?.email, [authorEmail, user?.email]);

	return (
		<HStack w="100%">
			<Tag size="lg" borderRadius="8px">
				<Tooltip label={authorEmail}>
					<Avatar src={profilePicture} size="xs" name={authorEmail} />
				</Tooltip>
				<TagLabel>
					<VStack align="start" p="4px" spacing="0px">
						<HStack>
							<Text fontSize="12px">{dateString}</Text>
							{isAuthor && (
								<DeleteIcon
									m="auto"
									_hover={{ cursor: 'pointer', color: 'red' }}
									onClick={onDeleteComment}
									width="10px"
								/>
							)}
						</HStack>
						<Text overflowWrap="anywhere" fontWeight="light" fontSize="14px">
							{content}
						</Text>
					</VStack>
				</TagLabel>
			</Tag>
		</HStack>
	);
};

export default CommentCard;
export { CommentsSection, useCpmComments, useOpsComments };
