import { FC, useState } from 'react';
import { Button, Heading, HStack, Input, Text, useDisclosure, VStack } from '@chakra-ui/react';

import AlertDialog from 'components/AlertDialog';
import useThemedToast from 'hooks/useThemedToast';
import {
	useGetSponsorQuery,
	useLazyGetClientBySponsorshipCodeQuery,
	useUpdateAffiliationMutation,
} from 'services/client';

type UpdateSponsorshipProps = {
	email: string;
};

const UpdateSponsorship: FC<UpdateSponsorshipProps> = ({ email }) => {
	const toast = useThemedToast();
	const { isOpen: isValidationOpen, onOpen: openValidation, onClose: closeValidation } = useDisclosure();

	const [emailParrain, setEmailParrain] = useState<string>('');
	const [inputSponsorshipCode, setInputSponsorshipCode] = useState<string>('');

	const { data: sponsorData } = useGetSponsorQuery(email);
	const [getUserBySponsorshipCode, { data: foundUser }] = useLazyGetClientBySponsorshipCodeQuery();
	const [updateAffiliation, { isLoading: isUpdateLoading }] = useUpdateAffiliationMutation();

	const handleAffiliationUpdate = async () => {
		updateAffiliation({ emailFilleul: email, emailParrain: emailParrain })
			.unwrap()
			.then(() => {
				toast({ status: 'success', title: 'Parrainage mis à jour avec succès' });
				closeValidation();
				setEmailParrain('');
			})
			.catch((err) => toast({ status: 'error', title: 'Erreur', description: err.data.message }));
	};

	return (
		<VStack align="start" spacing="32px">
			{sponsorData ? (
				<Text>
					Le parrain actuel de ce client est <b>{sponsorData.email}</b>
				</Text>
			) : (
				<Text>Ce client n'a pas de parrain</Text>
			)}

			<VStack align="start" w="100%">
				<Heading size="sm">Recherche par code de parrainage</Heading>
				<HStack w="100%">
					<Input
						value={inputSponsorshipCode}
						onChange={(e) => setInputSponsorshipCode(e.target.value.trim())}
						placeholder="code de parrainage"
					/>
					<Button
						colorScheme="blue"
						onClick={() => getUserBySponsorshipCode(inputSponsorshipCode)}
						isDisabled={!inputSponsorshipCode}
					>
						Valider
					</Button>
				</HStack>
			</VStack>

			{foundUser && (
				<Text>
					Le parrain correspondant au code de parrainage <b>{inputSponsorshipCode}</b> est <b>{foundUser.email}</b>
				</Text>
			)}

			<VStack align="start" w="100%">
				<Heading size="sm">Email du parrain à associer</Heading>
				<HStack w="100%">
					<Input
						value={emailParrain}
						onChange={(e) => setEmailParrain(e.target.value.trim())}
						placeholder="Email du parrain à associer"
					/>

					<Button colorScheme="blue" alignSelf="end" onClick={openValidation} isDisabled={!emailParrain}>
						Valider
					</Button>
				</HStack>
			</VStack>

			<AlertDialog
				isOpen={isValidationOpen}
				onClose={closeValidation}
				header="Modifier l'email du client"
				body={
					<Text>
						Vous êtes sur le point de mettre à jour le parrainage du client <b>{email}</b> en définissant{' '}
						<b>{emailParrain}</b> comme étant son parrain.
						<br />
						<br />
						Êtes-vous sûr de vouloir continuer ?
					</Text>
				}
				footer={
					<>
						<Button onClick={() => closeValidation()}>Annuler</Button>
						<Button colorScheme="red" onClick={() => handleAffiliationUpdate()} ml={3} isLoading={isUpdateLoading}>
							Valider
						</Button>
					</>
				}
			/>
		</VStack>
	);
};

export default UpdateSponsorship;
