import { dashboardApi } from 'store/api';
import { StatusLabel } from 'types/airtable/status-label.airtable.type';
import { SubscriptionStatus } from 'types/global.type';

const endpoints = dashboardApi.injectEndpoints({
	endpoints: (builder) => ({
		getStatusOptions: builder.query<Record<string, SubscriptionStatus[]>, void>({
			query: () => '/bo/ops/status/options',
		}),
		getStatuses: builder.query<Record<string, StatusLabel>, void>({
			query: () => '/bo/ops/status',
		}),
	}),
});

export const { useGetStatusOptionsQuery, useGetStatusesQuery } = endpoints;
