export enum OPS {
	AMELIE = 'AMELIE',
	VIRGILE = 'VIRGILE',
	MEHDI = 'MEHDI',
	EMRYS = 'EMRYS',
	HARIMIRIJA = 'HARIMIRIJA',
}

export const opsDisplayName: Record<OPS, string> = {
	[OPS.AMELIE]: 'Amélie Yuan',
	[OPS.VIRGILE]: 'Virgile Paulien',
	[OPS.EMRYS]: 'Emrys Sauno',
	[OPS.MEHDI]: 'Mehdi Berrada',
	[OPS.HARIMIRIJA]: 'Harimirija Rakotonavalona',
};

export const opsToEmail: Record<OPS, string> = {
	[OPS.AMELIE]: 'amelie.yuan@ramify.fr',
	[OPS.VIRGILE]: 'virgile.paulien@ramify.fr',
	[OPS.EMRYS]: 'emrys.sauno@ramify.fr',
	[OPS.MEHDI]: 'mehdi.berrada@ramify.fr',
	[OPS.HARIMIRIJA]: 'harimirija.rakotonavalona@ramify.fr',
};

export const emailToOps: Record<string, OPS> = Object.entries(opsToEmail).reduce(
	(acc, [ops, email]) => ({ ...acc, [email]: ops as OPS }),
	{} as Record<string, OPS>,
);
