import { FC } from 'react';
import { Checkbox, CheckboxGroup, VStack } from '@chakra-ui/react';

import { FilterPopoverProps } from '.';

const OpsFilter: FC<FilterPopoverProps> = ({ value, options }) => (
	<CheckboxGroup value={value}>
		<VStack align="start" w="100%">
			{Object.values(options).map((option) => (
				<Checkbox key={option} value={option}>
					{option.slice(0, option.indexOf('.')).toUpperCase()}{' '}
					{option.slice(option.indexOf('.') + 1, option.indexOf('@')).toUpperCase()}
				</Checkbox>
			))}
		</VStack>
	</CheckboxGroup>
);

export default OpsFilter;
