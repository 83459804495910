import { FC, PropsWithChildren } from 'react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { HStack, Menu, MenuButton, MenuButtonProps, MenuList, Text } from '@chakra-ui/react';

const DropDown: FC<PropsWithChildren<{ label: string; isOn?: boolean } & MenuButtonProps>> = ({
	label,
	isOn,
	children,
	...props
}) => (
	<Menu>
		<MenuButton
			height="40px"
			px={6}
			borderRadius="sm"
			bgColor="white"
			borderWidth="2px"
			{...(isOn
				? { borderColor: 'blue.500' }
				: {
						_hover: { lg: { borderColor: 'gray.900' } },
				  })}
			{...props}
		>
			<HStack w="100%" justify="space-between">
				<Text variant="Text-S-Regular" textAlign="start">
					{label}
				</Text>
				<ChevronDownIcon color={isOn ? 'blue.500' : undefined} />
			</HStack>
		</MenuButton>
		<MenuList zIndex="dropdown">{children}</MenuList>
	</Menu>
);

export default DropDown;
