import { FC, PropsWithChildren } from 'react';
import { Button, HStack, Popover, PopoverBody, PopoverContent, PopoverTrigger, Portal } from '@chakra-ui/react';

import BasicFilter from './BasicFilter';
import OpsFilter from './OpsFilter';
import PartnerFilter from './PartnerFilter';

export const FILTER_POPOVERS = {
	OPS: OpsFilter,
	PARTNER: PartnerFilter,
	BASIC: BasicFilter,
};

const FilterPopover: FC<PropsWithChildren<{ title: string; onEnableAll: () => void; onDisableAll: () => void }>> = ({
	title,
	children,
	onEnableAll,
	onDisableAll,
}) => (
	<Popover placement="bottom-start">
		<PopoverTrigger>
			<Button>{title}</Button>
		</PopoverTrigger>
		<Portal>
			<PopoverContent w="auto">
				<PopoverBody>{children}</PopoverBody>
				<HStack w="100%" justify="center" spacing={0} bg="gray.100">
					<Button w="100%" onClick={onDisableAll}>
						Aucun
					</Button>
					<Button w="100%" onClick={onEnableAll}>
						Tous
					</Button>
				</HStack>
			</PopoverContent>
		</Portal>
	</Popover>
);

export type FilterPopoverProps = {
	value: string[];
	onChange: (v: string[]) => unknown;
	options: string[];
};

const FilterPopovers: FC<{
	components: { component?: FC<FilterPopoverProps>; title: string; componentProps: FilterPopoverProps }[];
}> = ({ components }) => {
	const handleEnableAll = (onChange: (v: string[]) => unknown, options: string[]) => {
		onChange(options);
	};

	const handleDisableAll = (onChange: (v: string[]) => unknown) => {
		onChange([]);
	};

	return (
		<>
			{components.map((c) => (
				<FilterPopover
					key={c.title}
					title={c.title}
					onEnableAll={() => handleEnableAll(c.componentProps.onChange, c.componentProps.options)}
					onDisableAll={() => handleDisableAll(c.componentProps.onChange)}
				>
					{c.component ? <c.component {...c.componentProps} /> : <BasicFilter {...c.componentProps} />}
				</FilterPopover>
			))}
		</>
	);
};

export default FilterPopovers;
