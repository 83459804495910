import { dashboardApi } from 'store/api';
import { MailingFrameworkPost, MailingFrameworkPostType } from 'types/mailing-framework.type';

export type MailingFrameworkPostCreation = {
	message?: string;
	templateId?: number;
	templateParams?: unknown; // match the object type with the template ID
	type: MailingFrameworkPostType;
	toSendAt?: string;
};

export const endpoints = dashboardApi.injectEndpoints({
	endpoints: (builder) => ({
		createPost: builder.mutation<MailingFrameworkPost, { conversationId: string; body: MailingFrameworkPostCreation }>({
			query: (content) => ({
				url: `bo/mailing-framework/post/${content.conversationId}`,
				method: 'POST',
				body: content.body,
			}),
			invalidatesTags: ['MAILING_FRAMEWORK'],
		}),

		deletePost: builder.mutation<MailingFrameworkPost, { postId: string }>({
			query: ({ postId }) => ({
				url: `bo/mailing-framework/post/${postId}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['MAILING_FRAMEWORK'],
		}),
	}),
});

export const { useCreatePostMutation, useDeletePostMutation } = endpoints;
