import { useEffect, useState } from 'react';
import { Select, Text, VStack } from '@chakra-ui/react';
import { useAuth } from '@frontegg/react';

import { OPS, opsDisplayName, opsToEmail } from 'types/ops-list.type';

export const NO_ASSIGNED_OPS = 'Aucun responsable';

type AssignedOpsProps = {
	assignedOps: string;
	onChangeAssignedOps: (value: string) => void;
	allowNone?: boolean;
	isLoading?: boolean;
};

export const SelectAssignedOps = ({
	assignedOps,
	onChangeAssignedOps,
	allowNone = false,
	isLoading = false,
}: AssignedOpsProps): JSX.Element => (
	<VStack align="start" w="100%">
		<Text>Responsable</Text>
		<Select isDisabled={isLoading} value={assignedOps} onChange={(e) => onChangeAssignedOps(e.target.value)}>
			{allowNone && <option value={NO_ASSIGNED_OPS}>Aucun responsable</option>}
			{Object.values(OPS).map((ops) => (
				<option key={ops} value={opsToEmail[ops]}>
					{opsDisplayName[ops]}
				</option>
			))}
		</Select>
	</VStack>
);

const useAssignedOps = (defaultOps = opsToEmail.MEHDI) => {
	const { user, isLoading } = useAuth();

	const [assignedOps, setAssignedOps] = useState<string>(defaultOps);

	useEffect(() => {
		if (!user || isLoading) return;
		if (Object.values(opsToEmail).includes(user.email)) setAssignedOps(user.email);
	}, [user, isLoading]);

	return {
		assignedOps,
		onChangeAssignedOps: setAssignedOps,
	};
};

export default useAssignedOps;
