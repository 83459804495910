import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import {
	Button,
	FormControl,
	FormErrorMessage,
	FormLabel,
	HStack,
	Text,
	Textarea,
	useDisclosure,
	VStack,
} from '@chakra-ui/react';

import DisplayPostsModal from 'features/mailing-framework/posts/DisplayPostsModal';
import { useGetConversationByIdQuery } from 'services/mailing-framework/mailing-framework.conversation';
import { MailingFrameworkPostCreation } from 'services/mailing-framework/mailing-framework.post';

type DisplayConversationProps = {
	conversationId: string;
	canSendMail?: boolean;
	isLoadingCreatePost?: boolean;
};

const DisplayConversation: FC<DisplayConversationProps> = ({ conversationId, canSendMail, isLoadingCreatePost }) => {
	// FETCH CONVERSATION
	const { data: conversationData } = useGetConversationByIdQuery({ conversationId });

	// POST CREATION FORM
	const {
		register,
		formState: { errors },
		watch,
	} = useFormContext<MailingFrameworkPostCreation>();
	const messageContent = watch('message');

	// WORKFLOW DISCLOSURE
	// const { isOpen: isOpenWk, onClose: onCloseWk } = useDisclosure();
	const { isOpen: isOpenPosts, onClose: onClosePosts, onOpen: onOpenPosts } = useDisclosure();

	if (!conversationData) return null;

	return (
		<VStack w="100%" spacing="16px" align="left">
			<VStack w="100%" align="start">
				<HStack>
					<Text minW="50px">
						<b>Sujet</b>:
					</Text>
					<Text>{conversationData.subject}</Text>
				</HStack>
				<HStack>
					<Text minW="50px">
						<b>Le</b>:
					</Text>
					<Text>{new Date(conversationData.createdAt).toLocaleString()}</Text>
				</HStack>
				<HStack>
					<Text minW="50px">
						<b>From</b>:
					</Text>
					<Text>{conversationData.sender}</Text>
				</HStack>
				<HStack>
					<Text minW="50px">
						<b>To</b>:
					</Text>
					<Text>{conversationData.receiver}</Text>
				</HStack>
			</VStack>

			<Button onClick={onOpenPosts} w="100px">
				Historique
			</Button>

			{/*
			On désactive la fonctionnalité pour le moment
			<Button onClick={onOpenWk}>Ajouter un workflow</Button>
			*/}

			{canSendMail && (
				<VStack gap="24px" spacing="24px" w="100%">
					<VStack align="start" spacing="4px" w="100%">
						<FormControl isInvalid={!!errors.message}>
							<FormLabel>Envoyer un nouveau message</FormLabel>
							<Textarea {...register('message', { required: true })} h="185px" />
							{errors.message?.type === 'required' && <FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>}
						</FormControl>
					</VStack>
					<Button
						colorScheme="blue"
						type="submit"
						mt="auto"
						isLoading={isLoadingCreatePost}
						isDisabled={!messageContent}
						w="100%"
					>
						Envoyer
					</Button>
				</VStack>
			)}

			{/*Modals*/}
			{conversationData && (
				<>
					{/* <WorkflowModal conversationId={conversationId} onClose={onCloseWk} isOpen={isOpenWk} /> */}
					<DisplayPostsModal
						mailingFrameworkPosts={conversationData.mailingFrameworkPosts}
						onClose={onClosePosts}
						isOpen={isOpenPosts}
					/>
				</>
			)}
		</VStack>
	);
};

export default DisplayConversation;
