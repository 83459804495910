import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AlertsSystemFiltersDto, AlertsSystemPaginationDto } from 'services/ops/alerts';

interface AlertsToolState {
	filters: AlertsSystemFiltersDto;
	pagination: AlertsSystemPaginationDto;
}

const initialState: AlertsToolState = {
	filters: {
		impactedUserEmail: '',
		priority: [],
		groupId: [],
		product: [],
		service: [],
		fromCron: false,
		showResolved: false,
	},
	pagination: {
		page: 1,
		limit: 25,
	},
};

const alertsToolSlice = createSlice({
	name: 'alerts-tool',
	initialState,
	reducers: {
		setAlertsFilters(state, action: PayloadAction<AlertsSystemFiltersDto>) {
			state.filters = action.payload;
		},
		resetAlertsFilters(state) {
			// We don't want to reset the fromCron filter
			state.filters = { ...initialState.filters, fromCron: state.filters.fromCron };
		},
		setAlertsPagination(state, action: PayloadAction<AlertsSystemPaginationDto>) {
			state.pagination = action.payload;
		},
	},
});

export const { setAlertsFilters, resetAlertsFilters, setAlertsPagination } = alertsToolSlice.actions;

export default alertsToolSlice.reducer;
