import { CloseIcon } from '@chakra-ui/icons';
import { Button, HStack, Input, Tab, TabList, Tabs, Text, VStack } from '@chakra-ui/react';

import CheckboxFilter from 'components/filters/Checkbox';
import DropDownOptionPicker from 'components/filters/Dropdown/DropDownOptionPicker';
import { AlertsSystemFiltersDto, useGetFilterOptionsQuery } from 'services/ops/alerts';
import { useAppDispatch, useAppSelector } from 'store';

import { resetAlertsFilters, setAlertsFilters } from '../alerts.slice';

const AlertsFilters = ({ totalAlerts }: { totalAlerts: { cron: number; user: number } }) => {
	const filters = useAppSelector((state) => state.alertsTool.filters);
	const {
		impactedUserEmail: emailInput,
		product: productFilter,
		groupId: groupFilter,
		service: serviceFilter,
		priority: priorityFilter,
		fromCron: fromCronChecked,
		showResolved: showResolvedChecked,
	} = filters;
	const dispatch = useAppDispatch();

	const setInput = (value: string) => {
		dispatch(setAlertsFilters({ ...filters, impactedUserEmail: value }));
	};

	const handleFilterChange = (key: keyof AlertsSystemFiltersDto, value: string[]) => {
		dispatch(setAlertsFilters({ ...filters, [key]: value }));
	};

	const handleCheckboxChange = (key: keyof AlertsSystemFiltersDto, value: boolean) => {
		dispatch(setAlertsFilters({ ...filters, [key]: value }));
	};

	const handleResetFilters = () => {
		dispatch(resetAlertsFilters());
	};

	const { data: optionFilters } = useGetFilterOptionsQuery();

	return (
		<VStack w="100%" spacing="24px" align="start">
			<HStack w="100%" spacing="8px">
				<Input
					value={emailInput}
					bg="white"
					onChange={(e) => setInput(e.target.value.trim())}
					placeholder="Email"
					w="456px"
				/>
				<DropDownOptionPicker
					label="Priorité"
					values={priorityFilter || []}
					onChange={(newValue) => handleFilterChange('priority', newValue)}
					options={optionFilters?.priorities || []}
				/>
				<DropDownOptionPicker
					label="Produit"
					values={productFilter || []}
					onChange={(newValue) => handleFilterChange('product', newValue)}
					options={optionFilters?.products || []}
				/>
				<DropDownOptionPicker
					label="Groupe"
					values={groupFilter || []}
					onChange={(newValue) => handleFilterChange('groupId', newValue)}
					options={optionFilters?.groups || []}
				/>
				<DropDownOptionPicker
					label="Service"
					values={serviceFilter || []}
					onChange={(newValue) => handleFilterChange('service', newValue)}
					options={optionFilters?.services || []}
				/>
				<CheckboxFilter
					isChecked={showResolvedChecked}
					onChange={(event) => handleCheckboxChange('showResolved', event.target.checked)}
				>
					<Text>Afficher les alertes résolues</Text>
				</CheckboxFilter>

				<Button size="md" onClick={handleResetFilters}>
					<HStack spacing="12px">
						<CloseIcon boxSize={3} />
						<Text>Effacer les filtres</Text>
					</HStack>
				</Button>
			</HStack>

			<Tabs
				variant="soft-rounded"
				index={!!fromCronChecked ? 1 : 0}
				onChange={(index) => handleCheckboxChange('fromCron', !!index)}
			>
				<TabList>
					<Tab>{totalAlerts.user} - Opérations</Tab>
					<Tab>{totalAlerts.cron} - Actualisations</Tab>
				</TabList>
			</Tabs>
		</VStack>
	);
};

export default AlertsFilters;
