import { FC } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

import BasicCard from 'components/BasicCard';

import KpiResultsPage from './KpiResultsPage';

const KpiStatusCard = () => {
	const navigate = useNavigate();

	return (
		<BasicCard
			title="KPI"
			description="Génération automatisée d'un CSV contenant les KPI ops sur une période donnée"
			buttonText="Ouvrir"
			onClick={() => navigate(`/ops/kpi`)}
		/>
	);
};

const OpsKpiRouter: FC = () => (
	<Routes>
		<Route path="*" element={<KpiResultsPage />} />
	</Routes>
);

export { OpsKpiRouter };
export default KpiStatusCard;
