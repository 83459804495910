import { SearchBy } from 'components/filters/SearchBy';
import useSessionStorageState from 'hooks/useSessionStorate';
import { SubscriptionStatus } from 'types/global.type';

export const productOptions = [
	'INVEST',
	'SP',
	'TRANSFER',
	'PE',
	'SCPI',
	'CER',
	'CATPM',
	'CATPP',
	'ART',
	'CROWDFUNDING',
	'AV_LUX',
	'GIRARDIN',
];

const initialState = {
	partnerFilter: [],
	statusFilter: [SubscriptionStatus.PENDING, SubscriptionStatus.SIGNED],
	clientTypeFilter: [],
	onlyOverdue: false,
	includeDealsWithBI: true,
	productFilter: productOptions,
	input: '',
	searchBy: 'email' as const,
};

const useSubscriptionListFilters = (customSearchBy?: SearchBy, customInput?: string, partnerOptions?: string[]) => {
	const [input, setInput] = useSessionStorageState('SEARCH_INPUT_SUBSCRIPTION', customInput ?? '');
	const [searchBy, setSearchBy] = useSessionStorageState<SearchBy>('SEARCH_BY_SUBSCRIPTION', customSearchBy ?? 'email');
	const [clientTypeFilter, setClientTypeFilter] = useSessionStorageState<string[]>(
		'CLIENT_TYPE_FILTER_SUBSCRIPTION',
		initialState.clientTypeFilter,
	);
	const [statusFilter, setStatusFilter] = useSessionStorageState<SubscriptionStatus[]>(
		'STATUS_FILTER_SUBSCRIPTION',
		initialState.statusFilter,
	);
	const [includeDealsWithBI, setIncludeDealsWithBI] = useSessionStorageState<boolean>(
		'DEALS_WITH_BI_FILTER_SUBSCRIPTION',
		initialState.includeDealsWithBI,
	);
	const [onlyOverdue, setOnlyOverdue] = useSessionStorageState<boolean>('ONLY_OVERDUE_SUBSCRIPTION', false);
	const [productFilter, setProductFilter] = useSessionStorageState<string[]>(
		'PRODUCT_FILTER_SUBSCRIPTION',
		initialState.productFilter,
	);
	const [partnerFilter, setPartnerFilter] = useSessionStorageState<string[]>(
		'PARTNER_FILTER_SUBSCRIPTION',
		partnerOptions ?? initialState.partnerFilter,
	);

	const resetFilters = () => {
		setInput(initialState.input);
		setSearchBy(initialState.searchBy);
		setClientTypeFilter(initialState.clientTypeFilter);
		setStatusFilter(initialState.statusFilter);
		setIncludeDealsWithBI(initialState.includeDealsWithBI);
		setOnlyOverdue(initialState.onlyOverdue);
		setProductFilter(initialState.productFilter);
		setPartnerFilter(partnerOptions ?? initialState.partnerFilter);
	};

	return {
		input,
		setInput,
		searchBy,
		setSearchBy,
		clientTypeFilter,
		setClientTypeFilter,
		statusFilter,
		setStatusFilter,
		includeDealsWithBI,
		setIncludeDealsWithBI,
		onlyOverdue,
		setOnlyOverdue,
		productFilter,
		setProductFilter,
		partnerFilter,
		setPartnerFilter,
		resetFilters,
	};
};

export default useSubscriptionListFilters;
