import { Navigate, Route, Routes } from 'react-router-dom';
import { Heading, VStack } from '@chakra-ui/react';

import PropaleDetails from 'pages/cpm/super/propale/PropaleDetails';
import PropaleList from 'pages/cpm/super/propale/PropaleList';

const PropaleHome = () => (
	<VStack w="100%" spacing="24px" align="start">
		<Heading size="lg">Propales</Heading>
		<PropaleList context="propale" />
	</VStack>
);

const PropaleRouter = (): JSX.Element => (
	<Routes>
		<Route index element={<PropaleHome />} />
		<Route path=":id" element={<PropaleDetails />} />
		<Route path="*" element={<Navigate to="/cpm/super/propale" replace />} />
	</Routes>
);

export default PropaleRouter;
