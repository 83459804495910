import { Outlet, ScrollRestoration } from 'react-router-dom';
import { VStack } from '@chakra-ui/react';

import TopBar from './TopBar';

const AppLayout = (): JSX.Element => (
	<>
		<ScrollRestoration />
		<VStack minH="100vh" bg="gray.50" spacing="0px">
			<TopBar />
			<Outlet />
		</VStack>
	</>
);

export default AppLayout;
