import { FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Button, HStack, Image, StackDivider } from '@chakra-ui/react';
import { useAuth, useDispatch } from '@frontegg/react';

import RamifyLogoFull from 'assets/ramifyLogo.svg';
import { setFrontEggToken } from 'store/auth.slice';
const TopBar: FC = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { isAuthenticated } = useAuth();

	const handleLogout = () => {
		dispatch(setFrontEggToken(null));
		navigate('account/logout');
	};

	return (
		<Box w="100%" zIndex={100} h="65px">
			<Box w="100%" position="absolute" top="0" left="0px">
				<Box w="100%" as="nav" h="65px" bg="gray.800" position="fixed">
					<HStack
						justify="space-between"
						align="center"
						w="100%"
						h="100%"
						px={{ base: '24px', md: '40px' }}
						alignItems="center"
					>
						<HStack align="start" alignItems="center" spacing="24px" divider={<StackDivider color="white" />}>
							<Link to="/">
								<Image src={RamifyLogoFull} w="96px" />
							</Link>
						</HStack>

						{isAuthenticated && (
							<HStack align="start" alignItems="center" spacing="24px">
								<Button size="sm" onClick={handleLogout}>
									Déconnexion
								</Button>
							</HStack>
						)}
					</HStack>
				</Box>
			</Box>
		</Box>
	);
};

export default TopBar;
