import { FC, useMemo, useState } from 'react';
import { Button, Table, TableContainer, Tbody, Thead, Tr, useDisclosure } from '@chakra-ui/react';

import CustomDrawer from 'components/Drawer';
import YesNoIcon from 'components/icons/YesNo';
import { DefaultTd } from 'components/tables/columns/Td';
import { DefaultTh } from 'components/tables/columns/Th';
import { AnyObject } from 'types/global.type';
import { isEmptyObject, isIsoDate, isNone, removeObjectFields, renameObjectFields } from 'utils/functions';

type DictDisplayTableProps = {
	dict: AnyObject;
	fieldToRemove?: string[];
	translation?: Record<string, string>;
};

type FindDisplayValueProps = {
	value: string | number | boolean | AnyObject;
	onClick: () => void;
};

const FindDisplayValue: FC<FindDisplayValueProps> = ({ value, onClick }) => {
	if (isNone(value)) return null;
	if (Array.isArray(value)) return <>{value.join(', ')}</>;
	if (typeof value === 'boolean') return <YesNoIcon isTrue={value} />;
	if (typeof value === 'object')
		return (
			<Button size="xs" onClick={onClick}>
				Voir plus
			</Button>
		);
	if (typeof value === 'string' && isIsoDate(value)) return <>{new Date(value).toLocaleDateString('fr-FR')}</>;
	return <>{String(value)}</>;
};

const DictDisplayTable = ({ dict, fieldToRemove, translation }: DictDisplayTableProps) => {
	const [selectedField, setSelectedField] = useState<string | null>(null);
	const { isOpen, onOpen, onClose } = useDisclosure();

	const newDict = useMemo(() => {
		const filtered = fieldToRemove?.length ?? 0 > 0 ? removeObjectFields(dict, fieldToRemove!) : dict;
		return isEmptyObject(translation) ? filtered : renameObjectFields(filtered, translation!)!;
	}, [dict, fieldToRemove, translation]);

	return (
		<>
			{selectedField && newDict[selectedField] && (
				<CustomDrawer header={selectedField} isOpen={isOpen} onClose={onClose}>
					<DictDisplayTable dict={newDict[selectedField]} fieldToRemove={fieldToRemove} translation={translation} />
				</CustomDrawer>
			)}

			<TableContainer w="100%">
				<Table variant="simple" size="sm">
					<Thead>
						<Tr>
							<DefaultTh w="300px">Key</DefaultTh>
							<DefaultTh>Value</DefaultTh>
						</Tr>
					</Thead>

					<Tbody>
						{Object.keys(newDict).map((key) => (
							<Tr key={key}>
								<DefaultTd w="300px">{key}</DefaultTd>
								<DefaultTd>
									<FindDisplayValue
										value={newDict[key]}
										onClick={() => {
											setSelectedField(key);
											onOpen();
										}}
									/>
								</DefaultTd>
							</Tr>
						))}
					</Tbody>
				</Table>
			</TableContainer>
		</>
	);
};

export default DictDisplayTable;
