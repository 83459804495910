import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { CheckIcon, DownloadIcon } from '@chakra-ui/icons';
import {
	Badge,
	Box,
	Button,
	Checkbox,
	Heading,
	HStack,
	Spinner,
	Table,
	TableCellProps,
	TableColumnHeaderProps,
	TableContainer,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	theme,
	Tr,
	VStack,
	Wrap,
} from '@chakra-ui/react';
import fileDownload from 'js-file-download';

import CheckboxFilter from 'components/filters/Checkbox';
import useThemedToast from 'hooks/useThemedToast';
import { AffiliateOpsExtract, useMarkSponsorshipAsPaidMutation } from 'services/ops/sponsorship';
import { displayMoney, toCSV } from 'utils/functions';
import { displayDateSimpleFormat } from 'utils/rendering';

import ConfirmationModalReimbursement from './ConfirmationModalPayment';

const TableValue = ({ width, children, ...props }: TableCellProps) => (
	<Td overflowX="auto" borderColor="gray.300" minW={width} maxW={width} {...props}>
		{children}
	</Td>
);

const TableHeader = ({ children, ...props }: TableColumnHeaderProps) => (
	<Th borderColor="gray.300" {...props}>
		{children}
	</Th>
);

const SponsorshipTable = ({ affiliateDataList }: { affiliateDataList: AffiliateOpsExtract }) => {
	const toast = useThemedToast();

	const [selectedAffiliate, setSelectedAffiliate] = useState<Map<string, boolean>>(new Map());
	const [markAsPaid, { isLoading: isMarkAsPaidLoading }] = useMarkSponsorshipAsPaidMutation();
	const [showInProgressSponsorships, setShowInProgressSponsorships] = useState(false);
	const [showConfirmModal, setShowConfirmModal] = useState(false);

	const onSelectGlobal = () => {
		if (selectedAffiliate.size > 0) {
			setSelectedAffiliate(new Map());
		} else {
			const newSelectedAlerts = new Map<string, boolean>();
			affiliateDataList.forEach((affiliate) => {
				if (affiliate.isSponsorshipPeriodOver) newSelectedAlerts.set(affiliate?.invitationId, true);
			});
			setSelectedAffiliate(newSelectedAlerts);
		}
	};

	const onSelect = (id: string) => {
		const newSelectedAffiliates = new Map(selectedAffiliate);
		if (newSelectedAffiliates.has(id)) newSelectedAffiliates.delete(id);
		else newSelectedAffiliates.set(id, true);
		setSelectedAffiliate(newSelectedAffiliates);
	};

	const isAffiliateSelected = (id: string) => !!selectedAffiliate.get(id);

	const onMarkAsPaid = () => {
		markAsPaid({ invitationIds: Array.from(selectedAffiliate.keys()) })
			.unwrap()
			.then(() => {
				setSelectedAffiliate(new Map());
				toast({
					status: 'success',
					title: 'Modification enregistrée',
					description: 'Les affiliations ont bien été marquées comme payées',
				});
			})
			.catch((error) => {
				toast({
					status: 'error',
					title: 'Une erreur est survenue',
					description: error?.data?.message,
				});
			})
			.finally(() => setShowConfirmModal(false));
	};

	const filteredAffiliateDataList = useMemo(
		() => affiliateDataList.filter((a) => (showInProgressSponsorships ? true : a.isSponsorshipPeriodOver === true)),
		[affiliateDataList, showInProgressSponsorships],
	);

	const onExportCSV = () => {
		const extract: {
			beneficiary_name: string;
			iban: string;
			amount: number;
			currency: 'EUR';
			reference?: string;
		}[] = [];

		selectedAffiliate.forEach((_, id) => {
			console.log('id', id);

			const invitation = affiliateDataList.find((a) => a.invitationId === id);

			if (!invitation) {
				toast({
					status: 'error',
					title: 'Erreur',
					description: `Une erreur est survenue lors de l'export du fichier CSV, à cause de l'invitation ${id}. Veuillez vous rapprocher d'un tech`,
				});
				return;
			}

			// affilié
			extract.push({
				beneficiary_name: invitation.affiliate.kyc.firstName + '-' + invitation.affiliate.kyc.lastName,
				iban: invitation.affiliateIBAN,
				amount: invitation.reward,
				currency: 'EUR',
			});
			// parrain
			extract.push({
				beneficiary_name: invitation.sponsor.kyc.firstName + '-' + invitation.sponsor.kyc.lastName,
				iban: invitation.sponsorIBAN,
				amount: invitation.reward,
				currency: 'EUR',
			});
		});

		// fill reference
		const today = new Date();
		const todayFormattedReference = `${today.getDate()}_${today.getMonth() + 1}_${today.getFullYear()}`;
		const extractWithReference = extract.map((e, i) => ({
			...e,
			reference: `RB_${todayFormattedReference}_${i + 1}`,
		}));

		fileDownload(toCSV(extractWithReference), 'extract', 'text/csv');
	};

	return (
		<VStack w="100%" align="start" spacing="24px">
			<ConfirmationModalReimbursement
				show={showConfirmModal}
				nbSponsorships={selectedAffiliate.size}
				onConfirm={onMarkAsPaid}
				onClose={() => setShowConfirmModal(false)}
			/>
			<CheckboxFilter
				isChecked={showInProgressSponsorships}
				onChange={(event) => setShowInProgressSponsorships(event.target.checked)}
			>
				<Text>Afficher les parrainages en cours</Text>
			</CheckboxFilter>

			<HStack w="100%" justify="space-between">
				<Heading whiteSpace="nowrap" size="md" color={selectedAffiliate.size > 0 ? 'blue.500' : 'gray.900'}>
					{selectedAffiliate.size === 0
						? 'Primes de parrainages à traiter'
						: `${selectedAffiliate.size} primes sélectionnées`}
				</Heading>
				<HStack w="100%" justify="end">
					<Button
						colorScheme="green"
						size="sm"
						isDisabled={selectedAffiliate.size === 0 || isMarkAsPaidLoading}
						onClick={() => setShowConfirmModal(true)}
					>
						<HStack spacing="8px">
							<Text>Marquer comme payé</Text>
							{isMarkAsPaidLoading ? <Spinner size="xs" /> : <CheckIcon boxSize="12px" />}
						</HStack>
					</Button>
					<Button
						colorScheme="blue"
						size="sm"
						isDisabled={selectedAffiliate.size === 0 || isMarkAsPaidLoading}
						onClick={onExportCSV}
					>
						<HStack spacing="8px">
							<Text>Exporter CSV</Text>
							<DownloadIcon boxSize="12px" />
						</HStack>
					</Button>
				</HStack>
			</HStack>

			<TableContainer w="100%" bg="white" paddingTop="8px" borderRadius="8px" border="1px solid" borderColor="gray.300">
				<Table w="100%" variant="simple" size="md" __css={{ tableLayout: 'fixed' }}>
					<Thead w="100%">
						<Tr w="100%">
							<TableHeader w="60px">
								<Checkbox
									isIndeterminate={selectedAffiliate.size > 0 && selectedAffiliate.size !== affiliateDataList.length}
									isChecked={selectedAffiliate.size === affiliateDataList.length}
									onChange={() => onSelectGlobal()}
								/>
							</TableHeader>
							<TableHeader w="300px">Filleul</TableHeader>
							<TableHeader w="300px">Parrain</TableHeader>
							<TableHeader w="100px">Prime</TableHeader>
							<TableHeader w="200px">Fin de période</TableHeader>
							<TableHeader w="200px">Deadline Paiement</TableHeader>
							<TableHeader w="100%">Souscriptions</TableHeader>
						</Tr>
					</Thead>
					<Tbody w="100%">
						{filteredAffiliateDataList.map((affiliate) => (
							<Tr
								w="100%"
								key={affiliate?.invitationId}
								bg={
									isAffiliateSelected(affiliate?.invitationId)
										? 'blue.50 !important'
										: affiliate.isSponsorshipPeriodOver
										? 'white'
										: 'gray.100'
								}
							>
								<TableValue
									cursor="pointer"
									onClick={(e) => {
										e.stopPropagation();
										if (affiliate.isSponsorshipPeriodOver) onSelect(affiliate?.invitationId);
									}}
									_hover={{ bg: affiliate.isSponsorshipPeriodOver ? 'gray.50' : 'gray.100' }}
								>
									{/* https://github.com/chakra-ui/chakra-ui/issues/2854 */}
									<Box pointerEvents="none">
										<Checkbox
											isDisabled={!affiliate.isSponsorshipPeriodOver}
											isChecked={isAffiliateSelected(affiliate?.invitationId)}
											isReadOnly
										/>
									</Box>
								</TableValue>

								<TableValue _hover={{ bg: theme.colors.gray[100] }} cursor="pointer">
									<Link to={`/ops/super/client/${affiliate?.affiliate?.id}`}>
										<VStack align="start">
											<Text>{affiliate?.affiliate?.email}</Text>
											<Text>
												{affiliate?.affiliate?.kyc?.firstName} - {affiliate?.affiliate?.kyc?.lastName}
											</Text>
										</VStack>
									</Link>
								</TableValue>

								<TableValue _hover={{ bg: theme.colors.gray[100] }} cursor="pointer">
									<Link to={`/ops/super/client/${affiliate?.sponsor?.id}`}>
										<VStack align="start">
											<Text>{affiliate?.sponsor?.email}</Text>
											<Text>
												{affiliate?.sponsor?.kyc?.firstName} - {affiliate?.sponsor?.kyc?.lastName}
											</Text>
										</VStack>
									</Link>
								</TableValue>

								<TableValue>
									<Text
										fontWeight="600"
										color={
											affiliate?.reward === 50 ? 'green.500' : affiliate?.reward === 100 ? 'yellow.500' : 'red.500'
										}
									>
										{displayMoney(affiliate?.reward)}
									</Text>
								</TableValue>

								<TableValue>
									{affiliate?.affiliationDeadline && (
										<Text>{displayDateSimpleFormat(affiliate.affiliationDeadline as unknown as string)}</Text>
									)}
								</TableValue>

								<TableValue>
									{affiliate?.reimbursementDeadline && (
										<Text>{displayDateSimpleFormat(affiliate.reimbursementDeadline as unknown as string)}</Text>
									)}
								</TableValue>

								<TableValue>
									<Wrap>
										{affiliate?.consideredSubscriptions.map((subscription) => (
											<Link
												key={subscription.id}
												to={`/ops/super/subscription/${subscription?.id}?productType=${subscription?.type}`}
											>
												<Badge colorScheme="gray" cursor="pointer" _hover={{ bg: theme.colors.gray[300] }}>
													{subscription.type} - {displayMoney(subscription.amount)}
												</Badge>
											</Link>
										))}
									</Wrap>
								</TableValue>
							</Tr>
						))}
					</Tbody>
				</Table>
			</TableContainer>
		</VStack>
	);
};

export default SponsorshipTable;
