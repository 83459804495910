import { useMemo, useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
	Box,
	Tab,
	Table,
	TableContainer,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Tbody,
	Text,
	Thead,
	Tr,
} from '@chakra-ui/react';

import { ConditionalLink } from 'components/tables/columns/ConditionalLink';
import { BoolTd, DateTd, DefaultTd } from 'components/tables/columns/Td';
import { DefaultTh, SortTh } from 'components/tables/columns/Th';
import { statusNamingMap } from 'pages/cpm/super/propale/PropaleList';
import { cpmDisplayName, emailToCpm } from 'types/cpm-list.type';
import { CpmPropale, LeadClosingProbability, PropaleStatus } from 'types/cpm-propale';
import { BOContext } from 'types/global.type';
import { MailingFrameworkPostType } from 'types/mailing-framework.type';
import { displayMoney, isNotNone } from 'utils/functions';

const translateLeadClosingProbability = (probability: LeadClosingProbability | undefined) => {
	if (probability === LeadClosingProbability.HIGH) return 'Haute 🔥';
	if (probability === LeadClosingProbability.MEDIUM) return 'Moyenne 🙂';
	if (probability === LeadClosingProbability.LOW) return 'Basse 🥶';
	return undefined;
};

const rowColor = (propale: CpmPropale, todayDate: number, selectedPropale?: CpmPropale): string => {
	if (selectedPropale?.id === propale.id) return 'blue.50';
	if (propale.deadline && todayDate - new Date(propale.deadline).getTime() > 0) return 'red.50';
	return 'white';
};

const sortFields = (a: CpmPropale, b: CpmPropale, orderBy: string, order: 'asc' | 'desc') => {
	if (orderBy === 'amount') {
		const aAmount = a.amount ?? 0;
		const bAmount = b.amount ?? 0;
		return order === 'asc' ? aAmount - bAmount : bAmount - aAmount;
	}
	if (orderBy === 'deadline') {
		const aDeadline = a.deadline ? new Date(a.deadline).getTime() : 0;
		const bDeadline = b.deadline ? new Date(b.deadline).getTime() : 0;
		return order === 'asc' ? aDeadline - bDeadline : bDeadline - aDeadline;
	}
	if (orderBy === 'sentAt') {
		const aSentAt = a.sentAt ? new Date(a.sentAt).getTime() : 0;
		const bSentAt = b.sentAt ? new Date(b.sentAt).getTime() : 0;
		return order === 'asc' ? aSentAt - bSentAt : bSentAt - aSentAt;
	}
	return 0;
};

type PropaleTableProps = {
	context: Extract<BOContext, 'client' | 'propale'>;
	propales: CpmPropale[];
	selectedPropale?: CpmPropale;
	onClick?: (c: CpmPropale) => void;
	pageSize: number;
};

const PropalesTable = ({ context, propales, selectedPropale, onClick, pageSize }: PropaleTableProps) => {
	const [pageIndex, setPageIndex] = useState<number>(0);
	const [selectedStatus, setSelectedStatus] = useState<PropaleStatus>(PropaleStatus.TODO);

	const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');
	const [sortBy, setSortBy] = useState<string>(
		selectedStatus === PropaleStatus.DONE || selectedStatus === PropaleStatus.SENT ? 'sentAt' : 'updatedAt',
	);

	const nbPropales = useMemo(
		() => propales.filter((s) => s.status === selectedStatus).length,
		[propales, selectedStatus],
	);

	const handleOrderBy = (orderByParam: string) => {
		if (orderByParam === sortBy) {
			setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		} else {
			setSortBy(orderByParam);
			setSortDirection('asc');
		}
	};

	const todayDate = useMemo(() => new Date().getTime(), []);

	return (
		<Box>
			<Tabs variant="enclosed" w="100%" isFitted isLazy bg="white">
				{context === 'propale' && (
					<TabList h="60px" borderWidth="2px" borderColor="gray.100">
						{Object.values(PropaleStatus).map((status) => (
							<Tab
								key={status}
								_selected={{
									borderRadius: '0px',
									borderWidth: '2px',
									borderColor: 'gray.400',
								}}
								onClick={() => {
									setSelectedStatus(status);
									setPageIndex(0);
								}}
							>
								{statusNamingMap[status]}
							</Tab>
						))}
					</TabList>
				)}

				<TabPanels>
					{Object.values(PropaleStatus).map((status) => (
						<TabPanel key={status} px="0px" py="0px">
							<TableContainer bg="white">
								<Table variant="simple" size="sm">
									<Thead>
										<Tr>
											<DefaultTh>Email</DefaultTh>
											<DefaultTh>Nom</DefaultTh>
											<DefaultTh>Prénom</DefaultTh>
											<SortTh
												onClick={handleOrderBy}
												value="amount"
												selectedSortBy={sortBy}
												sortDirection={sortDirection}
											>
												Montant
											</SortTh>
											<DefaultTh>Type</DefaultTh>

											{/* product isn't defined yet in todo */}
											<DefaultTh hide={status === PropaleStatus.TODO}>Produit</DefaultTh>
											{/* we don't send mail when propale is marked as done */}
											<DefaultTh hide={status !== PropaleStatus.SENT}>Mail envoyé</DefaultTh>
											{/* we just want to check if the client replied to the mail where the propale is sent */}
											<DefaultTh hide={status !== PropaleStatus.SENT}>A répondu</DefaultTh>
											{/* we just want to get the number of relance for the mail where the propale is sent */}
											<DefaultTh hide={status !== PropaleStatus.SENT}>Relances</DefaultTh>

											<SortTh
												onClick={handleOrderBy}
												value="black"
												selectedSortBy={sortBy}
												sortDirection={sortDirection}
											>
												Black
											</SortTh>
											<DefaultTh>Morale</DefaultTh>
											<DefaultTh>Societé(s)</DefaultTh>
											<DefaultTh>CPM attitré</DefaultTh>
											<DefaultTh>CPM owner</DefaultTh>
											<DefaultTh>Proba de closing</DefaultTh>
											<SortTh
												onClick={handleOrderBy}
												value="deadline"
												selectedSortBy={sortBy}
												sortDirection={sortDirection}
											>
												Deadline
											</SortTh>
											<SortTh
												hide={status !== PropaleStatus.DONE && status !== PropaleStatus.SENT}
												onClick={handleOrderBy}
												value="sentAt"
												selectedSortBy={sortBy}
												sortDirection={sortDirection}
											>
												Envoyé le
											</SortTh>
											<DefaultTh>Crée le</DefaultTh>
										</Tr>
									</Thead>

									<Tbody>
										{propales
											.filter((s) => s.status === status)
											.sort((a, b) => sortFields(a, b, sortBy, sortDirection))
											.slice(pageIndex, pageIndex + pageSize)
											.map((propale) => (
												<ConditionalLink
													key={propale.id}
													style={{ display: 'contents' }}
													to={`/cpm/super/propale/${propale.id}`}
													shouldrenderLink={!onClick}
												>
													<Tr
														key={propale.id}
														cursor="pointer"
														bg={rowColor(propale, todayDate, selectedPropale)}
														onClick={() => {
															if (onClick) onClick(propale);
														}}
													>
														<DefaultTd>{propale.email}</DefaultTd>
														<DefaultTd>{propale.lastName}</DefaultTd>
														<DefaultTd>{propale.firstName}</DefaultTd>
														<DefaultTd>{displayMoney(propale.amount)}</DefaultTd>
														<DefaultTd>{propale.type}</DefaultTd>

														{/* PRODUCT TYPES - product isn't defined yet in when propale status is todo */}
														<DefaultTd hideCell={status === PropaleStatus.TODO}>
															{propale.linkedEntities.map((e) => e.product).join(', ')}
														</DefaultTd>

														{/* HAS BEEN SENT - check if the mail is sent when propale is sent */}
														<BoolTd
															hideCell={status !== PropaleStatus.SENT}
															value={isNotNone(
																propale.conversationPropale?.mailingFrameworkPosts.find(
																	(p) => p.type === MailingFrameworkPostType.MANUAL && p.sentAt,
																),
															)}
														/>

														{/* HAS REPLIED - check if the client replied to the mail where the propale is sent */}
														<BoolTd
															hideCell={status !== PropaleStatus.SENT}
															value={propale.conversationPropale?.hasReplied}
														/>

														{/* NB RELANCES - get the number of relance for the mail where the propale is sent */}
														<DefaultTd hideCell={status !== PropaleStatus.SENT}>
															{
																propale.conversationPropale?.mailingFrameworkPosts.filter(
																	(p) => p.type === MailingFrameworkPostType.WORKFLOW && p.sentAt,
																).length
															}
														</DefaultTd>

														<BoolTd value={propale.user?.isBlack ?? false} />
														<BoolTd value={(propale.user?.moralPersons?.length ?? 0) > 0} />
														<DefaultTd>
															{propale.user?.moralPersons?.map((m) => m.denomination).join(', ') || 'Aucune'}
														</DefaultTd>
														<DefaultTd>{cpmDisplayName[emailToCpm[propale.assignedCpmEmail ?? '']]}</DefaultTd>
														<DefaultTd>{cpmDisplayName[emailToCpm[propale.creatorCpmEmail]]}</DefaultTd>
														<DefaultTd>{translateLeadClosingProbability(propale.leadClosingProbability)}</DefaultTd>
														<DateTd value={propale.deadline ? new Date(propale.deadline) : undefined} />
														<DateTd
															hideCell={status !== PropaleStatus.DONE && status !== PropaleStatus.SENT}
															value={propale.sentAt ? new Date(propale.sentAt) : undefined}
														/>
														<DateTd value={new Date(propale.createdAt)} />
													</Tr>
												</ConditionalLink>
											))}
									</Tbody>
								</Table>
							</TableContainer>
						</TabPanel>
					))}
				</TabPanels>
			</Tabs>

			{context !== 'client' && (
				<Box w="100%" textAlign="right" my="16px" fontWeight="semibold" userSelect="none">
					<Text as="span" color="gray.600">
						Show {pageIndex + 1} to {pageIndex + pageSize} of {nbPropales} entries{' '}
					</Text>
					<ChevronLeftIcon
						mx="16px"
						boxSize="24px"
						bg="gray.100"
						cursor="pointer"
						onClick={() =>
							setPageIndex(pageIndex === 0 ? Math.floor(nbPropales / pageSize) * 10 : pageIndex - pageSize)
						}
					/>
					<ChevronRightIcon
						boxSize="24px"
						bg="gray.100"
						cursor="pointer"
						onClick={() => setPageIndex(pageIndex + 1 * pageSize >= nbPropales ? 0 : pageIndex + pageSize)}
					/>
				</Box>
			)}
		</Box>
	);
};

export default PropalesTable;
