export enum InvestorCategory {
	INDIVIDUAL = 'INDIVIDUAL',
	PROFESSIONAL = 'PROFESSIONAL',
	ASSIMILTED_PROFESSIONAL = 'ASSIMILTED_PROFESSIONAL',
}

enum MfaMethod {
	SMS,
	AUTHENTICATOR,
}

export type User = {
	id: string;
	createdAt: string;
	updatedAt: string;

	email: string;
	hasPassword?: boolean;
	emailValidated: boolean;
	isMfaEnabled?: boolean;
	mfaMethod?: MfaMethod;

	investorCategory: InvestorCategory;

	isDemo: boolean;
	isPhoenix?: boolean;
	isCorporate?: boolean;
	isBlack?: boolean;

	hubspotId?: string;
	intercomId?: string;
	intercomIdentity: string;

	sponsoredByCode?: string;
	partnerId?: string;
	featureFlags: Record<string, boolean>;
};
