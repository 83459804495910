export enum LastOperationsChoices {
	LAST_YEAR = 'LAST_YEAR',
	LAST_3_YEARS = 'LAST_3_YEARS',
	MORE_THAN_3_YEARS = 'MORE_THAN_3_YEARS',
}

export enum KnowledgePossibleChoices {
	NO_KNOWLEDGE = 'NO_KNOWLEDGE',
	KNOWLEDGE = 'KNOWLEDGE',
	EXPERIENCE = 'EXPERIENCE',
	MORE_THAN_5_OPERATIONS = 'MORE_THAN_5_OPERATIONS',
}

export enum RiskPerception {
	NORISK = 'NORISK',
	LOWRISK = 'LOWRISK',
	HIGHRISK = 'HIGHRISK',
}

export enum LengthPerception {
	SHORTERM = 'SHORTERM',
	MIDTERM = 'MIDTERM',
	LONGTERM = 'LONGTERM',
}

export const knowledgeLevel = {
	NO_KNOWLEDGE: "Je n'ai aucune connaissance de ce produit",
	KNOWLEDGE: "J'ai connaissance de ce produit",
	EXPERIENCE: "J'ai déjà eu une expérience avec ce produit",
	MORE_THAN_5_OPERATIONS: "J'ai déjà réalisé plus de 5 opérations sur ce produit",
};

export const riskPerceptionOptions = Object.entries({
	NORISK: 'Aucun risque',
	LOWRISK: 'Risque moyen',
	HIGHRISK: 'Risque élevé',
} as Record<RiskPerception, string>) as [Adequation['riskPerceptionPe'], string][];

export const lengthPerceptionOptions = Object.entries({
	SHORTERM: 'Court-terme',
	MIDTERM: 'Moyen-terme',
	LONGTERM: 'Long-terme',
} as Record<LengthPerception, string>) as [Adequation['lengthPerceptionPe'], string][];

export const lastOperationChoices = Object.entries({
	LAST_YEAR: "Au cours de l'année en cours",
	LAST_3_YEARS: 'Il y a moins de 3 ans',
	MORE_THAN_3_YEARS: 'Il y a plus de 3 ans',
});

export interface Adequation {
	id: string;
	createdAt: string;
	updatedAt: string;

	userId: string;

	peaKnowledge?: KnowledgePossibleChoices;
	avKnowledge?: KnowledgePossibleChoices;
	opcvmMonetaireKnowledge?: KnowledgePossibleChoices;
	opcvmActionKnowledge?: KnowledgePossibleChoices;
	nonCoteKnowledge?: KnowledgePossibleChoices;
	coteKnowledge?: KnowledgePossibleChoices;
	produitStrucutureKnowledge?: KnowledgePossibleChoices;
	obligation?: KnowledgePossibleChoices;
	scpi?: KnowledgePossibleChoices;
	opc?: KnowledgePossibleChoices;
	opci?: KnowledgePossibleChoices;
	trackers?: KnowledgePossibleChoices;
	fondEuro?: KnowledgePossibleChoices;
	crowdfunding?: KnowledgePossibleChoices;

	averageAmount?: number;

	lastOperation?: LastOperationsChoices;

	riskPerceptionScpi?: RiskPerception;
	lengthPerceptionScpi?: LengthPerception;
	scpiSignedAt?: string;

	riskPerceptionPe?: RiskPerception;
	lengthPerceptionPe?: LengthPerception;
	peSignedAt?: string;

	riskPerceptionCwdfd?: RiskPerception;
	lengthPerceptionCwdfd?: LengthPerception;
	cwdfdSignedAt?: string;

	riskPerceptionArt?: RiskPerception;
	lengthPerceptionArt?: LengthPerception;
	artSignedAt?: string;
}
