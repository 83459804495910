import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { Icon } from '@chakra-ui/react';

type YesNoProps = {
	isTrue: boolean | null | undefined;
};

const YesNoIcon = ({ isTrue }: YesNoProps) => (
	<Icon as={isTrue ? CheckIcon : CloseIcon} color={isTrue ? 'green.500' : 'red.500'} />
);

export default YesNoIcon;
