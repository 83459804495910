import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbLink,
	Button,
	Center,
	Heading,
	HStack,
	Input,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Select,
	Skeleton,
	Text,
	useDisclosure,
	VStack,
} from '@chakra-ui/react';

import AlertDialog from 'components/AlertDialog';
import Cardlayout from 'components/CardLayout';
import { CommentsSection, useOpsComments } from 'components/Comment';
import HistorySection from 'components/History';
import CategoriesTable from 'components/tables/CategoriesTable';
import { ChangeDealOverdueDate } from 'features/ChangeOverdueDate';
import DocumentsList from 'features/DocumentsList';
import MailFollowUpSection from 'features/mailing-framework/MailFollowUpSection';
import useAssignedOps, { NO_ASSIGNED_OPS, SelectAssignedOps } from 'hooks/useAssignedOps';
import useDealStatus from 'hooks/useDealStatus';
import useFund from 'hooks/useFund';
import { useNextStatusModal } from 'hooks/useNextStatusModal';
import useThemedToast from 'hooks/useThemedToast';
import useGetUserData from 'pages/ops/super/subscription/useDisplayUserInfo';
import { useGetClientKYCQuery } from 'services/client';
import {
	AllDeal,
	useChurnDealMutation,
	useDeleteDealMutation,
	useGetDealByIdQuery,
	useUpdateDealMutation,
} from 'services/deal';
import { useGetStatusesQuery } from 'services/ops/status';
import { useGetTransferByIdQuery } from 'services/ops/transfer';
import { ProductType, SubscriptionStatus } from 'types/global.type';
import { isNone, isNotNone } from 'utils/functions';
import { PermissionDisplayGuard } from 'utils/guards';
import { BoPermission } from 'utils/permissions';

const SelectStatus: FC<{
	status: SubscriptionStatus | undefined;
	deal: AllDeal;
	isLoading: boolean;
	onChangeStatus: (v: SubscriptionStatus) => void;
}> = ({ onChangeStatus, deal, status, isLoading }) => {
	const { dealStatusOptions } = useDealStatus(deal);

	return (
		<VStack align="start" w="100%">
			<Text>Statut</Text>
			<Select
				isDisabled={isLoading || deal.productType === ProductType.INVEST}
				value={status}
				onChange={(e) => onChangeStatus(e.target.value as SubscriptionStatus)}
			>
				{dealStatusOptions.map((s) => (
					<option key={s} value={s}>
						{s}
					</option>
				))}
			</Select>
		</VStack>
	);
};

const useFindDealOrTransfer = (productType: ProductType | 'TRANSFER') => {
	const { id } = useParams<{ id: string }>();

	const { data: possibleSubscription, isFetching: isSubscriptionFetching } = useGetDealByIdQuery(
		{ id: id ?? '', productType: productType as ProductType },
		{ skip: !isNotNone(id) || !isNotNone(productType) || productType === 'TRANSFER' },
	);
	const { data: possibleTransfer, isFetching: isTransferFetching } = useGetTransferByIdQuery(
		{ id: id ?? '' },
		{ skip: !isNotNone(id) || !isNotNone(productType) || productType !== 'TRANSFER' },
	);

	return useMemo(
		() => ({
			data: (possibleSubscription ?? possibleTransfer ?? undefined) as AllDeal | undefined,
			isFetching: isSubscriptionFetching || isTransferFetching,
		}),
		[isSubscriptionFetching, isTransferFetching, possibleSubscription, possibleTransfer],
	);
};

export const DealDetails = () => {
	const location = useLocation();
	const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
	const productType = useMemo(() => queryParams.get('productType') as ProductType | 'TRANSFER', [queryParams]);
	const { data: subscription } = useFindDealOrTransfer(productType);
	const categoriesData = useGetUserData({ email: subscription?.user.email, subscription });

	const toast = useThemedToast();
	const navigate = useNavigate();
	const { id } = useParams<{ id: string }>();

	const { data: deal, isFetching: isDealFetching } = useGetDealByIdQuery(
		{ id: id ?? '', productType: (queryParams.get('productType') as ProductType) ?? ProductType.INVEST },
		{ skip: !isNotNone(id) },
	);
	const { data: kyc } = useGetClientKYCQuery({ email: deal?.user.email ?? '' }, { skip: !isNotNone(deal) });
	const { data: statuses } = useGetStatusesQuery();

	const { isOpen: isChurnDialogOpen, onOpen: openChurnDialog, onClose: closeChurnDialog } = useDisclosure();
	const { isOpen: isDeleteDialogOpen, onOpen: openDeleteDialog, onClose: closeDeleteDialog } = useDisclosure();

	const [updateDeal, { isLoading: isUpdateLoading }] = useUpdateDealMutation();
	const [deleteDeal, { isLoading: isDeleteLoading }] = useDeleteDealMutation();
	const [churnDeal, { isLoading: isChurnLoading }] = useChurnDealMutation();

	const { NextStatusModal, handleOpenNextStatusModal } = useNextStatusModal();

	const comments = useOpsComments();
	const { assignedOps, onChangeAssignedOps } = useAssignedOps(NO_ASSIGNED_OPS);
	const fund = useFund(
		deal,
		!!deal && (deal?.status === SubscriptionStatus.REQUESTED || deal?.status === SubscriptionStatus.PENDING),
	);

	const [churnReason, setChurnReason] = useState<'churn' | 'expiration'>('churn');
	const [partnerEmail, setParterEmail] = useState<string>('');
	const [status, setStatus] = useState<SubscriptionStatus>();
	const [hubspotId, setHubspotId] = useState<string>('');
	const [externalId, setExternalId] = useState<string>('');

	const existingExternalId = useMemo<string>(() => {
		if (deal && 'externalReference' in deal) return deal.externalReference ?? '';
		if (deal && 'numContratApicil' in deal) return deal.numContratApicil ?? '';
		if (deal && 'apicilSubscriptionId' in deal) return deal.apicilSubscriptionId ?? '';
		if (deal && 'externalAccountId' in deal) return deal.externalAccountId ?? '';
		return '';
	}, [deal]);

	const opsProperties = useMemo(() => deal?.opsProperties, [deal?.opsProperties]);

	const dirtyFields = useMemo(() => {
		const existingHubspotId = deal && 'hubspotId' in deal && deal?.hubspotId ? deal.hubspotId : undefined;
		const isHubspotIdDirty = hubspotId !== existingHubspotId && hubspotId.length > 0;
		const isExternalIdDirty = externalId !== existingExternalId && externalId.length > 0;
		const isStatusDirty = status !== deal?.status;
		const isAssignedOpsDirty = opsProperties?.assignedOpsEmail !== assignedOps && assignedOps !== NO_ASSIGNED_OPS;
		return { isStatusDirty, isHubspotIdDirty, isAssignedOpsDirty, isExternalIdDirty };
	}, [assignedOps, deal, existingExternalId, externalId, hubspotId, opsProperties?.assignedOpsEmail, status]);

	useEffect(() => {
		if (!deal || !fund) return;
		setParterEmail(fund['Email - MO/BO'] ?? '');
	}, [deal, fund]);

	useEffect(() => {
		if (deal?.status) setStatus(deal.status);
		if (deal && 'hubspotId' in deal && deal?.hubspotId) setHubspotId(deal.hubspotId);
		if (existingExternalId) setExternalId(existingExternalId);
		if (isNotNone(opsProperties?.assignedOpsEmail)) onChangeAssignedOps(opsProperties?.assignedOpsEmail);
	}, [deal, existingExternalId, onChangeAssignedOps, opsProperties?.assignedOpsEmail]);

	const handleUpdate = useCallback(() => {
		if (dirtyFields.isStatusDirty && deal!.productType === ProductType.INVEST && deal!.dealType === 'CONTRACT') {
			toast({ status: 'error', title: "Impossible de mettre à jour le statut d'un contrat invest ouvert" });
			return;
		}
		updateDeal({
			id: deal!.id,
			status: dirtyFields.isStatusDirty ? status : undefined,
			hubspotId: dirtyFields.isHubspotIdDirty ? hubspotId : undefined,
			externalId: dirtyFields.isExternalIdDirty ? externalId : undefined,
			productType: deal!.productType,
			properties: {
				id: deal!.opsPropertiesId,
				assignedOpsEmail: !dirtyFields.isAssignedOpsDirty || assignedOps === NO_ASSIGNED_OPS ? undefined : assignedOps,
			},
		})
			.unwrap()
			.then(() => {
				setStatus(undefined);
				toast({ status: 'success', title: 'Statut du deal mise à jour avec succès' });
			})
			.catch((err) => toast({ status: 'error', title: 'Erreur', description: err.data.message }));
	}, [assignedOps, deal, dirtyFields, externalId, hubspotId, status, toast, updateDeal]);

	const handleChurn = () => {
		churnDeal({ id: deal!.id, productType: deal!.productType, churnReason })
			.unwrap()
			.then(() => {
				closeChurnDialog();
				toast({ status: 'success', title: 'Deal churn avec succès' });
			})
			.catch((err) => toast({ status: 'error', title: 'Erreur', description: err.data.message }));
	};

	const handleDelete = () => {
		deleteDeal({
			id: deal!.id,
			productType: deal!.productType,
			opsPropertiesId: deal!.opsPropertiesId,
			partnerEmail: partnerEmail.length > 0 ? partnerEmail : undefined,
		})
			.unwrap()
			.then(() => {
				setParterEmail('');
				closeDeleteDialog();
				navigate('..');
				toast({ status: 'success', title: 'Deal supprimé avec succès' });
			})
			.catch((err) => toast({ status: 'error', title: 'Erreur', description: err.data.message }));
	};

	const handleSendComment = useCallback(() => {
		// If the transfer has no opsPropertiesId, we need to generate it before sending the comment
		if (!deal?.opsPropertiesId) {
			updateDeal({
				id: deal!.id,
				productType: deal!.productType,
				properties: { id: deal!.opsPropertiesId, comment: comments.comment },
			});
			comments.onChangeComment('');
		} else comments.onCreateComment(deal?.opsPropertiesId);
	}, [comments, deal, updateDeal]);

	if (isDealFetching) return <Skeleton h="100%" w="100%" />;
	if (isNone(deal))
		return (
			<Center>
				<Heading size="md">Deal non trouvé</Heading>
			</Center>
		);

	return (
		<VStack w="100%" spacing="12px" align="start" pb="32px">
			<NextStatusModal />

			<Breadcrumb spacing="8px" separator={<ChevronRightIcon color="gray.500" />}>
				<BreadcrumbItem>
					<BreadcrumbLink onClick={() => navigate('..')}>Deals</BreadcrumbLink>
				</BreadcrumbItem>

				<BreadcrumbItem>
					<BreadcrumbLink>{deal.user.email}</BreadcrumbLink>
				</BreadcrumbItem>
			</Breadcrumb>

			<HStack w="100%" align="start" justify="space-between">
				<Heading size="lg">
					{kyc?.kyc?.firstName} {kyc?.kyc?.lastName} - {deal.productType}
				</Heading>

				<HStack align="start">
					{(deal.status !== SubscriptionStatus.COMPLETED ||
						deal.productType === ProductType.INVEST ||
						deal.productType === ProductType.CASH) && (
						<Button colorScheme="blue" onClick={() => handleOpenNextStatusModal(deal)}>
							Passer au statut suivant
						</Button>
					)}
					<Button
						isDisabled={Object.values(dirtyFields).every((i) => !i)}
						colorScheme="blue"
						onClick={handleUpdate}
						isLoading={isUpdateLoading}
					>
						Enregister les modifications
					</Button>
					<Menu closeOnSelect={false}>
						<MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
							Actions
						</MenuButton>
						<MenuList>
							<MenuItem onClick={() => navigate(`/ops/super/client/${deal.user.id}`)}>Page client</MenuItem>
							<PermissionDisplayGuard permission={BoPermission.BLOCKING_INSTANCE_DELETE}>
								<MenuItem
									color="red.600"
									onClick={deal.status === SubscriptionStatus.COMPLETED ? openChurnDialog : openDeleteDialog}
								>
									{deal.status === SubscriptionStatus.COMPLETED ? 'Churn le deal' : 'Supprimer le deal'}
								</MenuItem>
							</PermissionDisplayGuard>
						</MenuList>
					</Menu>
				</HStack>
			</HStack>

			<HStack w="100%" align="start" spacing="12px" justify="space-between">
				<VStack spacing="12px" align="start" flex={3}>
					<CategoriesTable categoriesData={categoriesData} title="Récapitulatif" />

					<DocumentsList context="deal" email={deal.user.email} subscription={deal} />

					<MailFollowUpSection conversationId={opsProperties?.mailingFrameworkConversation?.id} />

					<CommentsSection
						comment={comments.comment}
						commentList={opsProperties?.comments}
						onCreateComment={handleSendComment}
						onChangeComment={comments.onChangeComment}
						onDeleteComment={comments.onDeleteComment}
						isLoading={isUpdateLoading}
					/>

					<HistorySection opsProperties={opsProperties} />
				</VStack>

				<VStack spacing="12px" align="start" flex={1}>
					<Cardlayout title="Mettre à jour">
						<VStack align="start" w="100%" spacing="12px">
							<SelectAssignedOps
								isLoading={isUpdateLoading}
								allowNone
								assignedOps={assignedOps}
								onChangeAssignedOps={onChangeAssignedOps}
							/>
							<SelectStatus isLoading={isUpdateLoading} status={status} deal={deal} onChangeStatus={setStatus} />
							<VStack align="start" w="100%">
								<Text>HubspotId</Text>
								<Input isDisabled={isUpdateLoading} value={hubspotId} onChange={(e) => setHubspotId(e.target.value)} />
							</VStack>
							<VStack align="start" w="100%">
								<Text>Id partenaire</Text>
								<Input
									isDisabled={
										isUpdateLoading ||
										![ProductType.INVEST, ProductType.CASH, ProductType.PE].includes(deal.productType) // other products don't have external ids
									}
									value={externalId}
									onChange={(e) => setExternalId(e.target.value)}
								/>
							</VStack>
						</VStack>
					</Cardlayout>

					{statuses && ![SubscriptionStatus.COMPLETED, SubscriptionStatus.REQUESTED].includes(deal.status) && (
						<Cardlayout title="Deadline">
							<ChangeDealOverdueDate overdueItem={deal} statuses={statuses} />
						</Cardlayout>
					)}
				</VStack>
			</HStack>

			<AlertDialog
				isOpen={isChurnDialogOpen}
				onClose={closeChurnDialog}
				header="Churn le deal"
				body={
					<VStack spacing="16px" align="start">
						<Text>Vous êtes sur le point de churn ce deal. Merci de saisir la raison.</Text>
						<Select value={churnReason} onChange={(e) => setChurnReason(e.target.value as 'churn' | 'expiration')}>
							<option value="churn">Churn</option>
							<option value="expiration">Expiration</option>
						</Select>
					</VStack>
				}
				footer={
					<>
						<Button onClick={closeChurnDialog}>Annuler</Button>
						<Button colorScheme="red" ml={3} isLoading={isChurnLoading} onClick={handleChurn}>
							Valider
						</Button>
					</>
				}
			/>

			<AlertDialog
				isOpen={isDeleteDialogOpen}
				onClose={closeDeleteDialog}
				header="Supprimer le deal"
				body={
					<VStack spacing="16px" align="start">
						<Text>Vous êtes sur le point de supprimer ce deal.</Text>
						{deal.status !== SubscriptionStatus.REQUESTED && deal.status !== SubscriptionStatus.PENDING && (
							<>
								<Text>Email du partenaire:</Text>
								<Input
									value={partnerEmail}
									onChange={(e) => setParterEmail(e.target.value)}
									placeholder="Email du partenaire"
								/>
							</>
						)}
					</VStack>
				}
				footer={
					<>
						<Button onClick={closeDeleteDialog}>Annuler</Button>
						<Button colorScheme="red" ml={3} isLoading={isDeleteLoading} onClick={handleDelete}>
							Valider
						</Button>
					</>
				}
			/>
		</VStack>
	);
};
