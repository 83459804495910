import { FC, useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon, SearchIcon } from '@chakra-ui/icons';
import {
	Box,
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbLink,
	Divider,
	HStack,
	Input,
	InputGroup,
	InputLeftElement,
	Switch,
	Text,
	VStack,
} from '@chakra-ui/react';

import Cardlayout from 'components/CardLayout';

import SubCategorySection from './SubCategorySection';

export type SubCategoryValue =
	| {
			key?: string;
			value?: string | boolean | number | Date | null | undefined | object;
	  }
	| {
			title: string;
	  };

export type CategoryType = Record<string, SubCategoryValue[]>;

const CategoriesTable: FC<{ categoriesData: CategoryType; title: string }> = ({ categoriesData, title }) => {
	const [currentCategory, setCurrentCategory] = useState<SubCategoryValue[] | null>(null);
	const [selectedCategoryName, setSelectedCategoryName] = useState<string | null>(null);
	const [searchTerm, setSearchTerm] = useState<string>('');
	const [hideEmpty, setHideempty] = useState<boolean>(false);

	const handleCategoryClick = (categoryName: string, values: SubCategoryValue[]) => {
		setCurrentCategory(values);
		setSelectedCategoryName(categoryName);
	};

	const filterCategories = (data: CategoryType, term: string): SubCategoryValue[] => {
		if (!term) return [];

		const lowerCaseSearchTerm = term.toLowerCase();

		return Object.values(data)
			.flat()
			.filter((value) => 'key' in value && value?.key && value.key.toLowerCase().includes(lowerCaseSearchTerm));
	};

	const filteredCategories = filterCategories(categoriesData, searchTerm);

	return (
		<Cardlayout title={title} defaultIsOpen={false}>
			<VStack w="100%" spacing="16px">
				<HStack w="100%">
					{currentCategory ? (
						<HStack w="100%" justify="space-between">
							<HStack>
								<ChevronLeftIcon
									boxSize="32px"
									onClick={() => {
										setCurrentCategory(null);
										setSelectedCategoryName(null);
									}}
									cursor="pointer"
								/>
								<Breadcrumb>
									<BreadcrumbItem>
										<BreadcrumbLink
											onClick={() => {
												setCurrentCategory(null);
												setSelectedCategoryName(null);
											}}
											cursor="pointer"
										>
											{selectedCategoryName}
										</BreadcrumbLink>
									</BreadcrumbItem>
								</Breadcrumb>
							</HStack>
							<Switch size="sm" colorScheme="teal" isChecked={hideEmpty} onChange={() => setHideempty(!hideEmpty)}>
								Champs vides
								{hideEmpty}
							</Switch>
						</HStack>
					) : (
						<Box w="100%">
							<InputGroup>
								<InputLeftElement pointerEvents="none">
									<SearchIcon color="gray.300" />
								</InputLeftElement>
								<Input
									placeholder="Rechercher une information"
									value={searchTerm}
									onChange={(e) => setSearchTerm(e.target.value)}
									focusBorderColor="gray.400"
								/>
							</InputGroup>
						</Box>
					)}
				</HStack>

				<VStack w="100%" spacing="0px">
					{searchTerm && filteredCategories.length > 0 ? (
						<SubCategorySection values={filteredCategories} />
					) : !currentCategory ? (
						Object.entries(categoriesData).map(([category, values]) => (
							<VStack key={category} w="100%" spacing="0px">
								<HStack
									w="100%"
									_hover={{ borderRadius: '4px', bg: '#F3F4F6' }}
									p="12px"
									onClick={() => handleCategoryClick(category, values)}
									cursor="pointer"
								>
									<Text flex="1" textAlign="left">
										{category}
									</Text>
									<ChevronRightIcon boxSize="32px" />
								</HStack>
								<Divider orientation="horizontal" />
							</VStack>
						))
					) : null}
				</VStack>
				{currentCategory && <SubCategorySection values={currentCategory} hideEmpty={hideEmpty} />}
			</VStack>
		</Cardlayout>
	);
};

export default CategoriesTable;
