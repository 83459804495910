import { useState } from 'react';
import { Button, Heading, HStack, Input, VStack } from '@chakra-ui/react';

import useThemedToast from 'hooks/useThemedToast';
import { useLazyGetAutologinTokenQuery } from 'services/client';
import { ramifyStacks } from 'utils/constants';
import { sleep } from 'utils/functions';

const Autologin = () => {
	const toast = useThemedToast();

	const [email, setEmail] = useState<string>('');
	const [getAutologin] = useLazyGetAutologinTokenQuery();

	const handleAutologin = async () => {
		getAutologin({ email })
			.unwrap()
			.then(async (res) => {
				toast({ status: 'success', title: 'Utilisateur trouvé' });
				await sleep(1000);
				window.open(`${ramifyStacks.ramifyAppUrl.toString()}autologin?autologinToken=${res.token}`, '_blank');
			})
			.catch((err) => toast({ status: 'error', title: 'Erreur', description: err.data.message }));
	};

	return (
		<VStack align="start" spacing="32px">
			<Heading size="md">Connexion automatique sur le compte d'un client</Heading>
			<HStack w="100%">
				<Input
					value={email}
					onChange={(e) => setEmail(e.target.value.trim())}
					onKeyDown={(e) => (e.key === 'Enter' ? handleAutologin() : undefined)}
					placeholder="Email"
					w="500px"
				/>
				<Button colorScheme="blue" onClick={() => handleAutologin()} isDisabled={!email}>
					Valider
				</Button>
			</HStack>
		</VStack>
	);
};

export default Autologin;
