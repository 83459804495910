import { CivilStatus } from 'types/referentiels/civilStatus';

import { TaxType } from './scpiKyc.type';

export enum MoralPersonStatus {
	FREELANCE = 'FREELANCE',
	COMPANY = 'COMPANY',
	NON_LUCRATIVE_ORGANISME = 'NON_LUCRATIVE_ORGANISME',
}

export enum MoralPersonJuridicForm {
	SA = 'SA',
	SAS = 'SAS',
	SARL = 'SARL',
	INSURANCE = 'INSURANCE',
	OTHER = 'OTHER',
}

export type MoralPersonFunction =
	| 'LIBERAL'
	| 'TRADER'
	| 'CRAFTSMAN'
	| 'MANAGER'
	| 'GENERALDIRECTOR'
	| 'PRESIDENT'
	| 'VICEPRESIDENT'
	| 'FINANCIALDIRECTOR'
	| 'TREASURER'
	| 'ACCOUNTANT';

export type MoralPersonFundOriginDocumentType =
	| 'LAST_ACCOUNT_STATEMENT'
	| 'NOTARIZED_CERTIFICATE'
	| 'ACT_OF_CESSION'
	| 'ARGUMENTARY'
	| 'CLIENT_PRESENTATION'
	| 'OTHER';

export enum MoralPersonFundOrigin {
	IMMOBILISATION_CESSION = 'IMMOBILISATION_CESSION',
	EXCEDENT_TRESORERIE_EXPLOITATION = 'EXCEDENT_TRESORERIE_EXPLOITATION',
	OTHER = 'OTHER',
}

export const FundOriginOptions = Object.entries({
	IMMOBILISATION_CESSION: "Cession d'une immobilisation",
	EXCEDENT_TRESORERIE_EXPLOITATION: "Excédent de trésorerie d'exploitation",
	OTHER: 'Autre (précisez)',
} as Record<MoralPersonFundOrigin, string>) as [MoralPersonFundOrigin, string][];

export const FundOriginDocumentTypeOptions = Object.entries({
	LAST_ACCOUNT_STATEMENT: 'Dernier relevé de compte',
	NOTARIZED_CERTIFICATE: 'Attestation notariée',
	ACT_OF_CESSION: 'Acte de cession',
	ARGUMENTARY: 'Argumentaire',
	CLIENT_PRESENTATION: "Présentation du client et de l'opération",
	OTHER: 'Autre justificatif',
} as Record<MoralPersonFundOriginDocumentType, string>) as [MoralPersonFundOriginDocumentType, string][];

export type MoralPersonNonFinancialEntityType =
	| 'ENF_ACTIVE'
	| 'LISTED_COMPANY'
	| 'PUBLIC_ENTITY'
	| 'INTL_ORG'
	| 'BANK'
	| 'PASSIVE';

export type MoralPerson = {
	id: string;
	userId: string;
	createdAt?: Date;
	updatedAt?: Date;

	status: MoralPersonStatus | null;
	naf: string | null;
	siren: string | null;
	denomination: string | null;
	activity: string | null;
	constitutionDate: string; // date
	juridicForm: MoralPersonJuridicForm | null;
	legalRepresentativeFunction: MoralPersonFunction | null;
	legalRepresentativeEntryDate: string; // date
	taxType: TaxType | null;
	nonFinancialEntityType: MoralPersonNonFinancialEntityType | null;
	siegeAddress: string | null;
	siegeAddressDetails: string | null;
	siegeCity: string | null;
	siegeCityCode: string | null;
	correspondanceAddress: string | null;
	correspondanceAddressDetails: string | null;
	correspondanceCity: string | null;
	correspondanceCityCode: string | null;
	registrationCity: string | null;
	hasTurnoverFromExportation: boolean | null;
	exportationCountries: string[];
	immatriculationCountry: string | null;

	fundOriginDocument: MoralPersonFundOriginDocumentType | null;
	fundOrigin: MoralPersonFundOrigin | null;
	fundOriginDetails: string | null;

	turnover?: number; // Chiffre d'affaires
	netIncome?: number; // Résultat net
	treasury?: number; // Disponibilités
};

export type MoralPersonBeneficiary = {
	id: string;
	moralPersonId: string;

	civilStatus: CivilStatus;
	lastName: string;
	firstName: string;
	birthName: string;

	birthDate: string;
	birthCity: string;
	birthCityCode: string;
	birthCountry: string;
	citizenship: string;
	detentionPercentage: number;
	nif: string;
	isUSPerson: boolean;
};

export type PopulatedMoralPerson = MoralPerson & {
	moralPersonBeneficiaries: MoralPersonBeneficiary[];
};
