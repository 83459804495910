import { FC, useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Box, HStack, Input, Table, TableContainer, Tbody, Text, Thead, Tr, VStack } from '@chakra-ui/react';

import { DefaultTd } from 'components/tables/columns/Td';
import { DefaultTh } from 'components/tables/columns/Th';
import { UserWithKycAndMoral } from 'services/client';

type ClientTableProps = {
	clients: UserWithKycAndMoral[];
	selectedClient: UserWithKycAndMoral | null;
	setSelectedClient: (c: UserWithKycAndMoral) => void;
	onEmailFilter?: (email: string) => void;
	pageSize?: number;
};

const SimpleClientsTable: FC<ClientTableProps> = ({
	clients,
	selectedClient,
	setSelectedClient,
	onEmailFilter,
	pageSize = 10,
}) => {
	const [pageIndex, setPageIndex] = useState<number>(0);

	return (
		<VStack w="100%" spacing="16px" align="start">
			{onEmailFilter && (
				<HStack w="100%" spacing="24px">
					<Text whiteSpace="nowrap">Cherche par email :</Text>
					<Input onChange={(e) => onEmailFilter(e.target.value)} placeholder="Email" w="100%" />
				</HStack>
			)}

			<TableContainer w="100%">
				<Table variant="simple" size="sm">
					<Thead>
						<Tr>
							<DefaultTh>Email</DefaultTh>
							<DefaultTh>Nom</DefaultTh>
							<DefaultTh>Prénom</DefaultTh>
						</Tr>
					</Thead>

					<Tbody>
						{clients.slice(pageIndex * pageSize, pageIndex * pageSize + pageSize).map((client) => (
							<Tr
								key={client.id}
								onClick={() => setSelectedClient(client)}
								cursor="pointer"
								bg={selectedClient?.id === client.id ? 'blue.50' : 'white'}
							>
								<DefaultTd>{client.email}</DefaultTd>
								<DefaultTd>{client.kyc?.lastName}</DefaultTd>
								<DefaultTd>{client.kyc?.firstName}</DefaultTd>
							</Tr>
						))}
					</Tbody>
				</Table>
			</TableContainer>

			<Box w="100%" textAlign="right" mb="16px" fontWeight="semibold" userSelect="none">
				<Text as="span" color="gray.600">
					Page {pageIndex + 1} / {Math.ceil(clients.length / pageSize)}
				</Text>
				<ChevronLeftIcon
					mx="16px"
					boxSize="24px"
					bg="gray.100"
					cursor="pointer"
					onClick={() => setPageIndex(pageIndex === 0 ? 0 : pageIndex - 1)}
				/>
				<ChevronRightIcon
					boxSize="24px"
					bg="gray.100"
					cursor="pointer"
					onClick={() =>
						setPageIndex(pageIndex === Math.ceil(clients.length / pageSize) - 1 ? pageIndex : pageIndex + 1)
					}
				/>
			</Box>
		</VStack>
	);
};

export default SimpleClientsTable;
