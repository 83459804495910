import { dashboardApi } from 'store/api';

export type GoogleCalendarEvent = {
	id: string;
	summary: string;
	hangoutLink: string;
	description: string;
	start: {
		dateTime: string;
		timeZone: string;
	};
	end: {
		dateTime: string;
		timeZone: string;
	};
	attendees: Array<{ email: string }>;
};

interface CalendarEvent {
	external_id: string;
	kind: string;
}

interface EventMembership {
	user: string;
	user_email: string;
	user_name: string;
}

interface InviteesCounter {
	active: number;
	limit: number;
	total: number;
}

interface Location {
	join_url: string;
	status: string;
	type: string;
}

export interface ScheduledEvent {
	resource: {
		calendar_event: CalendarEvent;
		created_at: string;
		end_time: string;
		event_guests: unknown[]; // Assuming these can be empty
		event_memberships: EventMembership[];
		event_type: string;
		invitees_counter: InviteesCounter;
		location: Location;
		meeting_notes_html: string | null;
		meeting_notes_plain: string | null;
		name: string;
		start_time: string;
		status: string;
		updated_at: string;
		uri: string;
	};
}

interface QuestionAndAnswer {
	answer: string;
	position: number;
	question: string;
}

interface Tracking {
	utm_campaign: string | null;
	utm_source: string | null;
	utm_medium: string | null;
	utm_content: string | null;
	utm_term: string | null;
	salesforce_uuid: string | null;
}

interface Invitee {
	cancel_url: string;
	created_at: string;
	email: string;
	event: string;
	first_name: string;
	invitee_scheduled_by: unknown | null;
	last_name: string;
	name: string;
	new_invitee: unknown | null;
	no_show: unknown | null;
	old_invitee: unknown | null;
	payment: unknown | null;
	questions_and_answers: QuestionAndAnswer[];
	reconfirmation: unknown | null;
	reschedule_url: string;
	rescheduled: boolean;
	routing_form_submission: unknown | null;
	scheduling_method: unknown | null;
	status: string;
	text_reminder_number: unknown | null;
	timezone: string;
	tracking: Tracking;
	updated_at: string;
	uri: string;
}

interface Pagination {
	count: number;
	next_page: unknown | null;
	next_page_token: unknown | null;
	previous_page: unknown | null;
	previous_page_token: unknown | null;
}

export interface ScheduledEventInvitiee {
	collection: Invitee[];
	pagination: Pagination;
}

const endpoints = dashboardApi.injectEndpoints({
	endpoints: (builder) => ({
		getCalendar: builder.query<GoogleCalendarEvent[], 'day' | 'week' | 'month'>({
			query: (timePeriod) => ({
				url: `/bo/cpm/calendar/${timePeriod}`,
				method: 'GET',
			}),
		}),
		getCalendlyEvent: builder.query<ScheduledEvent & { invitees: ScheduledEventInvitiee }, string>({
			query: (eventId) => ({
				url: `/bo/cpm/calendar/calendly/${eventId}`,
				method: 'GET',
			}),
		}),
	}),
});

export const { useGetCalendarQuery, useLazyGetCalendlyEventQuery } = endpoints;
