import { from } from 'env-var';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const fakeToken = 'azertyuiop';

const publicEnv = {
	VITE_DASHBOARD_BACK_URL: import.meta.env.VITE_DASHBOARD_BACK_URL,
	VITE_LAB_BACK_URL: import.meta.env.VITE_LAB_BACK_URL,
	VITE_GOOGLE_CLIENT_ID: import.meta.env.VITE_GOOGLE_CLIENT_ID,
	VITE_APP_RAMIFY_URL: import.meta.env.VITE_APP_RAMIFY_URL,
	VITE_FRONTEGG_BASE_URL: import.meta.env.VITE_FRONTEGG_BASE_URL,
};

const env = from(publicEnv, {});

//
// STACKS RAMIFY ///////////////////////////////////////////////////////////////////////////////////////////////////////
//

export const ramifyStacks = {
	dashboardBackUrl: env.get('VITE_DASHBOARD_BACK_URL').required().asUrlObject(),
	labBackUrl: env.get('VITE_LAB_BACK_URL').required().asUrlObject(),
	ramifyAppUrl: env.get('VITE_APP_RAMIFY_URL').required().asUrlObject(),
};

export const fronteggConstants = {
	baseUrl: env.get('VITE_FRONTEGG_BASE_URL').required().asString(),
};
