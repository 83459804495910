import { FC, useCallback, useState } from 'react';
import { Button, Input, Text, Textarea, VStack } from '@chakra-ui/react';

import useThemedToast from 'hooks/useThemedToast';
import { BlockingInstanceWithDoc, useReviewBlockingInstancesMutation } from 'services/ops/blocking-instance';
import { isNone } from 'utils/functions';

type BlockingInstanceNoDocsReviewProps = {
	blockingInstance: BlockingInstanceWithDoc;
};

const BlockingInstanceNoDocsReview: FC<BlockingInstanceNoDocsReviewProps> = ({ blockingInstance }) => {
	const toast = useThemedToast();

	const [message, setMessage] = useState('');
	const [partnerEmail, setPartnerEmail] = useState<string>('');

	const [reviewBi, { isLoading }] = useReviewBlockingInstancesMutation();

	const handleReview = useCallback(async () => {
		reviewBi({
			id: blockingInstance.id,
			message,
			partnerEmail: isNone(blockingInstance.mailId) ? partnerEmail : undefined,
			isValid: true,
			noMissingDocuments: true,
		})
			.unwrap()
			.then(() => toast({ status: 'success', title: 'Instance bloquante validée avec succès' }))
			.catch((err) => toast({ status: 'error', title: 'Erreur', description: err.data.message }));
	}, [blockingInstance.id, blockingInstance.mailId, message, partnerEmail, reviewBi, toast]);

	return (
		<VStack align="start" w="100%" spacing="16px">
			{isNone(blockingInstance.mailId) && (
				<VStack align="start" w="100%">
					<Text variant="Text-M-Medium">Email du partenaire</Text>
					<Input value={partnerEmail} onChange={(e) => setPartnerEmail(e.target.value)} />
				</VStack>
			)}
			<VStack align="start" w="100%">
				<Text>Message pour le partenaire</Text>
				<Textarea minH="160px" value={message} onChange={(e) => setMessage(e.target.value)} />
			</VStack>

			<Button
				onClick={handleReview}
				alignSelf="end"
				colorScheme="blue"
				isLoading={isLoading}
				isDisabled={isNone(blockingInstance.mailId) && !partnerEmail.length}
			>
				Envoyer au partenaire
			</Button>
		</VStack>
	);
};

export default BlockingInstanceNoDocsReview;
