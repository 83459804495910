import { ProductType } from 'types/global.type';
import { PortfolioType } from 'types/investmentPreferences.type';
import { MailingFrameworkConversation } from 'types/mailing-framework.type';
import { MoralPerson } from 'types/moralPerson.type';
import { User } from 'types/user.type';

export enum PropaleType {
	AUTO = 'AUTO',
	MANUAL = 'MANUAL',
	FOLLOW_UP = 'FOLLOW_UP',
	NO_SHOW = 'NO_SHOW',
}

export enum PropaleStatus {
	TODO = 'TODO',
	SENT = 'SENT',
	DONE = 'DONE',
	DEAD = 'DEAD',
}

export enum LeadClosingProbability {
	LOW = 'LOW',
	MEDIUM = 'MEDIUM',
	HIGH = 'HIGH',
}

export type CpmPropale = {
	id: string;
	createdAt: string;
	updatedAt: string;

	user?: User & { moralPersons: MoralPerson[] };
	userId?: string;
	assignedCpmEmail?: string;
	creatorCpmEmail: string;

	firstName?: string;
	lastName?: string;
	email: string;

	googleCalendarId?: string;

	type: PropaleType;
	amount: number;
	notes: string;
	notionLink?: string;
	subject?: string;
	deadline?: string;
	investPortfolio?: PortfolioType;
	investRisk?: number;
	investGreen?: boolean;

	leadClosingProbability?: LeadClosingProbability;

	status: PropaleStatus;
	todoAt?: string;
	sentAt?: string;
	doneAt?: string;
	deadAt?: string;

	linkedEntities: CpmLinkedEntities[];
	comments: CpmComment[];

	conversationPostCall?: MailingFrameworkConversation;
	conversationPropale?: MailingFrameworkConversation;
};

export type CpmLinkedEntities = {
	id: string;
	product: ProductType;

	createdAt: string;

	propale?: CpmPropale;
	propaleId?: string;
};

export type CpmComment = {
	id: string;
	createdAt: string;
	updatedAt: string;

	content: string;

	propale: CpmPropale;
	propaleId: string;
	authorEmail?: string;
};
