import { dashboardApi } from 'store/api';

const endpoints = dashboardApi.injectEndpoints({
	endpoints: (builder) => ({
		getScpiPeCashback: builder.query<string, { month: string; year: string }>({
			query: (queryParams) => ({
				url: '/bo/ops/cashback/pe-scpi',
				params: queryParams,
				method: 'GET',
				responseHandler: 'content-type',
				cache: 'no-cache',
			}),
		}),

		getScpiCashback: builder.query<string, { month: string; year: string }>({
			query: (queryParams) => ({
				url: '/bo/ops/cashback/scpi',
				params: queryParams,
				method: 'GET',
				responseHandler: 'content-type',
				cache: 'no-cache',
			}),
		}),

		getInvestCashback: builder.query<string, { dateComputeStart: string; dateComputeEnd: string }>({
			query: (queryParams) => ({
				url: '/bo/ops/cashback/invest/legacy-affiliation',
				params: queryParams,
				method: 'GET',
				responseHandler: 'content-type',
				cache: 'no-cache',
			}),
		}),

		getInvestAffiliationCashback: builder.query<string, { dateComputeStart: string; dateComputeEnd: string }>({
			query: (queryParams) => ({
				url: '/bo/ops/cashback/affiliation/invest',
				params: queryParams,
				method: 'GET',
				responseHandler: 'content-type',
				cache: 'no-cache',
			}),
		}),
	}),
});

export const {
	useLazyGetScpiPeCashbackQuery,
	useLazyGetScpiCashbackQuery,
	useLazyGetInvestCashbackQuery,
	useLazyGetInvestAffiliationCashbackQuery,
} = endpoints;
