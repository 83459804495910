import { dashboardApi } from 'store/api';
import { ProductType, SubscriptionStatus } from 'types/global.type';
import { User } from 'types/user.type';

interface SponsorshipSubscription {
	amount: number;
	signedAt: string;
	id: string;
	status: SubscriptionStatus;
	type: ProductType;
}

export type AffiliateOpsExtract = {
	invitationId: string;
	affiliate: User & { kyc: { firstName: string; lastName: string } };
	sponsor: User & { kyc: { firstName: string; lastName: string } };

	reimbursementDeadline: Date;
	affiliationDeadline: Date;
	reward: number;
	consideredSubscriptions: SponsorshipSubscription[];
	isSponsorshipPeriodOver: boolean;

	sponsorIBAN: string;
	sponsorBIC: string;
	sponsorBankName: string;

	affiliateIBAN: string;
	affiliateBIC: string;
	affiliateBankName: string;
}[];

const endpoints = dashboardApi.injectEndpoints({
	endpoints: (builder) => ({
		getSponsorshipExtract: builder.query<AffiliateOpsExtract, void>({
			query: () => ({
				url: `/bo/ops/sponsorship`,
				method: 'GET',
			}),
			providesTags: ['OPS_AFFILIATE_REWARDS'],
		}),

		markSponsorshipAsPaid: builder.mutation<void, { invitationIds: string[] }>({
			query: ({ invitationIds }) => ({
				url: `/bo/ops/sponsorship/mark-as-paid`,
				method: 'POST',
				body: { invitationIds },
			}),
			invalidatesTags: ['OPS_AFFILIATE_REWARDS'],
		}),
	}),
});

export const { useGetSponsorshipExtractQuery, useMarkSponsorshipAsPaidMutation } = endpoints;
