import { FC, useCallback, useEffect, useMemo } from 'react';
import { CheckIcon } from '@chakra-ui/icons';
import {
	Accordion,
	AccordionButton,
	AccordionItem,
	AccordionPanel,
	Badge,
	Button,
	HStack,
	Icon,
	SimpleGrid,
	Skeleton,
	Table,
	Tbody,
	Text,
	Th,
	Thead,
	Tooltip,
	Tr,
	VStack,
} from '@chakra-ui/react';
import fileDownload from 'js-file-download';

import useThemedToast from 'hooks/useThemedToast';
import { useGetOpsKpiQuery } from 'services/ops/kpi';
import { isNone, isNotNone, toCSV } from 'utils/functions';
import { displayPercentage } from 'utils/rendering';

import { KpiBlockingInstanceHeaders, KpiDealHeaders, KpiDurationCell } from './KpiMisc';
import {
	formatBlockingInstanceKpiData,
	formatDealKpiData,
	OpsKpiBlockingInstanceDisplay,
	OpsKpiDealDisplay,
	OpsKpiType,
} from './utils';

type KpiTableProps = {
	startDate: Date;
	endDate: Date;
	type: OpsKpiType;
};

const KpiTable: FC<KpiTableProps> = ({ endDate, startDate, type }) => {
	const toast = useThemedToast();

	const { data, isFetching, isError } = useGetOpsKpiQuery(
		{ startDate: startDate.toISOString(), endDate: endDate.toISOString(), type: type as OpsKpiType },
		{ skip: isNone(startDate) || isNone(endDate) || isNone(type) },
	);

	const dataset = useMemo(
		() =>
			data?.dealsWithLabels
				.map((d) => ({
					label: d.label,
					data: d.type === 'Deal' ? formatDealKpiData(d.deals ?? []) : formatBlockingInstanceKpiData(d.deals ?? []),
					percentage: d.percentage,
					result: data?.results.find((r) => r.product === d.label),
					type: d.type,
				}))
				.filter((d) => d.data.length > 0) ?? [],
		[data],
	);

	const handleDownload = useCallback(
		() => (isNone(data) ? null : fileDownload(toCSV(data.results), 'extract', 'text/csv')),
		[data],
	);

	useEffect(() => {
		if (isError) {
			toast({
				title: 'Erreur',
				description: 'Une erreur est survenue lors de la récupération des données',
				status: 'error',
			});
		}
	}, [isError, toast]);

	return (
		<VStack w="100%" align="start">
			<Button isLoading={isFetching} onClick={handleDownload}>
				Télécharger (csv)
			</Button>

			<Skeleton isLoaded={!isFetching && !isError} w="100%">
				<Accordion allowToggle w="100%">
					{dataset.map((product) => (
						<AccordionItem key={product.label} w="100%">
							<AccordionButton>
								<HStack justify="space-between" w="100%">
									<VStack w="5%" align="center">
										{isNotNone(product.data) &&
											product.data.some(
												(d) =>
													('incoherentData' in d && d.incoherentData) ||
													('incoherentTreatmentData' in d && d.incoherentTreatmentData) ||
													('incoherentCompletionData' in d && d.incoherentCompletionData),
											) && <Badge colorScheme="red">Incohérences</Badge>}
										<Badge colorScheme="blue">{displayPercentage(product.percentage)}</Badge>
										<Text>{product.label}</Text>
									</VStack>

									<SimpleGrid columns={3} w="95%">
										{isNotNone(product.result) &&
											Object.entries(product.result)
												.filter(([key]) => key !== 'product')
												.map(([key, value]) => (
													<VStack fontSize="12px" key={key}>
														<Text fontWeight="bold">{key}</Text>
														<Text>{value ?? 'N/A'}</Text>
													</VStack>
												))}
									</SimpleGrid>
								</HStack>
							</AccordionButton>

							<AccordionPanel>
								{isNotNone(product.data) && (
									<Table>
										<Thead>
											<Tr>
												{product.type === 'Deal' ? (
													<KpiDealHeaders />
												) : (
													<KpiBlockingInstanceHeaders isNotMissingInfo={product.label !== 'Missing Info'} />
												)}
											</Tr>
										</Thead>
										<Tbody>
											{product.type === 'Deal'
												? (product.data as OpsKpiDealDisplay[]).map((sub) => (
														<Tr key={`${product.label}-${sub.id}`}>
															<Th>{sub.email}</Th>
															<Th>{sub.product}</Th>
															<Th>
																<Tooltip label={sub.submittedTime}>
																	<Text>{sub.submittedTimeDisplay}</Text>
																</Tooltip>
															</Th>
															<Th>
																<Tooltip label={sub.treatedTime}>
																	<Text>{sub.treatedTimeDisplay}</Text>
																</Tooltip>
															</Th>
															<Th>
																<Tooltip label={sub.completedTime}>
																	<Text>{sub.completedTimeDisplay}</Text>
																</Tooltip>
															</Th>
															<Th>
																<VStack align="start">
																	{sub.incoherentTreatmentData && (
																		<Tooltip label="Les données contiennent des incohérences de dates, ce qui fausse les résultats. Cette souscription devrait être investiguée pour corriger l'incohérence en DB (ceci ne devrait pas arriver)">
																			<Badge colorScheme="red">Incohérence</Badge>
																		</Tooltip>
																	)}
																	{sub.unpreciseTreatmentData && isNotNone(sub.treatmentDuration) && (
																		<Tooltip label="Il s'agit d'une approximation car les dates n'ont pas de précisions horaires (ex: 04/03/2024 au lieu de 04/03/2024 à 13h34)">
																			<Badge colorScheme="yellow">Approximation</Badge>
																		</Tooltip>
																	)}
																	<KpiDurationCell
																		duration={sub.treatmentDuration}
																		durationDisplay={sub.treatmentDurationDisplay}
																		durationDisplayHours={sub.treatmentDurationDisplayHours}
																	/>
																</VStack>
															</Th>
															<Th>
																<VStack align="start">
																	{sub.incoherentCompletionData && (
																		<Tooltip label="Les données contiennent des incohérences de dates, ce qui fausse les résultats. Cette souscription devrait être investiguée pour corriger l'incohérence en DB (ceci ne devrait pas arriver)">
																			<Badge colorScheme="red">Incohérence</Badge>
																		</Tooltip>
																	)}
																	{sub.unpreciseCompletionData && isNotNone(sub.completionDuration) && (
																		<Tooltip label="Il s'agit d'une approximation car les dates n'ont pas de précisions horaires (ex: 04/03/2024 au lieu de 04/03/2024 à 13h34)">
																			<Badge colorScheme="yellow">Approximation</Badge>
																		</Tooltip>
																	)}
																	<KpiDurationCell
																		duration={sub.completionDuration}
																		durationDisplay={sub.completionDurationDisplay}
																		durationDisplayHours={sub.completionDurationDisplayHours}
																	/>
																</VStack>
															</Th>
															<Th>{sub.isBlack && <Icon as={CheckIcon} color="green.500" />}</Th>
															<Th>{sub.hasBlockingInstance && <Icon as={CheckIcon} color="green.500" />}</Th>
														</Tr>
												  ))
												: (product.data as OpsKpiBlockingInstanceDisplay[]).map((sub) => (
														<Tr key={`${product.label}-${sub.id}`}>
															<Th>{sub.email}</Th>
															{product.label !== 'Missing Info' && (
																<Th>
																	<Tooltip label={sub.openAt}>
																		<Text>{sub.openAtDisplay}</Text>
																	</Tooltip>
																</Th>
															)}
															<Th>
																<Tooltip label={sub.sentToClientAt}>
																	<Text>{sub.sentToClientAtDisplay}</Text>
																</Tooltip>
															</Th>
															<Th>
																<Tooltip label={sub.documentsSubmittedAt}>
																	<Text>{sub.documentsSubmittedAtDisplay}</Text>
																</Tooltip>
															</Th>
															{product.label !== 'Missing Info' && (
																<Th>
																	<Tooltip label={sub.sentToPartnerAt}>
																		<Text>{sub.sentToPartnerAtDisplay}</Text>
																	</Tooltip>
																</Th>
															)}
															<Th>
																<Tooltip label={sub.closedAt}>
																	<Text>{sub.closedAtDisplay}</Text>
																</Tooltip>
															</Th>
															{product.label !== 'Missing Info' && (
																<Th>
																	<KpiDurationCell
																		duration={sub.durationA}
																		durationDisplay={sub.durationADisplay}
																		durationDisplayHours={sub.durationADisplayHours}
																	/>
																</Th>
															)}
															<Th>
																<KpiDurationCell
																	duration={sub.durationB}
																	durationDisplay={sub.durationBDisplay}
																	durationDisplayHours={sub.durationBDisplayHours}
																/>
															</Th>
															{product.label !== 'Missing Info' && (
																<Th>
																	<KpiDurationCell
																		duration={sub.durationC}
																		durationDisplay={sub.durationCDisplay}
																		durationDisplayHours={sub.durationCDisplayHours}
																	/>
																</Th>
															)}
															<Th>
																<KpiDurationCell
																	duration={sub.durationD}
																	durationDisplay={sub.durationDDisplay}
																	durationDisplayHours={sub.durationDDisplayHours}
																/>
															</Th>
															<Th>{sub.isBlack && <Icon as={CheckIcon} color="green.500" />}</Th>
														</Tr>
												  ))}
										</Tbody>
									</Table>
								)}
							</AccordionPanel>
						</AccordionItem>
					))}
				</Accordion>
			</Skeleton>
		</VStack>
	);
};

export default KpiTable;
