import { MoralPerson } from 'types/moralPerson.type';

import { LegalEntity, SubscriptionStatus } from './global.type';

export enum SavingsAccountType {
	CER = 'CER',
	CAT = 'CAT',
}

export const savingsAccountTypeLabels: Record<SavingsAccountType, string> = {
	[SavingsAccountType.CAT]: 'Compte à terme',
	[SavingsAccountType.CER]: 'Livret épargne rémunéré',
};

export enum SavingsAccountProvider {
	MY_MONEY_BANK = 'MY_MONEY_BANK',
	PLATON = 'PLATON',
	CFCAL = 'CFCAL',
}

export const savingsAccountProviderLabels: Record<SavingsAccountProvider, string> = {
	[SavingsAccountProvider.MY_MONEY_BANK]: 'My Money Bank',
	[SavingsAccountProvider.PLATON]: 'Platon',
	[SavingsAccountProvider.CFCAL]: 'CFCAL',
};

export enum SavingsAccountSupplyFrequency {
	REGULARLY = 'REGULARLY',
	PUNCTUALLY = 'PUNCTUALLY',
}

export enum SavingsAccountTimeHorizon {
	LESS_THAN_1_YEAR = 'LESS_THAN_1_YEAR',
	MORE_THAN_1_YEAR = 'MORE_THAN_1_YEAR',
}

export enum SavingsAccountInvestmentDuration {
	THREE_MONTHS = 'THREE_MONTHS',
	SIX_MONTHS = 'SIX_MONTHS',
	TWELVE_MONTHS = 'TWELVE_MONTHS',
	TWENTY_FOUR_MONTHS = 'TWENTY_FOUR_MONTHS',
	THIRTY_SIX_MONTHS = 'THIRTY_SIX_MONTHS',
}

export enum PatrimonySource {
	PRO_ASSETS = 'PRO_ASSETS',
	REAL_ESTATE_ASSETS = 'REAL_ESTATE_ASSETS',
	ASSETS_SELLOUT = 'ASSETS_SELLOUT',
	GAMBLE_GAINS = 'GAMBLE_GAINS',
	INHERITANCE = 'INHERITANCE',
	SAVINGS = 'SAVINGS',
	OTHER = 'OTHER',
}

export enum MoralPersonStatus {
	FREELANCE = 'FREELANCE',
	COMPANY = 'COMPANY',
	NON_LUCRATIVE_ORGANISME = 'NON_LUCRATIVE_ORGANISME',
}

export type SavingsAccountSubscriptionStatus =
	| SubscriptionStatus.REQUESTED
	| SubscriptionStatus.SIGNED
	| SubscriptionStatus.PARTNER_VALIDATION
	| SubscriptionStatus.PAYMENT
	| SubscriptionStatus.COMPLETED
	| SubscriptionStatus.TERMINATED;

export type SavingsAccount = {
	id: string;

	initialDepositAmount?: string;
	timeHorizon?: SavingsAccountTimeHorizon;
	type: SavingsAccountType;
	provider: SavingsAccountProvider;
	fundsOrigin?: PatrimonySource;
	moneySupplyFrequency?: SavingsAccountSupplyFrequency;
	standardRate: number;
	promotionalOfferRate: number;
	promotionalOfferDuration: number;
	endDate?: string;
	investmentDuration?: SavingsAccountInvestmentDuration;
	didInitialDeposit?: boolean;

	bankInformationId?: string;
	hubspotId?: string;
	opsPropertiesId?: string;
	externalAccountId: string;

	legalEntity: LegalEntity;
	moralPerson?: MoralPerson;
	moralPersonId: string | null;

	status: SavingsAccountSubscriptionStatus;
	createdAt: string;
	pendingAt?: string;
	kycValidationAt?: string;
	contractSentToClientAt?: string;
	signedAt?: string;
	partnerValidationAt?: string;
	paymentAt?: string;
	completedAt?: string;
	terminatedAt?: string;
};

export type SavingsAccountWithHistory = SavingsAccount & {
	history: SavingsAccountHistory[];
};

export type SavingsAccountHistory = {
	id: string;
	date: string;
	valuation: string;
};
