import {
	NumberDecrementStepper,
	NumberIncrementStepper,
	NumberInput as ChakraNumberInput,
	NumberInputField,
	NumberInputProps,
	NumberInputStepper,
} from '@chakra-ui/react';

const NumberInputWithStepper = (props: NumberInputProps) => (
	<ChakraNumberInput {...props}>
		<NumberInputField />
		<NumberInputStepper>
			<NumberIncrementStepper />
			<NumberDecrementStepper />
		</NumberInputStepper>
	</ChakraNumberInput>
);

export default NumberInputWithStepper;
