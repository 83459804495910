import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { chakra } from '@chakra-ui/react';

import Cardlayout from 'components/CardLayout';
import DisplayConversation from 'features/mailing-framework/DisplayConversation';
import useThemedToast from 'hooks/useThemedToast';
import { MailingFrameworkPostCreation, useCreatePostMutation } from 'services/mailing-framework/mailing-framework.post';
import { MailingFrameworkPostType } from 'types/mailing-framework.type';
import { isNone } from 'utils/functions';

const defaultMessage = `Bonjour,

Avez-vous pus prendre connaissance de notre dernier mail.
N'hésitez pas si des éléments seraient manquants à nous les demander.

Cordialement,
L'équipe de Gestion de Ramify`;

type ReplyMailProps = {
	conversationId?: string;
	canSendMail?: boolean;
	title?: string;
};

const MailFollowUpSection: FC<ReplyMailProps> = ({ title, conversationId, canSendMail }) => {
	const toast = useThemedToast();

	// POST CREATION FORM
	const methods = useForm<MailingFrameworkPostCreation>({ defaultValues: { message: defaultMessage } });

	// CREATE A POST
	const [createPost, { isLoading: isLoadingCreatePost }] = useCreatePostMutation();
	const handleCreatePost = methods.handleSubmit((data) => {
		if (!conversationId) return;
		createPost({
			conversationId,
			body: { type: MailingFrameworkPostType.MANUAL, message: data.message },
		})
			.unwrap()
			.then(() => {
				toast({ status: 'success', title: 'Succès', description: 'Message envoyée avec succès' });
				methods.setValue('message', ''); // clear the text area after sending the mail
			})
			.catch((err) => toast({ status: 'error', title: 'Erreur', description: err.data.message }));
	});

	if (isNone(conversationId)) return null;

	return (
		<chakra.form onSubmit={handleCreatePost} w="100%">
			<FormProvider {...methods}>
				<Cardlayout title={title ?? 'Communication'} defaultIsOpen={false}>
					<DisplayConversation
						conversationId={conversationId}
						canSendMail={canSendMail}
						isLoadingCreatePost={isLoadingCreatePost}
					/>
				</Cardlayout>
			</FormProvider>
		</chakra.form>
	);
};

export default MailFollowUpSection;
