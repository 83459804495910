import React, { FC } from 'react';
import { Button, ButtonProps, Checkbox, CheckboxProps, TableCellProps, Td } from '@chakra-ui/react';

import YesNoIcon from 'components/icons/YesNo';
import { isNotNone } from 'utils/functions';

// default

type DefaultTdProps = TableCellProps & {
	hideCell?: boolean;
	hideValue?: boolean;
};

export const DefaultTd: FC<DefaultTdProps> = ({ children, hideCell, hideValue, ...props }) =>
	!hideCell && (
		<Td borderWidth="2px" {...props}>
			{hideValue ? null : children}
		</Td>
	);

// boolean

type BoolTdProps = DefaultTdProps & {
	value: boolean | undefined | null;
};

export const BoolTd: FC<BoolTdProps> = ({ value, ...props }) => (
	<DefaultTd {...props}>{isNotNone(value) ? <YesNoIcon isTrue={value} /> : null}</DefaultTd>
);

// date

type DateTdProps = DefaultTdProps & {
	value: Date | undefined | null;
};

export const DateTd: FC<DateTdProps> = ({ value, ...props }) => (
	<DefaultTd {...props}>
		{isNotNone(value) ? (value instanceof Date ? value.toLocaleDateString('fr-FR') : 'Invalid Date') : null}
	</DefaultTd>
);

// button

type ButtonTdProps = DefaultTdProps & {
	onClick: ButtonProps['onClick'];
	buttonStyleProps?: ButtonProps;
};

export const ButtonTd: FC<ButtonTdProps> = ({ onClick, buttonStyleProps, children, ...props }) => (
	<DefaultTd {...props}>
		<Button size="xs" {...buttonStyleProps} onClick={onClick}>
			{children}
		</Button>
	</DefaultTd>
);

// checkbox

type CheckboxTdProps = Omit<DefaultTdProps, 'onChange'> & {
	isChecked: boolean;
	onChange: CheckboxProps['onChange'];
};

export const CheckboxTd: FC<CheckboxTdProps> = ({ isChecked, onChange, ...props }) => (
	<DefaultTd {...props}>
		<Checkbox isChecked={isChecked} onChange={onChange} />
	</DefaultTd>
);
