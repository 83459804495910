import { FC, useMemo, useState } from 'react';
import {
	Button,
	Divider,
	FormControl,
	FormLabel,
	Heading,
	HStack,
	Input,
	Select,
	Skeleton,
	Text,
	useDisclosure,
	VStack,
	Wrap,
	WrapItem,
} from '@chakra-ui/react';

import FilterPopovers from 'components/filters';
import SearchBy, { searchByFunc } from 'components/filters/SearchBy';
import DealsTable from 'components/tables/DealsTable';
import { AllDeal, OpsInvestSub } from 'services/deal';
import { useGetAllInvestSubscriptionsQuery } from 'services/ops/rattachement';
import { useGetStatusesQuery } from 'services/ops/status';
import { SubscriptionStatus } from 'types/global.type';

import ConfirmationModalRattachement from './ConfirmationModalRattachement';

const RattachementSubscription: FC = () => {
	const { data: subs, isFetching } = useGetAllInvestSubscriptionsQuery();
	const { data: productTypeStatuses } = useGetStatusesQuery();

	const { isOpen, onOpen, onClose } = useDisclosure();

	const [selectedSubscriptionId, setSelectedSubscriptionId] = useState<string | null>(null);
	const [input, setInput] = useState<string>('');
	const [searchBy, setSearchBy] = useState<'email' | 'name'>('email');
	const [statusFilter, setStatusFilter] = useState<SubscriptionStatus[]>([]);
	const [ignoreEventsContractOpening, setIgnoreEventsContractOpening] = useState<boolean>(false);
	const [newApicilSubscriptionNumber, setNewApicilSubscriptionNumber] = useState<string>('');
	const [newStatus, setNewStatus] = useState<
		SubscriptionStatus.REQUESTED | SubscriptionStatus.SIGNED | SubscriptionStatus.PARTNER_VALIDATION
	>(SubscriptionStatus.REQUESTED);

	const filteredSubs = useMemo(() => {
		if (!subs) return [];

		return subs.filter((sub) => {
			if (statusFilter.length > 0 && !statusFilter.includes(sub.status)) return false;
			if (input && !searchByFunc(sub as AllDeal, searchBy, input)) return false;
			return true;
		});
	}, [subs, input, searchBy, statusFilter]);

	const onSelectSubscription = (sub: OpsInvestSub) => {
		setSelectedSubscriptionId(sub.id);
		if (sub.apicilSubscriptionId) setNewApicilSubscriptionNumber(sub.apicilSubscriptionId);
		else setNewApicilSubscriptionNumber('');
		if (sub.status)
			setNewStatus(
				sub.status as SubscriptionStatus.REQUESTED | SubscriptionStatus.SIGNED | SubscriptionStatus.PARTNER_VALIDATION,
			);
		else setNewStatus(SubscriptionStatus.REQUESTED);

		if (sub.ignoreEventsContractOpening) setIgnoreEventsContractOpening(sub.ignoreEventsContractOpening);
		else setIgnoreEventsContractOpening(false);
	};

	const selectedSubscription = useMemo(() => {
		if (!subs) return null;
		return subs.find((sub) => sub.id === selectedSubscriptionId);
	}, [subs, selectedSubscriptionId]);

	return (
		<VStack align="start" w="100%" p="24px" spacing="24px" pb="80px">
			<Heading>Rattachement de contrats invest</Heading>
			<Divider />
			<HStack w="100%">
				<SearchBy
					search={input}
					onChangeSearch={setInput}
					searchBy={searchBy}
					onChangeSearchBy={(e) => setSearchBy(e as 'email' | 'name')}
				/>

				<FilterPopovers
					components={[
						{
							title: 'Statut',
							componentProps: {
								value: statusFilter,
								onChange: (v: string[]) => setStatusFilter(v as SubscriptionStatus[]),
								options: [SubscriptionStatus.REQUESTED, SubscriptionStatus.SIGNED],
							},
						},
					]}
				/>
			</HStack>

			<Skeleton isLoaded={!isFetching} w="100%">
				<DealsTable
					context="subscription"
					productTypeStatuses={productTypeStatuses}
					deals={filteredSubs as AllDeal[]}
					selectedDeal={selectedSubscription as AllDeal}
					onClick={(c) => onSelectSubscription(c as OpsInvestSub)}
					pageSize={10}
				/>
			</Skeleton>

			{selectedSubscription && (
				<>
					<VStack align="start" w="100%" spacing="16px">
						<Divider />

						<Heading size="md">Contrat sélectionné :</Heading>
						<Text>
							Numéro de projet Apicil : <b>{selectedSubscription.apicilSubscriptionId}</b>
						</Text>
						<Text>
							Statut : <b>{selectedSubscription.status}</b>
						</Text>
						<Text>Montant : {selectedSubscription.initialDepositAmount}</Text>
						<Text>
							Portefeuille : {selectedSubscription.investmentPreferences?.portfolioType} - Risque{' '}
							{selectedSubscription.investmentPreferences?.risk}
						</Text>

						<Divider />

						<Wrap w="100%" spacingX="24px">
							<WrapItem maxW="380px" w="100%">
								<FormControl>
									<FormLabel>Projet</FormLabel>
									<Input
										bg="white"
										placeholder="Nouveau numéro de projet Apicil"
										value={newApicilSubscriptionNumber}
										minLength={6}
										maxLength={6}
										onChange={(v) => setNewApicilSubscriptionNumber(v.target.value)}
									/>
								</FormControl>
							</WrapItem>
							<WrapItem maxW="380px" w="100%">
								<FormControl maxW="380px">
									<FormLabel>Changer le status</FormLabel>
									<Select
										bg="white"
										value={newStatus}
										onChange={(v) =>
											setNewStatus(
												v.target.value as
													| SubscriptionStatus.REQUESTED
													| SubscriptionStatus.SIGNED
													| SubscriptionStatus.PARTNER_VALIDATION,
											)
										}
									>
										<option value={SubscriptionStatus.REQUESTED}>Requested</option>
										<option value={SubscriptionStatus.SIGNED}>Signed</option>
										<option value={SubscriptionStatus.PARTNER_VALIDATION}>Validation partenaire</option>
									</Select>
								</FormControl>
							</WrapItem>
							<WrapItem maxW="380px" w="100%">
								{/* ignoreEventsContractOpening  */}
								<FormControl maxW="380px">
									<FormLabel>Emails marketing</FormLabel>
									<Select
										bg="white"
										value={ignoreEventsContractOpening ? 'true' : 'false'}
										onChange={(v) => setIgnoreEventsContractOpening(v.target.value === 'true' ? true : false)}
									>
										<option value="false">Activé</option>
										<option value="true">Désactivé</option>
									</Select>
								</FormControl>
							</WrapItem>
							<Button alignSelf="end" colorScheme="blue" onClick={onOpen}>
								Valider
							</Button>
						</Wrap>
					</VStack>

					<ConfirmationModalRattachement
						show={isOpen}
						subscription={selectedSubscription as OpsInvestSub}
						newStatus={newStatus}
						newApicilSubscriptionNumber={newApicilSubscriptionNumber}
						ignoreEventsContractOpening={ignoreEventsContractOpening}
						handleClose={onClose}
					/>
				</>
			)}
		</VStack>
	);
};

export default RattachementSubscription;
