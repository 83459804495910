import { FC, useMemo, useState } from 'react';
import { AddIcon } from '@chakra-ui/icons';
import { Button, Heading, HStack, Skeleton, useDisclosure, VStack } from '@chakra-ui/react';
import { useAuth } from '@frontegg/react';

import CustomDrawer from 'components/Drawer';
import FilterPopovers from 'components/filters';
import SearchBy from 'components/filters/SearchBy';
import PropalesTable from 'components/tables/PropaleTable';
import CreatePropale from 'pages/cpm/super/propale/PropaleCreate';
import { useGetPropalesQuery } from 'services/cpm/propale';
import { PropaleStatus, PropaleType } from 'types/cpm-propale';
import { BOContext, ProductType } from 'types/global.type';

export const statusNamingMap = {
	[PropaleStatus.TODO]: 'Todo',
	[PropaleStatus.SENT]: 'Envoyée',
	[PropaleStatus.DONE]: 'Closed',
	[PropaleStatus.DEAD]: 'Dead',
};

export const propaleTypeTranslation = {
	[PropaleType.AUTO]: 'automatique',
	[PropaleType.FOLLOW_UP]: 'à suivre',
	[PropaleType.MANUAL]: 'manuelle',
	[PropaleType.NO_SHOW]: 'no Show',
};

type PropaleListProps = {
	context: Extract<BOContext, 'client' | 'propale'>;
	customSearchBy?: 'email' | 'name'; // force filter at route level
	customInput?: string; // force filter at route level
};

const PropaleList: FC<PropaleListProps> = ({ customInput, customSearchBy, context }) => {
	const { user } = useAuth();
	const { isOpen: isCreateOpen, onOpen: onCreateOpen, onClose: onCreateClose } = useDisclosure();

	const [input, setInput] = useState(customInput ?? '');
	const [searchBy, setSearchBy] = useState<string>(customSearchBy ?? 'email');

	const { data: propales, isFetching: isPropalesFetching } = useGetPropalesQuery({ searchBy, input });

	const [productFilter, setProductFilter] = useState(Object.values(ProductType));
	const [typeFilter, setTypeFilter] = useState(Object.values(PropaleType));
	const [clientTypeFilter, setClientTypeFilter] = useState<string[]>([]);
	const [cpmFilter, setCpmFilter] = useState<string[]>([user?.email ?? '']);
	const cpmOptions = useMemo(
		() => [...new Set(propales?.map((d) => d.assignedCpmEmail ?? ''))].filter((o) => o.length > 0).sort(),
		[propales],
	);

	return (
		<VStack w="100%" align="start">
			<CustomDrawer
				header={<Heading size="md">Nouvelle propale</Heading>}
				isOpen={isCreateOpen}
				onClose={onCreateClose}
			>
				<CreatePropale context="propale" onClose={onCreateClose} />
			</CustomDrawer>

			<HStack w="100%">
				{context === 'client' && <Heading size="md">Toutes les propales</Heading>}
				{context === 'propale' && (
					<>
						<SearchBy
							search={input}
							onChangeSearch={setInput}
							searchBy={searchBy}
							onChangeSearchBy={setSearchBy}
							isFetching={isPropalesFetching}
						/>

						<FilterPopovers
							components={[
								{
									title: 'Produit',
									componentProps: {
										value: productFilter,
										onChange: (v: string[]) => setProductFilter(v as ProductType[]),
										options: Object.values(ProductType),
									},
								},
								{
									title: 'Type de propale',
									componentProps: {
										value: typeFilter,
										onChange: (v: string[]) => setTypeFilter(v as PropaleType[]),
										options: Object.values(PropaleType),
									},
								},
								{
									title: 'CPM attitré',
									componentProps: {
										onChange: setCpmFilter,
										options: cpmOptions,
										value: cpmFilter,
									},
								},
								{
									title: 'Client type',
									componentProps: {
										onChange: setClientTypeFilter,
										options: ['isBlack', 'isPhoenix', 'isCorporate'],
										value: clientTypeFilter,
									},
								},
							]}
						/>
						<Button colorScheme="blue" onClick={onCreateOpen} leftIcon={<AddIcon />}>
							Créer
						</Button>
					</>
				)}
			</HStack>

			<Skeleton isLoaded={!isPropalesFetching} w="100%">
				<PropalesTable
					context={context}
					propales={(propales ?? [])
						.filter((p) =>
							p.linkedEntities?.length === 0 ? true : p.linkedEntities.some((e) => productFilter.includes(e.product)),
						)
						.filter((p) => typeFilter.includes(p.type))
						.filter((s) =>
							cpmFilter.length === 0 ? true : cpmFilter.includes(s.assignedCpmEmail || s.creatorCpmEmail),
						)
						.filter((p) => {
							if (clientTypeFilter.length === 0) return true;
							return (
								(clientTypeFilter.includes('isBlack') && p.user?.isBlack) ||
								(clientTypeFilter.includes('isPhoenix') && p.user?.isPhoenix) ||
								(clientTypeFilter.includes('isCorporate') && p.user?.isCorporate)
							);
						})}
					pageSize={50}
				/>
			</Skeleton>
		</VStack>
	);
};

export default PropaleList;
