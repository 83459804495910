import { FC } from 'react';
import { CloseIcon } from '@chakra-ui/icons';
import { HStack, Text } from '@chakra-ui/react';

type DocumentButtonProps = {
	name: string;
	onDelete: () => void;
};

const DocumentButton: FC<DocumentButtonProps> = ({ name, onDelete }) => (
	<HStack align="center" bg="gray.100" px="16px" py="4px" borderRadius="4px" spacing="16px" justify="space-between">
		<Text>{name}</Text>
		<CloseIcon cursor="pointer" boxSize="12px" onClick={onDelete} />
	</HStack>
);

export default DocumentButton;
