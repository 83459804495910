export enum LegalCapacity {
	MAJOR = 'MAJOR',
	MINOR = 'MINOR',
	GUARDIANSHIP = 'GUARDIANSHIP',
	// HANDICAP = 'HANDICAP',
}

const LegalCapacityReferentiel: Record<LegalCapacity, string> = {
	MAJOR: 'Majeur',
	MINOR: 'Mineur',
	GUARDIANSHIP: 'Sous tutelle / curatelle',
	// HANDICAP: 'Epargne handicap',
};

export default LegalCapacityReferentiel;
