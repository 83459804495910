import { dashboardApi } from 'store/api';
import { ProductType } from 'types/global.type';

import { AllDeal } from './deal';

export type OpsAttachment = {
	documentName: string;
	filename: string;
	url?: string;
	content?: string;
};

export type SendApiSubscription = {
	partner: string;
	subscriptionId: string;
	assignedOps: string;
	sendClientConfirmationMail: boolean;
	rib?: OpsAttachment;
	identity?: OpsAttachment;
	identityVerso?: OpsAttachment;
	jod?: OpsAttachment;
	jof?: OpsAttachment;
	transferForms?: OpsAttachment[];
};

export type SendEmailSubscription = {
	assignedOps: string;
	ownerEmail: string;
	subscriptionId: string;
	sender: string;
	receiver: string;
	cc?: string;
	bcc?: string;
	subject: string;
	message: string;
	attachments: OpsAttachment[];
	productType: ProductType | 'TRANSFER';
	sendClientConfirmationMail: boolean;
};

export type EmailSubscriptionCreation = {
	assignedOps: string;
	ownerEmail: string;
	subscriptionId: string;
	sender: string;
	receiver: string[];
	cc?: string[];
	bcc?: string[];
	subject: string;
	message: string;
	attachments: OpsAttachment[];
	productType: ProductType | 'TRANSFER';
	sendClientConfirmationMail: boolean;
};

const endpoints = dashboardApi.injectEndpoints({
	endpoints: (builder) => ({
		getSubscriptions: builder.query<AllDeal[], { searchBy: string; input: string | undefined }>({
			query: (queryParams) => ({
				url: '/bo/subscriptions',
				params: queryParams,
				method: 'GET',
			}),
			providesTags: ['OPS_SUBSCRIPTIONS'],
		}),
		sendSubscriptionApi: builder.mutation<void, SendApiSubscription>({
			query: ({ partner, ...body }) => ({
				url: `/bo/subscriptions/send/${partner}`,
				body: body,
				method: 'POST',
			}),
			invalidatesTags: ['OPS_SUBSCRIPTIONS'],
		}),
		sendSubscriptionEmail: builder.mutation<void, EmailSubscriptionCreation>({
			query: (body) => ({
				url: '/bo/subscriptions/send/email',
				body: body,
				method: 'POST',
			}),
			invalidatesTags: ['OPS_SUBSCRIPTIONS', 'MAILING_FRAMEWORK'],
		}),
	}),
});

export const {
	useGetSubscriptionsQuery,
	useLazyGetSubscriptionsQuery,
	useSendSubscriptionApiMutation,
	useSendSubscriptionEmailMutation,
} = endpoints;
