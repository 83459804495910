import { CloseIcon } from '@chakra-ui/icons';
import { HStack, Image, Text, useToast, UseToastOptions, VStack } from '@chakra-ui/react';

import Error from 'assets/toast/error.svg';
import Info from 'assets/toast/info.svg';
import Success from 'assets/toast/success.svg';

const getIconFromStatus = (status: string | undefined) => {
	if (status === 'success') return <Image src={Success} />;
	if (status === 'error') return <Image src={Error} />;
	if (status === 'info') return <Image src={Info} />;
	return <Image src={Info} />;
};

const themedToast = (options: UseToastOptions, onClose: () => void) => (
	<HStack color="white" bg="#1A202C" p="16px" borderRadius="8px" align="start" spacing="32px">
		{getIconFromStatus(options.status)}
		<VStack align="start">
			<Text color="white" fontWeight="semibold">
				{options.title}
			</Text>
			{options.description && <Text color="#A0AEC0">{options.description}</Text>}
		</VStack>
		{options.isClosable && <CloseIcon onClick={onClose} fontSize="14px" cursor="pointer" />}
	</HStack>
);

const useThemedToast = () => {
	const toast = useToast();

	const returnFunction = (options: UseToastOptions) =>
		toast({
			id: options.id,
			duration: options.duration,
			isClosable: true,
			render: ({ onClose }) => themedToast(options, onClose),
		});
	return Object.assign(returnFunction, toast);
};

export default useThemedToast;
