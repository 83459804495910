import { createIcon } from '@chakra-ui/react';

export const ClientIcon = createIcon({
	displayName: 'ClientIcon',
	viewBox: '0 0 24 24',
	path: (
		<g fill="currentColor">
			<path d="M1 17.2c0-.567.146-1.087.438-1.563.291-.475.679-.837 1.162-1.087a14.843 14.843 0 0 1 3.15-1.163A13.76 13.76 0 0 1 9 13c1.1 0 2.183.13 3.25.387 1.067.259 2.117.646 3.15 1.163.483.25.87.612 1.162 1.087.292.476.438.996.438 1.563v.8c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 15 20H3c-.55 0-1.02-.196-1.413-.587A1.926 1.926 0 0 1 1 18v-.8ZM21 20h-2.55c.183-.3.32-.62.413-.962.091-.342.137-.688.137-1.038v-1c0-.733-.204-1.438-.613-2.113-.408-.675-.987-1.254-1.737-1.737a12.515 12.515 0 0 1 4.5 1.4c.6.333 1.058.704 1.375 1.112.317.409.475.855.475 1.338v1c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 21 20ZM9 12c-1.1 0-2.042-.392-2.825-1.175C5.392 10.042 5 9.1 5 8s.392-2.042 1.175-2.825C6.958 4.392 7.9 4 9 4s2.042.392 2.825 1.175C12.608 5.958 13 6.9 13 8s-.392 2.042-1.175 2.825C11.042 11.608 10.1 12 9 12Zm10-4c0 1.1-.392 2.042-1.175 2.825C17.042 11.608 16.1 12 15 12c-.183 0-.417-.02-.7-.063a6.128 6.128 0 0 1-.7-.137 5.947 5.947 0 0 0 1.037-1.775C14.88 9.375 15 8.7 15 8s-.12-1.375-.363-2.025A5.947 5.947 0 0 0 13.6 4.2a3.04 3.04 0 0 1 .7-.163c.233-.024.467-.037.7-.037 1.1 0 2.042.392 2.825 1.175C18.608 5.958 19 6.9 19 8ZM3 18h12v-.8a.973.973 0 0 0-.5-.85c-.9-.45-1.808-.787-2.725-1.012a11.6 11.6 0 0 0-5.55 0c-.917.225-1.825.562-2.725 1.012a.973.973 0 0 0-.5.85v.8Zm6-8c.55 0 1.02-.196 1.412-.588C10.804 9.021 11 8.55 11 8c0-.55-.196-1.02-.588-1.412A1.926 1.926 0 0 0 9 6c-.55 0-1.02.196-1.412.588A1.926 1.926 0 0 0 7 8c0 .55.196 1.02.588 1.412C7.979 9.804 8.45 10 9 10Z" />
		</g>
	),
});

export const DealIcon = createIcon({
	displayName: 'DealIcon',
	viewBox: '0 0 24 24',
	path: (
		<g fill="currentColor">
			<path d="M6.70003 21C6.21669 21 5.78753 20.8417 5.41253 20.525C5.03753 20.2083 4.80836 19.8083 4.72503 19.325L3.20003 10.175C3.15003 9.875 3.22086 9.60417 3.41253 9.3625C3.60419 9.12083 3.85836 9 4.17503 9H19.825C20.1417 9 20.3959 9.12083 20.5875 9.3625C20.7792 9.60417 20.85 9.875 20.8 10.175L19.275 19.325C19.1917 19.8083 18.9625 20.2083 18.5875 20.525C18.2125 20.8417 17.7834 21 17.3 21H6.70003ZM5.40003 11L6.67503 19H17.325L18.6 11H5.40003ZM10 15H14C14.2834 15 14.5209 14.9042 14.7125 14.7125C14.9042 14.5208 15 14.2833 15 14C15 13.7167 14.9042 13.4792 14.7125 13.2875C14.5209 13.0958 14.2834 13 14 13H10C9.71669 13 9.47919 13.0958 9.28753 13.2875C9.09586 13.4792 9.00003 13.7167 9.00003 14C9.00003 14.2833 9.09586 14.5208 9.28753 14.7125C9.47919 14.9042 9.71669 15 10 15ZM6.00003 8C5.71669 8 5.47919 7.90417 5.28753 7.7125C5.09586 7.52083 5.00003 7.28333 5.00003 7C5.00003 6.71667 5.09586 6.47917 5.28753 6.2875C5.47919 6.09583 5.71669 6 6.00003 6H18C18.2834 6 18.5209 6.09583 18.7125 6.2875C18.9042 6.47917 19 6.71667 19 7C19 7.28333 18.9042 7.52083 18.7125 7.7125C18.5209 7.90417 18.2834 8 18 8H6.00003ZM8.00003 5C7.71669 5 7.47919 4.90417 7.28753 4.7125C7.09586 4.52083 7.00003 4.28333 7.00003 4C7.00003 3.71667 7.09586 3.47917 7.28753 3.2875C7.47919 3.09583 7.71669 3 8.00003 3H16C16.2834 3 16.5209 3.09583 16.7125 3.2875C16.9042 3.47917 17 3.71667 17 4C17 4.28333 16.9042 4.52083 16.7125 4.7125C16.5209 4.90417 16.2834 5 16 5H8.00003Z" />
		</g>
	),
});

export const BlockingInstanceIcon = createIcon({
	displayName: 'BlockingInstanceIcon',
	viewBox: '0 0 24 24',
	path: (
		<g fill="currentColor">
			<path d="M4 20C3.45 20 2.97917 19.8042 2.5875 19.4125C2.19583 19.0208 2 18.55 2 18V6C2 5.45 2.19583 4.97917 2.5875 4.5875C2.97917 4.19583 3.45 4 4 4H9.175C9.44167 4 9.69583 4.05 9.9375 4.15C10.1792 4.25 10.3917 4.39167 10.575 4.575L12 6H20C20.55 6 21.0208 6.19583 21.4125 6.5875C21.8042 6.97917 22 7.45 22 8V18C22 18.55 21.8042 19.0208 21.4125 19.4125C21.0208 19.8042 20.55 20 20 20H4ZM4 18H20V8H11.175L9.175 6H4V18ZM12 17C13.1 17 14.0417 16.6083 14.825 15.825C15.6083 15.0417 16 14.1 16 13C16 11.9 15.6083 10.9583 14.825 10.175C14.0417 9.39167 13.1 9 12 9C10.9 9 9.95833 9.39167 9.175 10.175C8.39167 10.9583 8 11.9 8 13C8 14.1 8.39167 15.0417 9.175 15.825C9.95833 16.6083 10.9 17 12 17ZM10.85 10.8C11.0333 10.7167 11.2167 10.6458 11.4 10.5875C11.5833 10.5292 11.7833 10.5 12 10.5C12.7 10.5 13.2917 10.7417 13.775 11.225C14.2583 11.7083 14.5 12.3 14.5 13C14.5 13.2167 14.4708 13.4167 14.4125 13.6C14.3542 13.7833 14.2833 13.9667 14.2 14.15L10.85 10.8ZM12 15.5C11.3 15.5 10.7083 15.2583 10.225 14.775C9.74167 14.2917 9.5 13.7 9.5 13C9.5 12.7833 9.52917 12.5833 9.5875 12.4C9.64583 12.2167 9.71667 12.0333 9.8 11.85L13.15 15.2C12.9667 15.2833 12.7833 15.3542 12.6 15.4125C12.4167 15.4708 12.2167 15.5 12 15.5Z" />
		</g>
	),
});

export const TransferIcon = createIcon({
	displayName: 'TransferIcon',
	viewBox: '0 0 24 24',
	path: (
		<g fill="currentColor">
			<path d="M5.82499 16L7.69999 17.875C7.88332 18.0583 7.97499 18.2875 7.97499 18.5625C7.97499 18.8375 7.88332 19.075 7.69999 19.275C7.49999 19.475 7.26249 19.575 6.98749 19.575C6.71249 19.575 6.47499 19.475 6.27499 19.275L2.69999 15.7C2.59999 15.6 2.52915 15.4917 2.48749 15.375C2.44582 15.2583 2.42499 15.1333 2.42499 15C2.42499 14.8667 2.44582 14.7417 2.48749 14.625C2.52915 14.5083 2.59999 14.4 2.69999 14.3L6.29999 10.7C6.49999 10.5 6.73332 10.4042 6.99999 10.4125C7.26665 10.4208 7.49999 10.525 7.69999 10.725C7.88332 10.925 7.97915 11.1583 7.98749 11.425C7.99582 11.6917 7.89999 11.925 7.69999 12.125L5.82499 14H12C12.2833 14 12.5208 14.0958 12.7125 14.2875C12.9042 14.4792 13 14.7167 13 15C13 15.2833 12.9042 15.5208 12.7125 15.7125C12.5208 15.9042 12.2833 16 12 16H5.82499ZM18.175 9.99999H12C11.7167 9.99999 11.4792 9.90415 11.2875 9.71249C11.0958 9.52082 11 9.28332 11 8.99999C11 8.71665 11.0958 8.47915 11.2875 8.28749C11.4792 8.09582 11.7167 7.99999 12 7.99999H18.175L16.3 6.12499C16.1167 5.94165 16.025 5.71249 16.025 5.43749C16.025 5.16249 16.1167 4.92499 16.3 4.72499C16.5 4.52499 16.7375 4.42499 17.0125 4.42499C17.2875 4.42499 17.525 4.52499 17.725 4.72499L21.3 8.29999C21.4 8.39999 21.4708 8.50832 21.5125 8.62499C21.5542 8.74165 21.575 8.86665 21.575 8.99999C21.575 9.13332 21.5542 9.25832 21.5125 9.37499C21.4708 9.49165 21.4 9.59999 21.3 9.69999L17.7 13.3C17.5 13.5 17.2667 13.5958 17 13.5875C16.7333 13.5792 16.5 13.475 16.3 13.275C16.1167 13.075 16.0208 12.8417 16.0125 12.575C16.0042 12.3083 16.1 12.075 16.3 11.875L18.175 9.99999Z" />
		</g>
	),
});

export const SubscriptionIcon = createIcon({
	displayName: 'SubscriptionIcon',
	viewBox: '0 0 24 24',
	path: (
		<g fill="currentColor">
			<path d="M5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H19C19.55 3 20.0208 3.19583 20.4125 3.5875C20.8042 3.97917 21 4.45 21 5V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H5ZM5 19H19V16H16C15.5 16.6333 14.9042 17.125 14.2125 17.475C13.5208 17.825 12.7833 18 12 18C11.2167 18 10.4792 17.825 9.7875 17.475C9.09583 17.125 8.5 16.6333 8 16H5V19ZM12 16C12.5333 16 13.025 15.8625 13.475 15.5875C13.925 15.3125 14.2917 14.95 14.575 14.5C14.675 14.35 14.8 14.2292 14.95 14.1375C15.1 14.0458 15.2667 14 15.45 14H19V5H5V14H8.55C8.73333 14 8.9 14.0458 9.05 14.1375C9.2 14.2292 9.325 14.35 9.425 14.5C9.70833 14.95 10.075 15.3125 10.525 15.5875C10.975 15.8625 11.4667 16 12 16Z" />
		</g>
	),
});

export const HomeIcon = createIcon({
	displayName: 'HomeIcon',
	viewBox: '0 0 24 24',
	path: (
		<g fill="currentColor">
			<path d="M6 19H9V14C9 13.7167 9.09583 13.4792 9.2875 13.2875C9.47917 13.0958 9.71667 13 10 13H14C14.2833 13 14.5208 13.0958 14.7125 13.2875C14.9042 13.4792 15 13.7167 15 14V19H18V10L12 5.5L6 10V19ZM4 19V10C4 9.68333 4.07083 9.38333 4.2125 9.1C4.35417 8.81667 4.55 8.58333 4.8 8.4L10.8 3.9C11.15 3.63333 11.55 3.5 12 3.5C12.45 3.5 12.85 3.63333 13.2 3.9L19.2 8.4C19.45 8.58333 19.6458 8.81667 19.7875 9.1C19.9292 9.38333 20 9.68333 20 10V19C20 19.55 19.8042 20.0208 19.4125 20.4125C19.0208 20.8042 18.55 21 18 21H14C13.7167 21 13.4792 20.9042 13.2875 20.7125C13.0958 20.5208 13 20.2833 13 20V15H11V20C11 20.2833 10.9042 20.5208 10.7125 20.7125C10.5208 20.9042 10.2833 21 10 21H6C5.45 21 4.97917 20.8042 4.5875 20.4125C4.19583 20.0208 4 19.55 4 19Z" />
		</g>
	),
});

export const ThreeDotsIcon = createIcon({
	displayName: 'ThreeDotsIcon',
	viewBox: '0 0 24 24',
	path: (
		<g fill="currentColor">
			<path d="M12 20C11.45 20 10.9792 19.8042 10.5875 19.4125C10.1958 19.0208 10 18.55 10 18C10 17.45 10.1958 16.9792 10.5875 16.5875C10.9792 16.1958 11.45 16 12 16C12.55 16 13.0208 16.1958 13.4125 16.5875C13.8042 16.9792 14 17.45 14 18C14 18.55 13.8042 19.0208 13.4125 19.4125C13.0208 19.8042 12.55 20 12 20ZM12 14C11.45 14 10.9792 13.8042 10.5875 13.4125C10.1958 13.0208 10 12.55 10 12C10 11.45 10.1958 10.9792 10.5875 10.5875C10.9792 10.1958 11.45 10 12 10C12.55 10 13.0208 10.1958 13.4125 10.5875C13.8042 10.9792 14 11.45 14 12C14 12.55 13.8042 13.0208 13.4125 13.4125C13.0208 13.8042 12.55 14 12 14ZM12 8C11.45 8 10.9792 7.80417 10.5875 7.4125C10.1958 7.02083 10 6.55 10 6C10 5.45 10.1958 4.97917 10.5875 4.5875C10.9792 4.19583 11.45 4 12 4C12.55 4 13.0208 4.19583 13.4125 4.5875C13.8042 4.97917 14 5.45 14 6C14 6.55 13.8042 7.02083 13.4125 7.4125C13.0208 7.80417 12.55 8 12 8Z" />
		</g>
	),
});

export const CloseIcon = createIcon({
	displayName: 'CloseIcon',
	viewBox: '0 0 24 24',
	path: (
		<g fill="currentColor">
			<path d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z" />
		</g>
	),
});

export const ChevronUpIcon = createIcon({
	displayName: 'ChevronUpIcon',
	viewBox: '0 0 24 24',
	path: (
		<g fill="currentColor">
			<path d="M12 10.8L7.4 15.4L6 14L12 8L18 14L16.6 15.4L12 10.8Z" fill="#4B5563" />
		</g>
	),
});
export const ChevronDownIcon = createIcon({
	displayName: 'ChevronDownIcon',
	viewBox: '0 0 24 24',
	path: (
		<g fill="currentColor">
			<path d="M12 15.4L6 9.4L7.4 8L12 12.6L16.6 8L18 9.4L12 15.4Z" fill="#4B5563" />
		</g>
	),
});
