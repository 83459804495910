import { FC, useEffect, useState } from 'react';
import { Button, Text, useDisclosure, VStack } from '@chakra-ui/react';

import AlertDialog from 'components/AlertDialog';
import useThemedToast from 'hooks/useThemedToast';
import { useGetClientByEmailQuery, useUpdateBlackStatusMutation } from 'services/client';

type UpdateBlackStatusProps = {
	email: string;
};

const UpdateBlackStatus: FC<UpdateBlackStatusProps> = ({ email }) => {
	const toast = useThemedToast();
	const { isOpen: isValidationOpen, onOpen: openValidation, onClose: closeValidation } = useDisclosure();

	const [isBlack, setIsBlack] = useState<boolean | undefined>();

	const { data: client } = useGetClientByEmailQuery(email);
	const [updateStatus, { isLoading: isUpdateLoading }] = useUpdateBlackStatusMutation();

	const handleBlackUpdate = async () => {
		updateStatus({ email, isBlack: !isBlack })
			.unwrap()
			.then(() => {
				toast({ status: 'success', title: 'Statut black mis à jour avec succès' });
				closeValidation();
				setIsBlack(undefined);
			})
			.catch((err) => toast({ status: 'error', title: 'Erreur', description: err.data.message }));
	};

	useEffect(() => {
		if (client?.isBlack) setIsBlack(client.isBlack);
	}, [client]);

	return (
		<VStack align="start" spacing="16px">
			<Text>
				Statut du client : <b>{client?.isBlack ? 'Client black' : 'Client non black'}</b>
			</Text>

			<Button colorScheme="blue" alignSelf="end" onClick={openValidation} isDisabled={!email}>
				{isBlack ? 'Passer non black' : 'Passer black'}
			</Button>

			<AlertDialog
				isOpen={isValidationOpen}
				onClose={closeValidation}
				header="Modifier le statut black d'un client"
				body={
					<Text>
						Vous êtes sur le point de mettre à jour le status black du client <b>{email}</b> à{' '}
						<b>{isBlack ? 'Non black' : 'Black'}</b>
						<br />
						<br />
						Êtes-vous sûr de vouloir continuer ?
					</Text>
				}
				footer={
					<>
						<Button onClick={() => closeValidation()}>Annuler</Button>
						<Button colorScheme="red" onClick={handleBlackUpdate} ml="12px" isLoading={isUpdateLoading}>
							Valider
						</Button>
					</>
				}
			/>
		</VStack>
	);
};

export default UpdateBlackStatus;
