export enum BoRole {
	OPS = 'ops',
	CPM = 'cpm',
	QIS = 'qis',
	PHOENIX = 'phoenix',
}

export enum BoPermission {
	// OPS
	CLIENT_READ = 'client.read',
	CLIENT_DELETE = 'client.delete',
	CLIENT_AUTOLOGIN_READ = 'client:autologin.read',
	CLIENT_EMAIL_UPDATE = 'client:email.update',
	CLIENT_AFFILIATION_UPDATE = 'client:affiliation.update',
	CLIENT_BLACK_UPDATE = 'client:black.update',
	CLIENT_INVESTOR_CATEGORY_UPDATE = 'client:investor-category.update',
	CLIENT_BANK_ACCOUNT_READ = 'client:bank-account.read',
	CLIENT_BANK_ACCOUNT_CREATE = 'client:bank-account.create',
	CLIENT_BANK_ACCOUNT_UPDATE = 'client:bank-account.update',
	CLIENT_DOCUMENT_READ = 'client:document.read',
	CLIENT_DOCUMENT_CREATE = 'client:document.create',
	CLIENT_DOCUMENT_UPDATE = 'client:document.update', // unused for now
	CLIENT_DOCUMENT_DELETE = 'client:document.delete', // unused for now

	FUND_DATA_READ = 'fund-data.read',

	DEAL_READ = 'deal.read', // (deal & subscriptions & subscription-status & ops properties)
	DEAL_UPDATE = 'deal.update', // (status & ops properties & metadata & overdue & upload de documents airtable)
	DEAL_SEND_SUBSCRIPTION_UPDATE = 'deal:send-subscription.update',
	DEAL_CHURN_UPDATE = 'deal:churn.update',
	DEAL_LINK_INVEST_SUBSCRIPTION_UPDATE = 'deal:link-invest-subscription.update',
	DEAL_DELETE = 'deal.delete',

	ALERTS_SYSTEM_READ = 'alerts-system.read',
	ALERTS_SYSTEM_UPDATE = 'alerts-system.update',
	ALERTS_SYSTEM_DELETE = 'alerts-system.delete',

	BLOCKING_INSTANCE_READ = 'blocking-instance.read',
	BLOCKING_INSTANCE_CREATE = 'blocking-instance.create',
	BLOCKING_INSTANCE_UPDATE = 'blocking-instance.update',
	BLOCKING_INSTANCE_DELETE = 'blocking-instance.delete',

	TRANSFER_READ = 'transfer.read',
	TRANSFER_UPDATE = 'transfer.update',
	TRANSFER_DELETE = 'transfer.delete',

	OPS_TOOL_ISIN_READ = 'ops-tool:isin.read',
	OPS_TOOL_KPI_READ = 'ops-tool:kpi.read',
	OPS_TOOL_CASHBACK_READ = 'ops-tool:cashback.read',
	OPS_TOOL_CASHBACK_UPDATE = 'ops-tool:cashback.update',

	MAILING_FRAMEWORK_READ = 'mailing-framework.read',
	MAILING_FRAMEWORK_CREATE = 'mailing-framework.create',
	MAILING_FRAMEWORK_UPDATE = 'mailing-framework.update',
	MAILING_FRAMEWORK_DELETE = 'mailing-framework.delete',

	// CPM
	CALENDAR_READ = 'calendar.read',

	PROPALE_READ = 'propale.read',
	PROPALE_CREATE = 'propale.create',
	PROPALE_UPDATE = 'propale.update', // (status, metadata, comments)
	PROPALE_DELETE = 'propale.delete',

	PROPALE_DEFISCALISATION_READ = 'propale-defiscalisation.read',
	PROPALE_DEFISCALISATION_CREATE = 'propale-defiscalisation.create',

	PROPALE_PRODUCT_READ = 'propale-product.read',
	PROPALE_PRODUCT_CREATE = 'propale-product.create',

	// QIS
	REBALANCING_READ = 'rebalancing.read',
	REBALANCING_UPDATE = 'rebalancing.update',

	// PHOENIX
	PHOENIX_READ = 'phoenix.read',
	PHOENIX_CREATE = 'phoenix.create',
	PHOENIX_AUTOLOGIN_READ = 'phoenix:autologin.read',
}
