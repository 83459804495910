export enum MatrimonialRegime {
	LEGAL_COMMUNITY = 'LEGAL_COMMUNITY',
	REDUCED_COMMUNITY_OF_ACQUESTS = 'REDUCED_COMMUNITY_OF_ACQUESTS',
	CONVENTIONAL_COMMUNITY = 'CONVENTIONAL_COMMUNITY',
	UNIVERSAL_COMMUNITY = 'UNIVERSAL_COMMUNITY',
	SEPARATION_OF_PROPERTY = 'SEPARATION_OF_PROPERTY',
	PARTICIPATION_IN_ACQUESTS = 'PARTICIPATION_IN_ACQUESTS',
	COMMUNITY_OF_MOVABLES_AND_ACQUESTS = 'COMMUNITY_OF_MOVABLES_AND_ACQUESTS',
	OTHER = 'OTHER',
}

const MatrimonialRegimeReferentiel: Record<MatrimonialRegime, string> = {
	LEGAL_COMMUNITY: 'Communauté légale',
	REDUCED_COMMUNITY_OF_ACQUESTS: 'Communauté réduite aux acquêts',
	CONVENTIONAL_COMMUNITY: 'Communauté conventionnelle',
	UNIVERSAL_COMMUNITY: 'Communauté universelle',
	SEPARATION_OF_PROPERTY: 'Séparation de biens',
	PARTICIPATION_IN_ACQUESTS: 'Participation aux acquêts',
	COMMUNITY_OF_MOVABLES_AND_ACQUESTS: 'Communauté de meubles et acquêts',
	OTHER: 'Spécifique, autre',
};

export default MatrimonialRegimeReferentiel;
