export enum IncomeOrigin {
	PROFESSIONAL_INCOME = 'PROFESSIONAL_INCOME', // Revenus professionnels
	PROPERTY_INCOME = 'PROPERTY_INCOME', // Revenus fonciers
	SECURITIES_INCOME = 'SECURITIES_INCOME', // Revenus de valeurs mobilières
	RETIREMENT_INCOME = 'RETIREMENT_INCOME', // Pensions, retraites et rentes
	// OTHER_INCOME = 'OTHER_INCOME', // Autre
}

const IncomeOriginReferentiel = {
	PROFESSIONAL_INCOME: 'Revenus professionnels',
	PROPERTY_INCOME: 'Revenus fonciers',
	SECURITIES_INCOME: 'Revenus de valeurs mobilières',
	RETIREMENT_INCOME: 'Pensions, retraites et rentes',
} satisfies Record<IncomeOrigin, string>;

export default IncomeOriginReferentiel;
