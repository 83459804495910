import { dashboardApi } from 'store/api';
import { CpmPropale, LeadClosingProbability, PropaleStatus, PropaleType } from 'types/cpm-propale';
import { ProductType } from 'types/global.type';
import { PortfolioType } from 'types/investmentPreferences.type';
import { isNone } from 'utils/functions';

type MailData = {
	subject: string;
	body: string;
	cc: string[];
	attachments?: {
		documentName: string;
		filename: string;
		content: string; // base64
	}[];
};

type CreatePropalDto = {
	assignedCpmEmail: string;
	email: string;
	firstName: string;
	lastName: string;
	notes: string;
	type: PropaleType;
	leadClosingProbability?: LeadClosingProbability;
	autoPropale?: {
		amount: number;
		investRisk: number;
		investTimeHorizon: number;
		investPortfolio: PortfolioType;
		investGreen: boolean;
		notionLink: string;
	};
	manualPropale?: {
		amount: number;
		deadline: string;
	};
	followUpPropale?: {
		subject: string;
		deadline: string;
	};
	mailData?: MailData;
	googleCalendarId?: string;
};

type UpdatePropalDto = {
	id: string;
	notes?: string;
	amount?: number;
	status?: PropaleStatus;
	assignedCpmEmail?: string;
	leadClosingProbability?: LeadClosingProbability;
	deadline?: string;
	notionLink?: string;
	type?: PropaleType;
	investRisk?: number;
	investPortfolio?: PortfolioType;
	investGreen?: boolean;
	mailData?: MailData;
};

type UpdatePropaleLinkedEntities = {
	id: string;
	ids: Record<ProductType, string[]>;
};

type GenerateNotionPage = {
	risk: number;
	portfolio: PortfolioType;
	green: boolean;
	firstName: string;
	lastName: string;
};

const endpoints = dashboardApi.injectEndpoints({
	endpoints: (builder) => ({
		getPropales: builder.query<CpmPropale[], { searchBy?: string; input?: string; assignedCpmEmail?: string }>({
			query: (queryParams) => ({
				url: '/bo/cpm/propales',
				params: queryParams,
				method: 'GET',
			}),
			providesTags: (res) => (isNone(res) ? ['PROPALES'] : res.map((d) => ({ type: 'PROPALES', id: d.id }))),
		}),
		getPropaleById: builder.query<CpmPropale, string>({
			query: (id) => `/bo/cpm/propales/id/${id}`,
			providesTags: (res) => [{ type: 'PROPALES', id: res?.id }],
		}),
		createPropale: builder.mutation<CpmPropale, CreatePropalDto>({
			query: (body) => ({
				url: `/bo/cpm/propales`,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['PROPALES'],
		}),
		getNotionLink: builder.query<{ link: string }, GenerateNotionPage>({
			query: (params) => ({
				url: `/bo/cpm/propales/notion-link`,
				params: params,
			}),
		}),
		sendAutoPropales: builder.mutation<void, string>({
			query: (id) => ({
				url: `/bo/cpm/propales/send-auto/${id}`,
				method: 'PUT',
			}),
			invalidatesTags: (res, err, id) => [{ type: 'PROPALES', id }],
		}),
		updatePropale: builder.mutation<CpmPropale, UpdatePropalDto>({
			query: ({ id, ...body }) => ({
				url: `/bo/cpm/propales/${id}`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: (res) => [{ type: 'PROPALES', id: res?.id }],
		}),
		updatePropaleLinkedEntities: builder.mutation<void, UpdatePropaleLinkedEntities>({
			query: ({ id, ids }) => ({
				url: `/bo/cpm/propales/link-subscriptions/${id}`,
				method: 'PUT',
				body: ids,
			}),
		}),
		deletePropale: builder.mutation<void, string>({
			query: (id) => ({
				url: `/bo/cpm/propales/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: (res, err, id) => [{ type: 'PROPALES', id }],
		}),
		createPropaleComment: builder.mutation<void, { id: string; content: string }>({
			query: ({ id, content }) => ({
				url: `/bo/cpm/propales/comment/${id}`,
				method: 'POST',
				body: { content },
			}),
			invalidatesTags: ['PROPALES'],
		}),
		deletePropaleComment: builder.mutation<void, { id: string; commentId: string }>({
			query: ({ id, commentId }) => ({
				url: `/bo/cpm/propales/comment/${id}`,
				method: 'DELETE',
				body: { commentId },
			}),
			invalidatesTags: (res, err, { id }) => [{ type: 'PROPALES', id }],
		}),
	}),
});

export const {
	useGetPropalesQuery,
	useGetPropaleByIdQuery,
	useCreatePropaleMutation,
	useLazyGetNotionLinkQuery,
	useSendAutoPropalesMutation,
	useUpdatePropaleMutation,
	useUpdatePropaleLinkedEntitiesMutation,
	useDeletePropaleMutation,

	// comments
	useCreatePropaleCommentMutation,
	useDeletePropaleCommentMutation,
} = endpoints;
