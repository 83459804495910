import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
	frontEggToken: string | null;
}

const initialState: AuthState = {
	frontEggToken: localStorage.getItem('fronteggToken'),
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setFrontEggToken(state, action: PayloadAction<string | null>) {
			state.frontEggToken = action.payload;
			if (!action.payload) localStorage.removeItem('fronteggToken');
			else localStorage.setItem('fronteggToken', action.payload);
		},
	},
});

export const { setFrontEggToken } = authSlice.actions;

export default authSlice.reducer;
