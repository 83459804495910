export enum ProfessionalSector {
	ADMINISTRATIVE_SUPPORT_ACTIVITIES = 'ADMINISTRATIVE_SUPPORT_ACTIVITIES',
	ACTIVITES_OF_HOUSEHOLDS_AND_SERVICES_FOR_OWN_USE = 'ACTIVITES_OF_HOUSEHOLDS_AND_SERVICES_FOR_OWN_USE',
	EXTRA_TERRITORIAL_ACTIVITIES = 'EXTRA_TERRITORIAL_ACTIVITIES',
	FINANCIAL_AND_INSURANCE_ACTIVITIES = 'FINANCIAL_AND_INSURANCE_ACTIVITIES',
	REAL_ESTATE_ACTIVITIES = 'REAL_ESTATE_ACTIVITIES',
	PROFESSIONAL_SCIENTIFIC_AND_TECHNICAL_ACTIVITIES = 'PROFESSIONAL_SCIENTIFIC_AND_TECHNICAL_ACTIVITIES',
	PUBLIC_ADMINISTRATION = 'PUBLIC_ADMINISTRATION',
	AGRICULTURE_FORESTRY_AND_FISHING = 'AGRICULTURE_FORESTRY_AND_FISHING',
	ARTS_ENTERTAINMENT_AND_RECREATION = 'ARTS_ENTERTAINMENT_AND_RECREATION',
	OTHER_SERVICE_ACTIVITIES = 'OTHER_SERVICE_ACTIVITIES',
	TRADE_REPAIR_OF_AUTOMOBILES_AND_MOTORCYCLES = 'TRADE_REPAIR_OF_AUTOMOBILES_AND_MOTORCYCLES',
	CONSTRUCTION = 'CONSTRUCTION',
	EDUCATION = 'EDUCATION',
	ACCOMMODATION_AND_FOOD_SERVICES = 'ACCOMMODATION_AND_FOOD_SERVICES',
	MANUFACTURING_INDUSTRY = 'MANUFACTURING_INDUSTRY',
	MINING_AND_QUARRYING = 'MINING_AND_QUARRYING',
	INFORMATION_AND_COMMUNICATION = 'INFORMATION_AND_COMMUNICATION',
	WATER_PRODUCTION_AND_DISTRIBUTION_SANITATION_WASTE_MANAGEMENT = 'WATER_PRODUCTION_AND_DISTRIBUTION_SANITATION_WASTE_MANAGEMENT',
	PRODUCTION_AND_DISTRIBUTION_OF_ELECTRICITY_GAS_STEAM = 'PRODUCTION_AND_DISTRIBUTION_OF_ELECTRICITY_GAS_STEAM',
	HUMAN_HEALTH_AND_SOCIAL_WORK = 'HUMAN_HEALTH_AND_SOCIAL_WORK',
	TRANSPORT_AND_STORAGE = 'TRANSPORT_AND_STORAGE',
}

const ProfessionalSectorReferentiel: Record<ProfessionalSector, string> = {
	ADMINISTRATIVE_SUPPORT_ACTIVITIES: 'Activités de services administratifs et de soutien',
	ACTIVITES_OF_HOUSEHOLDS_AND_SERVICES_FOR_OWN_USE: "Activités des ménages en tant qu'employeurs",
	EXTRA_TERRITORIAL_ACTIVITIES: 'Activités extra-territoriales',
	FINANCIAL_AND_INSURANCE_ACTIVITIES: "Activités financières et d'assurance",
	REAL_ESTATE_ACTIVITIES: 'Activités immobilières',
	PROFESSIONAL_SCIENTIFIC_AND_TECHNICAL_ACTIVITIES: 'Activités spécialisées, scientifiques et techniques',
	PUBLIC_ADMINISTRATION: 'Administration publique',
	AGRICULTURE_FORESTRY_AND_FISHING: 'Agriculture, sylviculture et pêche',
	ARTS_ENTERTAINMENT_AND_RECREATION: 'Arts, spectacles et activités récréatives',
	OTHER_SERVICE_ACTIVITIES: 'Autres activités de services',
	TRADE_REPAIR_OF_AUTOMOBILES_AND_MOTORCYCLES: "commerce, réparation d'automobile et de motocycles",
	CONSTRUCTION: 'Construction',
	EDUCATION: 'Enseignement',
	ACCOMMODATION_AND_FOOD_SERVICES: 'Hébergement et restauration',
	MANUFACTURING_INDUSTRY: 'Industrie manufacturière',
	MINING_AND_QUARRYING: 'Industries extractives',
	INFORMATION_AND_COMMUNICATION: 'Information et communication',
	WATER_PRODUCTION_AND_DISTRIBUTION_SANITATION_WASTE_MANAGEMENT:
		"Production et distribution d'eau, assainissement, gestion des déchets et dépollution",
	PRODUCTION_AND_DISTRIBUTION_OF_ELECTRICITY_GAS_STEAM:
		"Production et distribution d'électricité, de gaz, de vapeur et d'air conditionné",
	HUMAN_HEALTH_AND_SOCIAL_WORK: 'Santé humaine et action sociale',
	TRANSPORT_AND_STORAGE: 'Transports et entreposage',
};

export default ProfessionalSectorReferentiel;
