import { FC, useCallback, useMemo } from 'react';
import { Button, HStack, Skeleton, Switch, VStack } from '@chakra-ui/react';

import FilterPopovers, { FILTER_POPOVERS } from 'components/filters';
import SearchByFilter, { SearchBy, searchByFunc } from 'components/filters/SearchBy';
import DealsTable from 'components/tables/DealsTable';
import { isDealOverdue } from 'features/ChangeOverdueDate';
import { AllDeal, useClearDealsCacheMutation, useGetDealsQuery } from 'services/deal';
import { useGetStatusesQuery } from 'services/ops/status';
import { BOContext, LegalEntity, ProductType, SubscriptionStatus } from 'types/global.type';
import { SavingsAccountType } from 'types/savingsAccount.type';

import useDealFilters, { productOptions } from './utils';

type DealListProps = {
	context: Extract<BOContext, 'client' | 'subscription' | 'deal' | 'blocking-instance'>;
	selectedDeal?: AllDeal; // for display purpose
	onClick?: (c: AllDeal) => void;
	pageSize?: number;
	customSearchBy?: SearchBy; // force filter at route level
	customInput?: string; // force filter at route level
};

const DealList: FC<DealListProps> = ({ selectedDeal, onClick, pageSize, customSearchBy, customInput, context }) => {
	const {
		partnerFilter,
		setPartnerFilter,
		statusFilter,
		setStatusFilter,
		clientTypeFilter,
		setClientTypeFilter,
		opsFilter,
		setOpsFilter,
		onlyOverdue,
		setOnlyOverdue,
		includeDealsWithBI,
		setIncludeDealsWithBI,
		input,
		setInput,
		searchBy,
		setSearchBy,
		productFilter,
		setProductFilter,
		resetFilters,
	} = useDealFilters(customSearchBy, customInput);

	const [clearDealsCache] = useClearDealsCacheMutation();
	const handleClearDealsCache = useCallback(() => clearDealsCache().unwrap(), [clearDealsCache]);

	const { data: deals, isFetching: isSubsFetching } = useGetDealsQuery(
		{
			searchBy: customSearchBy ?? 'email',
			input: customInput ?? '',
		},
		{ refetchOnFocus: true, refetchOnReconnect: true, pollingInterval: 300000 },
	);
	const { data: productTypeStatuses } = useGetStatusesQuery();
	const partnerOptions = useMemo(() => [...new Set(deals?.map((d) => d.partner))].sort(), [deals]);

	const opsOptions = useMemo(
		() => [...new Set(deals?.map((d) => d.opsProperties?.assignedOpsEmail ?? ''))].filter((o) => o.length > 0).sort(),
		[deals],
	);

	return (
		<VStack w="100%" align="start">
			{(context === 'deal' || context === 'blocking-instance') && (
				<HStack w="100%" justify="space-between">
					<HStack w="100%">
						<SearchByFilter
							search={input}
							onChangeSearch={setInput}
							searchBy={searchBy}
							onChangeSearchBy={setSearchBy}
							isFetching={isSubsFetching}
							onClearCache={handleClearDealsCache}
						/>
						<FilterPopovers
							components={[
								{
									component: FILTER_POPOVERS.BASIC,
									title: 'Produit',
									componentProps: {
										value: productFilter,
										onChange: setProductFilter,
										options: productOptions,
									},
								},
								{
									title: 'Statut',
									componentProps: {
										value: statusFilter,
										onChange: (v: string[]) => setStatusFilter(v as SubscriptionStatus[]),
										options: Object.values(SubscriptionStatus).filter(
											(s) => s !== SubscriptionStatus.PARTNER_TREATMENT,
										),
									},
								},
								{
									title: 'Client type',
									componentProps: {
										onChange: setClientTypeFilter,
										options: ['isBlack', 'isPhoenix', 'isCorporate'],
										value: clientTypeFilter,
									},
								},
								{
									component: FILTER_POPOVERS.PARTNER,
									title: 'Partenaire',
									componentProps: {
										onChange: setPartnerFilter,
										options: partnerOptions,
										value: partnerFilter,
									},
								},
								{
									component: FILTER_POPOVERS.OPS,
									title: 'OPS',
									componentProps: {
										onChange: setOpsFilter,
										options: opsOptions,
										value: opsFilter,
									},
								},
							]}
						/>
						<Button
							_hover={{ cursor: 'auto' }}
							rightIcon={<Switch isChecked={onlyOverdue} onChange={(event) => setOnlyOverdue(event.target.checked)} />}
						>
							Overdue
						</Button>
						<Button
							_hover={{ cursor: 'auto' }}
							rightIcon={
								<Switch
									isChecked={includeDealsWithBI}
									onChange={(event) => setIncludeDealsWithBI(event.target.checked)}
								/>
							}
						>
							Instances
						</Button>
					</HStack>
					<Button
						variant="outline"
						onClick={() => {
							resetFilters();
						}}
					>
						Reset filters
					</Button>
				</HStack>
			)}

			<Skeleton isLoaded={!isSubsFetching} w="100%">
				<DealsTable
					context={context}
					productTypeStatuses={productTypeStatuses}
					deals={(deals ?? [])
						.filter((s) => searchByFunc(s, searchBy, input))
						.filter((s) => {
							if (s.productType === ProductType.CASH && s.type === SavingsAccountType.CER)
								return productFilter.includes('CER');
							if (s.productType === ProductType.CASH && s.type === SavingsAccountType.CAT)
								return productFilter.includes(s.legalEntity === LegalEntity.MORAL ? 'CATPM' : 'CATPP');
							else if (s.productType === ProductType.INVEST && s.dealType === 'SUBSCRIPTION')
								return productFilter.includes('INVEST_SUB');
							else if (s.productType === 'INVEST' && s.dealType === 'CONTRACT')
								return productFilter.includes(s.structuredProduct.length > 0 ? 'SP' : 'INVEST_CON');
							else return productFilter.includes(s.productType);
						})
						.filter((s) => {
							if (clientTypeFilter.length === 0) return true;
							return (
								(clientTypeFilter.includes('isBlack') && s.user.isBlack) ||
								(clientTypeFilter.includes('isPhoenix') && s.user.isPhoenix) ||
								(clientTypeFilter.includes('isCorporate') && s.user.isCorporate)
							);
						})
						.filter((s) => (statusFilter?.length === 0 ? true : statusFilter?.includes(s.status as SubscriptionStatus)))
						.filter((s) => (partnerFilter.length === 0 ? true : partnerFilter.includes(s.partner)))
						.filter((s) =>
							opsFilter.length === 0 ? true : opsFilter.includes(s.opsProperties?.assignedOpsEmail ?? ''),
						)
						.filter((s) => (!includeDealsWithBI ? !s.hasBlockingInstance : true))
						.filter((s) => (onlyOverdue ? isDealOverdue(s, productTypeStatuses) : true))}
					onClick={onClick}
					selectedDeal={selectedDeal}
					pageSize={pageSize ?? 50}
				/>
			</Skeleton>
		</VStack>
	);
};

export default DealList;
