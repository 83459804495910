import { FC } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { Collapse, Heading, HStack, Icon, useDisclosure, VStack } from '@chakra-ui/react';

type CardlayoutProps = {
	title: string;
	defaultIsOpen?: boolean;
	children: React.ReactNode;
};

const Cardlayout: FC<CardlayoutProps> = ({ title, defaultIsOpen, children }) => {
	const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: defaultIsOpen ?? true });

	return (
		<VStack
			align="start"
			w="100%"
			p="24px"
			spacing="16px"
			bg="white"
			borderRadius="12px"
			borderWidth="1px"
			borderColor="grey.100"
		>
			<HStack w="100%" justify="space-between" onClick={onToggle} cursor="pointer">
				<Heading size="md">{title}</Heading>
				<Icon as={isOpen ? ChevronDownIcon : ChevronUpIcon} boxSize="24px" />
			</HStack>

			<Collapse in={isOpen} animateOpacity style={{ width: '100%', overflow: 'visible' }}>
				{children}
			</Collapse>
		</VStack>
	);
};

export default Cardlayout;
