import { useCallback, useMemo, useState } from 'react';
import { Calendar, Event, EventPropGetter, momentLocalizer } from 'react-big-calendar';
import { useNavigate } from 'react-router-dom';
import { Box, Heading, useDisclosure, VStack } from '@chakra-ui/react';
import { useAuth } from '@frontegg/react';
import moment from 'moment';

import CustomDrawer from 'components/Drawer';
import CreatePropale from 'pages/cpm/super/propale/PropaleCreate';
import { useGetCalendarQuery } from 'services/cpm/calendar';
import { useGetPropalesQuery } from 'services/cpm/propale';
import { isNotNone } from 'utils/functions';

import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);
const todayAtHeighAM = new Date(new Date().setHours(8, 0, 0, 0));
const todayAtTenPM = new Date(new Date().setHours(22, 0, 0, 0));

const CalendarCpm = () => {
	const navigate = useNavigate();
	const { isOpen, onOpen, onClose } = useDisclosure();

	const { user } = useAuth();
	const { data } = useGetCalendarQuery('month');
	const { data: propales } = useGetPropalesQuery({ assignedCpmEmail: user?.email }, { skip: !user });
	const [selectedEvent, setSelectedEvent] = useState<Event>();

	const formattedEvents = useMemo(() => {
		if (!data) return [];
		return data.map((event) => ({
			start: new Date(event.start.dateTime),
			end: new Date(event.end.dateTime),
			title: event.summary
				.replace(': Échange avec un conseiller privé Ramify', '')
				.replace('Échange avec un conseiller privé Ramify - ', '')
				.replace('Échange avec un conseiller Ramify - ', ''),
			resource: {
				id: event.id,
				description: event.description,
				attendees: event.attendees,
				hangoutLink: event.hangoutLink,
			},
		})) satisfies Event[];
	}, [data]);

	const customEventColor = useCallback<EventPropGetter<Event>>(
		(event, start, end) => {
			const now = new Date();
			const hasCreatedPropale = propales?.some((propale) => propale.googleCalendarId === event.resource.id);

			if (now > end && !hasCreatedPropale) return { style: { backgroundColor: 'red' } };
			else if (now > end && hasCreatedPropale) return { style: { backgroundColor: 'lightgrey', color: 'black' } };
			else return {};
		},
		[propales],
	);

	const handleSelectEvent = useCallback(
		(e: Event) => {
			const foundPropale = propales?.find((propale) => propale.googleCalendarId === e.resource.id);
			if (isNotNone(foundPropale)) {
				navigate(`/cpm/super/propale/${foundPropale.id}`);
			} else {
				setSelectedEvent(e);
				onOpen();
			}
		},
		[navigate, onOpen, propales],
	);

	return (
		<>
			<CustomDrawer
				header={<Heading size="md">{selectedEvent?.title}</Heading>}
				isOpen={isOpen}
				onClose={() => {
					onClose();
					setSelectedEvent(undefined);
				}}
			>
				<CreatePropale context="meeting" event={selectedEvent} onClose={onClose} />
			</CustomDrawer>

			<VStack w="100%" spacing="24px" align="start">
				<Heading size="lg">Meetings</Heading>
				<Box w="100%">
					<Calendar
						localizer={localizer}
						defaultView="day"
						events={formattedEvents}
						min={todayAtHeighAM}
						max={todayAtTenPM}
						startAccessor="start"
						endAccessor="end"
						eventPropGetter={customEventColor} // custom color for events
						onSelectEvent={handleSelectEvent}
						style={{ height: 700 }}
					/>
				</Box>
			</VStack>
		</>
	);
};

export default CalendarCpm;
