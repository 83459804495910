import { FC, PropsWithChildren } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth, usePermissionEntitlements } from '@frontegg/react';

import { setFrontEggToken } from 'store/auth.slice';
import { BoPermission } from 'utils/permissions';

export const RedirectFromLocation: FC<{ to: string }> = ({ to }) => {
	const location = useLocation();

	return <Navigate to={to} state={{ from: location }} replace />;
};

export const AuthenticatedGuard: FC = () => {
	const { isAuthenticated, user } = useAuth();
	const dispatch = useDispatch();

	if (!isAuthenticated) return <RedirectFromLocation to="/account/login" />;
	dispatch(setFrontEggToken(user?.accessToken || null));

	return <Outlet />;
};

export const PermissionGuard: FC<{ permission: BoPermission }> = ({ permission }) => {
	const { isEntitled: isPermissionEntitled } = usePermissionEntitlements(permission);

	return isPermissionEntitled ? <Outlet /> : <RedirectFromLocation to="/" />;
};

export const PermissionDisplayGuard: FC<PropsWithChildren & { permission: BoPermission }> = ({
	children,
	permission,
}) => {
	const { isEntitled } = usePermissionEntitlements(permission);
	return isEntitled ? children : null;
};
