import { useMemo, useState } from 'react';
import { Button, chakra, HStack, Table, TableContainer, Tbody, Thead, Tr } from '@chakra-ui/react';
import fileDownload from 'js-file-download';

import CustomModal from 'components/Modal';
import { ButtonTd, CheckboxTd, DefaultTd } from 'components/tables/columns/Td';
import { DefaultTh } from 'components/tables/columns/Th';
import { RamifyDoc } from 'services/document';
import { documentDataMap, DocumentName } from 'utils/documentNamingMap';
import { isNotNone } from 'utils/functions';

type DocumentsTableProps = {
	docList: RamifyDoc[];
	hideSelect: boolean;
	selectedDocs?: RamifyDoc[];
	setSelectedDocs?: (d: RamifyDoc[]) => void;
};

const DocumentsTable = ({ docList, selectedDocs, setSelectedDocs, hideSelect }: DocumentsTableProps) => {
	const [selectedFile, setSelectedFile] = useState<RamifyDoc>();

	const selectedFileName = useMemo(
		() => docList.find((d) => d.url === selectedFile?.url)?.documentName as DocumentName,
		[docList, selectedFile?.url],
	);

	return (
		<>
			<TableContainer w="100%">
				<Table variant="simple" size="sm">
					<Thead>
						<Tr>
							<DefaultTh>Nom</DefaultTh>
							<DefaultTh>Type</DefaultTh>
							<DefaultTh>Dossier</DefaultTh>
							<DefaultTh>Visualiser</DefaultTh>
							<DefaultTh>Télécharger</DefaultTh>
							<DefaultTh hide={hideSelect}>Sélectionner</DefaultTh>
						</Tr>
					</Thead>

					<Tbody>
						{docList.map((doc) => (
							<Tr key={doc.url}>
								<DefaultTd>{documentDataMap[doc.documentName]?.displayName ?? doc.documentName}</DefaultTd>
								<DefaultTd>{doc.contentType?.split('/').at(-1)}</DefaultTd>
								<DefaultTd maxW="400px">{doc.folder}</DefaultTd>

								<ButtonTd onClick={() => setSelectedFile(doc)}>Visualiser</ButtonTd>

								<ButtonTd
									onClick={() =>
										fetch(doc.url)
											.then((res) => res.blob())
											.then((res) => fileDownload(res, doc.filename))
									}
								>
									Télécharger
								</ButtonTd>

								<CheckboxTd
									hideCell={hideSelect}
									isChecked={isNotNone(selectedDocs) ? selectedDocs.map((d) => d.url).includes(doc.url) : false}
									onChange={(v) =>
										setSelectedDocs && selectedDocs
											? setSelectedDocs(
													v.target.checked
														? [...selectedDocs, doc]
														: selectedDocs.filter((d) => d.url !== doc.url) ?? [],
											  )
											: undefined
									}
								/>
							</Tr>
						))}
					</Tbody>
				</Table>
			</TableContainer>

			<CustomModal
				size="full"
				isOpen={isNotNone(selectedFile)}
				onClose={() => setSelectedFile(undefined)}
				contentProps={{ style: { display: 'flex', height: 900 } }}
				scrollBehavior="inside"
				headersProps={{ children: documentDataMap[selectedFileName]?.displayName ?? selectedFileName }}
				footerProps={{
					children: (
						<HStack>
							<Button
								onClick={() => {
									const index = docList.findIndex((d) => d.url === selectedFile?.url);
									setSelectedFile(docList[index - 1 < 0 ? docList.length - 1 : index - 1]);
								}}
							>
								Précédent
							</Button>
							<Button
								onClick={() => {
									const index = docList.findIndex((d) => d.url === selectedFile?.url);
									setSelectedFile(docList[index + 1 > docList.length - 1 ? 0 : index + 1]);
								}}
							>
								Suivant
							</Button>
						</HStack>
					),
				}}
			>
				{selectedFile?.contentType?.includes('image') ? (
					<chakra.img src={selectedFile?.url} m="auto" />
				) : (
					<chakra.iframe src={selectedFile?.url} w="100%" h="100%" />
				)}
			</CustomModal>
		</>
	);
};

export default DocumentsTable;
