import { FC } from 'react';
import { Checkbox, HStack, MenuItem, Text } from '@chakra-ui/react';

const CheckList: FC<{
	options: string[];
	values: string[];
	onChange: (newValue: string, isChecked: boolean) => void;
	display?: Record<string, Record<string, string>>;
}> = ({ options, values, onChange, display }) => (
	<>
		{options.map((value) => {
			const isChecked = values.includes(value);
			return (
				<MenuItem w="100%" h="40px" closeOnSelect={false} key={value} onClick={() => onChange(value, !isChecked)}>
					<HStack w="100%" justify="space-between">
						<Text>{display ? display[value].display : value}</Text>
						<Checkbox
							w="30px"
							h="30px"
							bg="none"
							borderColor={isChecked ? 'yellow.500' : 'gray.300'}
							variant="full"
							isChecked={isChecked}
							pointerEvents="none"
						/>
					</HStack>
				</MenuItem>
			);
		})}
	</>
);

export default CheckList;
