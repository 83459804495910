import { FC, useEffect, useState } from 'react';
import { CheckIcon, CloseIcon, CopyIcon } from '@chakra-ui/icons';
import { Button, Center, Divider, HStack, Icon, Text, useDisclosure, VStack } from '@chakra-ui/react';

import CustomDrawer from 'components/Drawer';
import DictDisplayTable from 'components/tables/DictDisplayTable';
import { isIsoDate } from 'utils/functions';

import { SubCategoryValue } from '.';

interface SubCategorySectionProps {
	values: SubCategoryValue[];
	hideEmpty?: boolean;
}

const SubCategorySection: FC<SubCategorySectionProps> = ({ values, hideEmpty }) => {
	const [copyText, setCopyText] = useState<string | null | number | Date>(null);

	const formatValue = (value: string | boolean | Date | number | undefined) => {
		if (typeof value === 'string' && isIsoDate(value)) return new Date(value).toLocaleDateString('fr-FR');
		return value?.toString();
	};

	useEffect(() => {
		if (copyText) {
			const timeout = setTimeout(() => setCopyText(null), 2000);
			return () => clearTimeout(timeout);
		}
	}, [copyText]);

	const [selectedField, setSelectedField] = useState<string | null>(null);
	const [selectedValue, setSelectedValue] = useState<any | null>(null);
	const { isOpen, onOpen, onClose } = useDisclosure();

	return (
		<>
			{selectedField && (
				<CustomDrawer header={selectedField} isOpen={isOpen} onClose={onClose}>
					<DictDisplayTable dict={selectedValue} />
				</CustomDrawer>
			)}

			<VStack w="100%" spacing="0px">
				{values.map((item, index) => {
					if ('title' in item) {
						return (
							<VStack key={index} w="100%" align="center" spacing="0px">
								<Text as="b">{item.title}</Text>
								<Divider orientation="horizontal" />
							</VStack>
						);
					}

					if ('key' in item && 'value' in item) {
						const { key, value } = item;
						return (
							<VStack
								key={index}
								w="100%"
								align="center"
								spacing="0px"
								_hover={{ borderRadius: '4px', bg: '#F3F4F6' }}
								cursor="pointer"
								onClick={() => {
									if (value && typeof value !== 'object') {
										if (typeof value === 'boolean') return;
										setCopyText(value);
										navigator.clipboard.writeText(formatValue(value) || '');
									}
								}}
							>
								{(hideEmpty ? true : value) && (
									<>
										<HStack w="100%" justify="space-between" p="0px" h="26px" align="center">
											<Text>{key}</Text>
											<HStack spacing="24px">
												{typeof value === 'boolean' ? (
													<Center w="36px" h="36px" borderRadius="full">
														<Icon as={value ? CheckIcon : CloseIcon} color={value ? 'green' : 'red'} boxSize="16px" />
													</Center>
												) : typeof value === 'object' && value ? (
													<Button
														size="xs"
														onClick={() => {
															setSelectedField(key ? key : '');
															setSelectedValue(value);
															onOpen();
														}}
													>
														Voir plus
													</Button>
												) : (
													value && (
														<>
															<Text>{formatValue(value)}</Text>
															<Center
																w="26px"
																h="26px"
																borderRadius="full"
																bg={copyText === value ? '#D1FAE5' : '#F3F4F6'}
																_hover={{ bg: '#E2E8F0' }}
															>
																{copyText === value ? (
																	<CheckIcon color="green" boxSize="14px" />
																) : (
																	<CopyIcon boxSize="14px" />
																)}
															</Center>
														</>
													)
												)}
											</HStack>
										</HStack>
										<Divider orientation="horizontal" />
									</>
								)}
							</VStack>
						);
					}
				})}
			</VStack>
		</>
	);
};

export default SubCategorySection;
