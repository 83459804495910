import React, { FC, useCallback, useRef, useState } from 'react';
import { CloseIcon } from '@chakra-ui/icons';
import { Button, chakra, HStack, Input } from '@chakra-ui/react';

import CustomModal from 'components/Modal';
import { ButtonTd, DefaultTd } from 'components/tables/columns/Td';
import { RamifyDoc } from 'services/document';
import { useUpdateBankAccountJustificatifMutation } from 'services/ops/bank-account';
import { BankInformation } from 'types/bank-information.type';
import { isNotNone } from 'utils/functions';
import { PermissionDisplayGuard } from 'utils/guards';
import { BoPermission } from 'utils/permissions';

export type BankingDocumentsProps = {
	bank: BankInformation;
	userDocuments: RamifyDoc[] | undefined;
	isFetchingUserDocuments: boolean;
};

const BankingDocuments: FC<BankingDocumentsProps> = ({ userDocuments, isFetchingUserDocuments, bank }) => {
	// PREVIEW DOC
	const [docToPreview, setDocToPreview] = useState<RamifyDoc>();
	const handlePreviewDoc = useCallback(
		(bankId: string) => {
			if (!userDocuments) return;

			const parsedDocument = userDocuments.find((doc) => doc.key.includes(bankId));
			setDocToPreview(parsedDocument);
		},
		[userDocuments],
	);

	// UPDATE USER BANK DOCUMENT
	const [updateUserBankAccount, { isLoading: isLoadingUpdateUserBankAccount }] =
		useUpdateBankAccountJustificatifMutation();
	const inputFile = useRef<HTMLInputElement | null>(null);
	const [fileUploadData, setFileUploadData] = useState<{ bankId: string; file: FileList } | null>(null);
	const resetFileInput = () => {
		setFileUploadData(null);
		inputFile.current!.value = '';
	};
	const handleUpdateBankAccount = useCallback(
		async ({ file, bankId }: { file: FileList; bankId: string }) => {
			const formData = new FormData();
			formData.append('file', file[0]);

			await updateUserBankAccount({ bankId, body: formData });
			resetFileInput();
		},
		[updateUserBankAccount],
	);

	return (
		<>
			{!!userDocuments ? (
				<>
					<ButtonTd buttonStyleProps={{ colorScheme: 'blue' }} onClick={() => handlePreviewDoc(bank.id)}>
						Visualiser
					</ButtonTd>

					<PermissionDisplayGuard permission={BoPermission.CLIENT_BANK_ACCOUNT_UPDATE}>
						<DefaultTd>
							<HStack>
								<Button
									size="xs"
									colorScheme="blue"
									isLoading={isFetchingUserDocuments}
									onClick={() => inputFile.current?.click()}
								>
									Mettre à jour
								</Button>
								<Input
									type="file"
									ref={inputFile}
									display="none"
									onChange={(e) => {
										if (e.target.files) setFileUploadData({ file: e.target.files, bankId: bank.id });
									}}
								/>
								{isNotNone(fileUploadData) && bank.id === fileUploadData.bankId && (
									<>
										<Button
											size="xs"
											colorScheme="blue"
											isLoading={isLoadingUpdateUserBankAccount}
											onClick={() => handleUpdateBankAccount(fileUploadData)}
										>
											Uploader {fileUploadData.file[0].name ?? ''}
										</Button>
										<CloseIcon cursor="pointer" boxSize="12px" onClick={() => resetFileInput()} />
									</>
								)}
							</HStack>
						</DefaultTd>
					</PermissionDisplayGuard>
				</>
			) : (
				<>
					<ButtonTd buttonStyleProps={{ colorScheme: 'red', isLoading: isFetchingUserDocuments }} onClick={undefined}>
						Indisponible
					</ButtonTd>
					<ButtonTd buttonStyleProps={{ colorScheme: 'red', isLoading: isFetchingUserDocuments }} onClick={undefined}>
						Indisponible
					</ButtonTd>
				</>
			)}

			{/*FILES PREVIEW*/}
			<CustomModal
				size="full"
				scrollBehavior="inside"
				isOpen={isNotNone(docToPreview)}
				onClose={() => setDocToPreview(undefined)}
				contentProps={{ style: { display: 'flex', height: 900 } }}
				headersProps={{ children: docToPreview?.documentName || '' }}
			>
				{docToPreview?.contentType?.includes('image') ? (
					<chakra.img src={docToPreview?.url} m="auto" />
				) : (
					<chakra.iframe src={docToPreview?.url} w="100%" h="100%" />
				)}
			</CustomModal>
		</>
	);
};

export default BankingDocuments;
