import { FC, useEffect, useState } from 'react';
import { Button, HStack, Select, Text, useDisclosure, VStack } from '@chakra-ui/react';

import AlertDialog from 'components/AlertDialog';
import useThemedToast from 'hooks/useThemedToast';
import { useGetClientByEmailQuery, useUpdateInvestorCategoryMutation } from 'services/client';
import { InvestorCategory } from 'types/user.type';

type UpdateInvestorCategoryProps = {
	email: string;
};

const UpdateInvestorCategory: FC<UpdateInvestorCategoryProps> = ({ email }) => {
	const toast = useThemedToast();
	const { isOpen: isValidationOpen, onOpen: openValidation, onClose: closeValidation } = useDisclosure();

	const [investorCategory, setInvestorCategory] = useState<InvestorCategory>();

	const { data: client } = useGetClientByEmailQuery(email);
	const [updateInvestorCategory, { isLoading: isUpdateLoading }] = useUpdateInvestorCategoryMutation();

	const handleInvestorCategoryUpdate = async () => {
		updateInvestorCategory({ email, investorCategory: investorCategory! })
			.unwrap()
			.then(() => {
				toast({ status: 'success', title: "Catégorie d'investisseur mise à jour avec succès" });
				closeValidation();
				setInvestorCategory(undefined);
			})
			.catch((err) => toast({ status: 'error', title: 'Erreur', description: err.data.message }));
	};

	useEffect(() => {
		if (client?.investorCategory) setInvestorCategory(client.investorCategory);
	}, [client]);

	return (
		<VStack align="start" spacing="32px">
			<Text>
				Statut du client : <b>{client?.investorCategory}</b>
			</Text>

			<HStack align="start">
				<HStack>
					<Select
						value={investorCategory}
						onChange={(e) => setInvestorCategory(e.target.value as InvestorCategory)}
						isDisabled={!email}
					>
						{Object.values(InvestorCategory).map((type) => (
							<option key={type} value={type}>
								{type}
							</option>
						))}
					</Select>
				</HStack>

				<Button colorScheme="blue" alignSelf="end" onClick={openValidation}>
					Valider
				</Button>
			</HStack>

			<AlertDialog
				isOpen={isValidationOpen}
				onClose={closeValidation}
				header="Modifier la catégorie investisseur d'un client"
				body={
					<Text>
						Vous êtes sur le point de mettre à jour le status légal du client <b>{email}</b> à <b>{investorCategory}</b>
						<br />
						<br />
						Êtes-vous sûr de vouloir continuer ?
					</Text>
				}
				footer={
					<>
						<Button onClick={() => closeValidation()}>Annuler</Button>
						<Button colorScheme="red" onClick={() => handleInvestorCategoryUpdate()} ml={3} isLoading={isUpdateLoading}>
							Valider
						</Button>
					</>
				}
			/>
		</VStack>
	);
};

export default UpdateInvestorCategory;
