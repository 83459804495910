import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { Box, BoxProps } from '@chakra-ui/react';

import 'react-datepicker/dist/react-datepicker.css';

const DatePicker = (props: Omit<BoxProps, keyof ReactDatePickerProps> & ReactDatePickerProps) => (
	<Box
		as={ReactDatePicker}
		p="4px"
		border="1px solid lightgray"
		borderRadius="4px"
		dateFormat="dd/MM/yyyy"
		{...props}
	/>
);

export default DatePicker;
