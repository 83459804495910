import { Navigate, Route, Routes } from 'react-router-dom';
import { Heading, VStack } from '@chakra-ui/react';

import TransferList from 'pages/ops/super/transfer/TransferList';

import { TransferDetails } from './TransferDetails';

const TransferHome = () => (
	<VStack w="100%" spacing="32px" align="start">
		<Heading size="lg">Transferts</Heading>
		<TransferList context="transfer" />
	</VStack>
);

const TransferRouter = (): JSX.Element => (
	<Routes>
		<Route index element={<TransferHome />} />
		<Route path=":id" element={<TransferDetails />} />
		<Route path="*" element={<Navigate to="/ops/super/transfer" replace />} />
	</Routes>
);

export default TransferRouter;
