import { Navigate, Route, Routes } from 'react-router-dom';
import { Heading, VStack } from '@chakra-ui/react';

import { BlockingInstanceDetails } from 'pages/ops/super/blocking-instance/BlockingInstanceDetails';

import BlockingInstanceList from './BlockingInstanceList';

const BlockingInstanceHome = () => (
	<VStack w="100%" spacing="24px" align="start">
		<Heading size="lg">Instances Bloquantes</Heading>
		<BlockingInstanceList context="blocking-instance" />
	</VStack>
);

const BlockingInstanceRouter = (): JSX.Element => (
	<Routes>
		<Route index element={<BlockingInstanceHome />} />
		<Route path=":id" element={<BlockingInstanceDetails />} />
		<Route path="*" element={<Navigate to="/ops/super/blocking-instance" replace />} />
	</Routes>
);

export default BlockingInstanceRouter;
