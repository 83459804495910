import { useState } from 'react';

function useSessionStorageState<T>(key: string, initialValue: T): [T, React.Dispatch<React.SetStateAction<T>>] {
	const [state, setState] = useState<T>(() => {
		const storedValue = sessionStorage.getItem(key);
		try {
			return storedValue ? JSON.parse(storedValue) : initialValue;
		} catch (e) {
			return storedValue ?? initialValue;
		}
	});

	const setSessionStorageState = (value: T | ((val: T) => T)) => {
		setState((prevState) => {
			const newValue = value instanceof Function ? value(prevState) : value;
			sessionStorage.setItem(key, JSON.stringify(newValue));
			return newValue;
		});
	};

	return [state, setSessionStorageState];
}

export default useSessionStorageState;
