export enum IncomeType {
	GROSS = 'GROSS',
	NET = 'NET',
}

const IncomeTypeReferentiel: Record<IncomeType, string> = {
	GROSS: 'Brut',
	NET: 'Net',
};

export default IncomeTypeReferentiel;
