import { FC } from 'react';
import { Checkbox, CheckboxGroup, VStack } from '@chakra-ui/react';

import { FilterPopoverProps } from '.';

const optionsLabels = {
	INVEST: 'Invest',
	INVEST_CON: 'Invest - contrat',
	INVEST_SUB: 'Invest - souscription',
	SP: 'Produits structurés',
	CATPP: 'Compte à terme - physique',
	CATPM: 'Compte à terme - morale',
	CER: 'Compte épargne rémunéré',
	CASH: 'Cash',
	PE: 'Private Equity',
	SCPI: 'SCPI',
	CROWDFUNDING: 'Crowdfunding',
	ART: 'Art',
	TRANSFER: 'Transfert',
	AV_LUX: 'Assurance vie Lux',
	GIRARDIN: 'Girardin',
	isBlack: 'BLACK',
	isPhoenix: 'PHEONIX',
	isCorporate: 'CORPORATE',
};

const BasicFilter: FC<FilterPopoverProps> = ({ value, onChange, options }) => (
	<CheckboxGroup value={value} onChange={(v) => onChange(v as string[])}>
		<VStack align="start" w="100%" p="16px">
			{options.map((option) => (
				<Checkbox key={option} value={option}>
					{optionsLabels[option as keyof typeof optionsLabels] ?? option}
				</Checkbox>
			))}
		</VStack>
	</CheckboxGroup>
);

export default BasicFilter;
