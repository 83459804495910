import { Route, Routes } from 'react-router-dom';

import AlertDetails from './pages/AlertDetail';
import AllAlertsPage from './pages/AlertsList.tsx';

const AlertsRouter = (): JSX.Element => (
	<Routes>
		<Route path="*" element={<AllAlertsPage />} />
		<Route path="/:id" element={<AlertDetails />} />
	</Routes>
);

export default AlertsRouter;
